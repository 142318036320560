import { useContext, useState } from 'react';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components/ui
import Box from '@mui/material/Box';
// Components
import { Body, Header, Footer, BodySkeleton } from './components';
import { IConversation } from '@trii/types/dist/Conversations';
import { useSearchParams } from 'react-router-dom';

type ConversationProps = {
  conversationSelected: IConversation;
  notFound: boolean;
  contactId: string;
};

const Conversation = ({
  conversationSelected,
  notFound,
  contactId,
}: ConversationProps) => {
  const { fileSelectorMode } = useContext(messagesContext);

  const [closeAction, setCloseAction] = useState<boolean>(false);

  const disabled =
    notFound || (conversationSelected && Boolean(conversationSelected.finalizedAt));

  const handleClose = (code: string) => {
    if (code === 'Escape') {
      setCloseAction(true);
    }
  };
  return (
    <Box
      display={fileSelectorMode ? 'none' : 'flex'}
      flexGrow={1}
      flexDirection="column"
      height="100%"
      minWidth="50%"
      maxWidth="50%"
      onKeyDown={(e) => handleClose(e.code)}
    >
      <Header disabled={notFound} />
      {!notFound ? (
        <Body conversationSelected={conversationSelected} />
      ) : (
        <BodySkeleton contactId={contactId} />
      )}
      <Footer
        disabled={disabled}
        closeAction={closeAction}
        setCloseAction={setCloseAction}
      />
    </Box>
  );
};

export default Conversation;
