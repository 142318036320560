import { useContext, useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, Divider } from '@mui/material';
// Components
import { Destination, OriginChannel } from './components';
import { LoadingButton } from '@mui/lab';
// Icons
import { Send } from '@mui/icons-material';
// Slice
import { selectForwardMessageStatus } from 'redux/features/messagesSlice/messagesSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  fetchChannels,
  selectConversationSelected,
} from 'redux/features/conversationsSlice/conversationsSlice';

const ForwardMessage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    setOpenModalList,
    selectedMessages,
    setSelectMessagesMode,
    setSelectedMessages,
  } = useContext(conversationsContext);
  const { message, forwardMessage, setMessage } = useContext(messagesContext);

  const [massForwardLoading, setMassForwardLoading] = useState(false);
  const [addressId, setAddressId] = useState<string[]>([]);
  const [to, setTo] = useState<string[]>([]);
  const [channelId, setChannelId] = useState<string>('');

  const forwardMessageStatus = useSelector(selectForwardMessageStatus);
  const conversationSelected = useSelector(selectConversationSelected);

  const isForwardLoading = forwardMessageStatus === 'loading';

  const handleClose = () => {
    setOpenModalList(false);
  };

  const handleForward = async () => {
    if (message) {
      const { id: messageId, shardKey } = message;
      const data = {
        messageId,
        channelId,
        shardKey,
        destination: {
          addressId,
          to,
        },
      };

      await forwardMessage(data);
    } else if (selectedMessages.length > 0) {
      setMassForwardLoading(true);

      for (const selectedMessage of selectedMessages) {
        const { id: messageId, shardKey } = selectedMessage;
        const data = {
          messageId,
          channelId,
          shardKey,
          destination: {
            addressId,
            to,
          },
        };

        await forwardMessage(data);
      }

      setSelectMessagesMode(false);
      setSelectedMessages([]);
      setMassForwardLoading(false);
    }
    setMessage(null);
    handleClose();
  };

  useEffect(() => {
    dispatch(fetchChannels(conversationSelected.channelInfo.type));
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        height: '40rem',
      }}
    >
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          maxHeight: '50%',
          height: '100%',
          padding: '.5rem',
        }}
      >
        <OriginChannel
          channelSelected={channelId}
          setChannelSelected={setChannelId}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          maxHeight: '50%',
          height: '100%',
          padding: '.5rem',
        }}
      >
        <Destination
          addressId={addressId}
          setAddressId={setAddressId}
          to={to}
          setTo={setTo}
        />
      </Box>
      <Divider
        sx={{
          width: '100%',
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '.5rem',
        }}
      >
        <Button
          variant="outlined"
          color="error"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: '.5rem',
          }}
          onClick={handleClose}
        >
          {t('global.close')}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: '.5rem',
          }}
          loading={massForwardLoading || isForwardLoading}
          onClick={handleForward}
          endIcon={<Send />}
        >
          {t('conversations.message.actions.forward')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ForwardMessage;
