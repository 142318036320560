import React, { useContext, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import { conversationsContext } from '../../../../../../../context/ConversationsProvider/ConversationsProvider';
// Components/ui
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
// Icons
import { Close, Delete, FileDownload, Reply, Star } from '@mui/icons-material';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { dbWorker } from 'db/db';

const SelectMode = () => {
  const { t } = useTranslation();
  const {
    setSelectMessagesMode,
    selectedMessages,
    setSelectedMessages,
    handleOpenForwardModal,
  } = useContext(conversationsContext);
  const { highlightMessages } = useContext(messagesContext);

  const iconStyle = {
    color: (theme) => theme.palette.text.disabled,
  };

  const handleCloseSelectMode = () => {
    setSelectMessagesMode(false);
    setSelectedMessages([]);
  };

  async function handleMassHighlight() {
    let highlightedMessagesData = [];

    for (const message of selectedMessages) {
      const { id: messageId, shardKey, isHighlighted } = message;

      if (!isHighlighted) {
        const data = {
          messageId,
          shardKey,
          isHighlighted: !message.isHighlighted,
        };

        highlightedMessagesData.push(data);
      }
    }

    const highlightedMessages = await highlightMessages(highlightedMessagesData);

    for (const message of highlightedMessages) {
      await dbWorker.postMessage({
        action: 'updateMessage',
        data: message,
      });
    }

    handleCloseSelectMode();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '.5rem',
        }}
      >
        <IconButton sx={iconStyle} onClick={() => handleCloseSelectMode()}>
          <Close fontSize="small" />
        </IconButton>
        <Typography variant="body1">
          {selectedMessages.length} {t('conversations.message.selected')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '.5rem',
        }}
      >
        <Tooltip
          title={t('conversations.message.actions.highlight')}
          placement="top"
        >
          <IconButton
            onClick={handleMassHighlight}
            sx={iconStyle}
            disabled={selectedMessages.length === 0}
          >
            <Star fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('conversations.message.actions.forward')} placement="top">
          <IconButton
            onClick={handleOpenForwardModal}
            sx={iconStyle}
            disabled={selectedMessages.length === 0}
          >
            <Reply fontSize="small" sx={{ transform: 'rotateY(180deg)' }} />
          </IconButton>
        </Tooltip>
   
      </Box>
    </Box>
  );
};

export default SelectMode;
