import { useContext, useEffect, useRef, useState } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
// Icons
import {
  Adb,
  ArrowDropDown,
  Done,
  Label,
  Search,
  SyncAlt,
  TrendingFlat,
} from '@mui/icons-material';
// Components
import { MoreActions } from './components';
// Types
import { ChatType } from '@trii/types/dist/Conversations';
import { selectUser } from 'redux/features/userSlice/userSlice';
import { useSelector } from 'react-redux';
import { ChatBotIcon } from 'components';
import {
  selectConversationSelected,
  selectConversationsFetchStatus,
} from 'redux/features/conversationsSlice/conversationsSlice';
import LoadingButton from '@mui/lab/LoadingButton';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ConversationActions = () => {
  const { t } = useTranslation();
  const conversationSelected = useSelector(selectConversationSelected);
  const conversationStatus = useSelector(selectConversationsFetchStatus);
  const {
    handleFinalizeConversation,
    handleTransferTo,
    getUsers,
    getGroups,
    getLabels,
    setOpenModalList,
    setModalTitle,
  } = useContext(conversationsContext);
  const { setIsSearching } = useContext(messagesContext);
  const user = useSelector(selectUser);
  const [transferIn, setTransferIn] = useState<boolean>(false);
  const [showActions, setShowActions] = useState(false);
  const [cancelTransfer, setCancelTransfer] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const options = [
    t('conversations.actions.done'),
    t('conversations.actions.doneWithoutExiting'),
  ];
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const theme = useTheme();
  const isLoading: boolean = conversationStatus === 'loading';

  const handleOpenTransferTo = () => {
    getUsers();
    getGroups();
  };

  const handleClick = (title: string, handle: () => void) => {
    setOpenModalList(true);
    setModalTitle(t(title));
    handle();
  };

  const handleMenuItemClick = (index: number) => {
    if (index === 0) {
      handleFinalizeConversation(conversationSelected.id);
    } else if (index === 1) {
      const finalizeWithoutExit = true;

      handleFinalizeConversation(conversationSelected.id, finalizeWithoutExit);
    }
    setOpen(false);
  };

  const handleSearchMessage = () => {
    setIsSearching(true);
  };
  const handleModalBot = (title: string) => {
    setOpenModalList(true);
    setModalTitle(t(title));
  };

  const handleCancel = () => {
    const data = {
      conversationId: conversationSelected.id,
      userId: '',
      groupId: '',
    };
    handleTransferTo(data);
    setCancelTransfer(false);
  };

  useEffect(() => {
    setShowActions(
      conversationSelected && conversationSelected?.type === ChatType.EXTERNAL
    );
  }, [conversationSelected?.type]);

  useEffect(() => {
    const getTransferTo = conversationSelected?.transferTo;
    if (getTransferTo) {
      setCancelTransfer(true);
      if (getTransferTo.userInfo) {
        setTransferIn(getTransferTo.userInfo.id !== user.uid);
      } else if (getTransferTo.userIds && getTransferTo.userIds.length > 0) {
        setTransferIn(!getTransferTo.userIds.includes(user.uid));
      }
    } else {
      setCancelTransfer(false);
    }
  }, [conversationSelected]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  return (
    <Box display="flex" alignItems={'center'} height="100%">
      {showActions && (
        <>
          {/* Bot */}
          {/* <Tooltip title={t("conversations.actions.cancelTransfer")} arrow> */}
          {conversationSelected?.botStatus !== undefined &&
            conversationSelected?.botStatus !== 0 && (
              <IconButton onClick={() => handleModalBot('conversations.botAnswers')}>
                <ChatBotIcon
                  color={
                    conversationSelected.botStatus === 4 ? '#0aa709' : 'inherit'
                  }
                />
              </IconButton>
            )}
          {/* </Tooltip> */}

          {cancelTransfer ? (
            <Tooltip title={t('conversations.actions.cancelTransfer')} arrow>
              <IconButton
                sx={{
                  color: transferIn ? '#007bff' : '#0aa709',
                  rotate: transferIn ? '320deg' : '140deg',
                }}
                onClick={handleCancel}
              >
                <TrendingFlat />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t('conversations.actions.transfer')} arrow>
              <IconButton
                onClick={() =>
                  handleClick('conversations.actions.transfer', handleOpenTransferTo)
                }
                sx={{ color: 'text.disabled', rotate: '140deg' }}
              >
                <SyncAlt />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={t('conversations.actions.label')} arrow>
            <IconButton
              onClick={() => handleClick('conversations.actions.label', getLabels)}
              sx={{ color: 'text.disabled' }}
            >
              <Label />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip title={t('conversations.actions.search')} arrow>
        <IconButton sx={{ color: 'text.disabled' }} onClick={handleSearchMessage}>
          <Search />
        </IconButton>
      </Tooltip>
      {showActions && (
        <>
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            size="small"
            sx={{
              //@ts-ignore
              color: theme.palette.text.primary,
            }}
          >
            <LoadingButton
              loading={isLoading}
              // loadingPosition="start"
              variant="contained"
              startIcon={selectedIndex === 0 ? <Done /> : <Done />}
              size="small"
              sx={{
                //@ts-ignore
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  //@ts-ignore
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
              onClick={() => handleFinalizeConversation(conversationSelected.id)}
            >
              {options[selectedIndex]}
            </LoadingButton>
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      id="split-button-menu"
                      autoFocusItem
                      sx={{
                        //@ts-ignore
                        backgroundColor: theme.palette.dropdownBox,
                      }}
                    >
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          disabled={index === 2}
                          selected={index === selectedIndex}
                          onClick={() => handleMenuItemClick(index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <MoreActions showActions={showActions} />
        </>
      )}
    </Box>
  );
};

export default ConversationActions;
