import React, { useContext, useEffect, useState } from 'react';
// Components/ui
import { Box, Avatar, Typography, Tooltip } from '@mui/material';
// Icons
import { Height } from '@mui/icons-material';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Types
import { ChatType } from '@trii/types/dist/Conversations';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';
import useSASAvatarURL from 'hooks/useSASAvatarURL';

const ConversationInfo = () => {
  const conversationSelected = useSelector(selectConversationSelected);

  const { getImage } = useContext(conversationsContext);

  const [icon, setIcon] = useState<React.ReactNode>(null);
  const [avatarImage, setAvatarImage] = useState<string>('');

  const imageURLWithAccess = useSASAvatarURL(avatarImage);

  const hasImageUrl = conversationSelected?.contactInfo?.imageUrl;

  useEffect(() => {
    if (conversationSelected && conversationSelected.type === ChatType.EXTERNAL) {
      const { icon: newIcon } = getImage(conversationSelected.channelInfo.type);
      const { imageUrl } = conversationSelected.contactInfo;
      setAvatarImage(imageUrl);
      setIcon(newIcon);
    } else if (
      conversationSelected &&
      conversationSelected.type === ChatType.DIRECT
    ) {
      const user = conversationSelected.chatMembers.find(
        (member) => member.userId !== conversationSelected.ownerId
      );
      const img = user?.user?.imageUrl;
      setAvatarImage(img);
    } else if (
      conversationSelected &&
      conversationSelected.type === ChatType.GROUP
    ) {
      const { chatImage } = conversationSelected;
      setAvatarImage(chatImage);
    }
  }, [conversationSelected]);

  return (
    <Box
      display="flex"
      alignItems="center"
      height="100%"
      sx={{
        textWrap: 'nowrap',
        '@media (max-width: 1250px)': {
          maxWidth: '35%',
        },
        '@media (max-width: 1030px)': {
          maxWidth: '25%',
        },

        maxWidth: '45%',
      }}
    >
      <Avatar
        alt={conversationSelected?.contactInfo?.name}
        src={hasImageUrl ? imageURLWithAccess : undefined}
        sx={{
          border: '1px solid #ced1d5',
          width: 30,
          height: 30,
        }}
      >
        {conversationSelected?.contactInfo?.name?.charAt(0).toUpperCase()}
      </Avatar>
      <Box display="flex" flexDirection="column" maxWidth="100%">
      <Tooltip title={conversationSelected?.contactInfo?.name} >

        <Typography
          variant="subtitle2"
          ml={1}
          mb={'-5px'}
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {conversationSelected?.contactInfo?.name}
        </Typography>
      </Tooltip>
        {conversationSelected?.type === ChatType.EXTERNAL && (
          <Box display="flex" alignItems="center" ml={1} gap={1}>
            <Box display="flex" alignItems="center">
              {icon}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              width="100%"
              sx={{ opacity: '0.7' }}
            >
              <Tooltip title={conversationSelected?.remoteAddress}>
                <Typography
                  variant="caption"
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {conversationSelected?.remoteAddress}
                </Typography>
              </Tooltip>

              <>
                <Height sx={{ rotate: '90deg' }} />
                <Tooltip title={conversationSelected?.channelInfo?.name}>
                  <Typography
                    variant="caption"
                    sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {conversationSelected?.channelInfo?.name}
                  </Typography>
                </Tooltip>
              </>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ConversationInfo;
