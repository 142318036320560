import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab, useTheme, Badge, Tooltip, styled, tooltipClasses, Divider } from '@mui/material';
import { SectionRefs } from '../../hooks/useSectionRefs';
import {
  Person2Outlined,
  QuestionAnswerOutlined,
  ConfirmationNumberOutlined,
  HandymanOutlined,
  TopicOutlined,
  CalendarMonthOutlined,
  Schedule,
  Note,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectActivities } from 'redux/features/activitiesSlice/utils/selectors';
import { selectButtons } from 'redux/features/buttonsSlice/buttonsSlice';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { ActivitiesCardContext } from '../../context/ActivitiesCardContext';
import { useTranslation } from 'react-i18next';
import { selectFiles, selectNotes, selectNotesFetchStatus } from 'redux/features/contactInfoSlice/contactInfoSlice';
import { selectEvents } from 'redux/features/activitiesSlice/activitiesSlice';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type TabsMenuProps = {
  handleChange: (newValue: number) => void;
  value: number;
  sectionRefs: SectionRefs;
  setIsClicked: (isClicked: boolean) => void;
};

function TabsMenu({
  handleChange,
  value,
  sectionRefs,
  setIsClicked,
}: TabsMenuProps) {
  const theme = useTheme();
  const {t} = useTranslation()
  const { contactInfo } = useContext(conversationsContext);
  const { tabsShowed, setTabsShowed } = useContext(ActivitiesCardContext);

  const activities = useSelector(selectActivities);
  const buttons = useSelector(selectButtons);

  const activeActivities = activities.length;

  const scrollToSection = (
    ref: React.RefObject<HTMLElement | null>,
    tabName: string
  ) => {
    if (!tabsShowed.includes(tabName)) {
      setTabsShowed([...tabsShowed, tabName]);
    }

    setIsClicked(true);

    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setTimeout(() => setIsClicked(false), 500);
    }
  };

  const handleButtonTabClick = (
    button: any,
    ref: React.RefObject<HTMLElement | null>,
    tabName: string
  ) => {
    scrollToSection(ref, tabName);

    if (button.api_formatosalida === 'HTML_newtab') {
      window.open(button.api_url, '_blank', 'noopener,noreferrer');
    }
  };


  type TooltipProps = {
    className?: string;
    title: string;
    placement: 'left';
    children: React.ReactElement;
  };
  
  const TooltipPers = styled(
    ({ className, children, placement, title, ...props }: TooltipProps) => (
      <Tooltip
        {...props}
        classes={{ popper: className }}
        title={title}
        placement={placement}
      >
        {children}
      </Tooltip>
    )
  )(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      //@ts-ignore
      backgroundColor: theme.palette.background.tooltip,
    },
    // [`& .${tooltipClasses.arrow}`]: {
    //   //@ts-ignore
    //   color: theme.palette.background.tooltip,
    // },
  }));
  const notesRedux = useSelector(selectNotes)
  const documentsRedux = useSelector(selectFiles)
  const eventsRedux = useSelector(selectEvents)
  const counters = contactInfo?.counters;
  const [amountNotes, setAmountNotes] = useState(counters.notes)
  const [amountEvents, setEvents] = useState(counters.events)
  const [amountDocuments, setAmountDocuments] = useState(counters.documents)

  useEffect(() => {    
    setAmountNotes(notesRedux.length)
    setEvents(eventsRedux.length)
    setAmountDocuments(documentsRedux.length)
  }, [notesRedux, documentsRedux, eventsRedux, counters])

  const badgeStyle = {
    '& .MuiBadge-badge': {
      minWidth: '16px',
      height: '16px', 
      fontSize: '10px', 
      padding: '0 4px', 
    },
  }
  return (
    <Tabs
      orientation="vertical"
      value={value}
      onChange={(event, newValue) => handleChange(newValue)}
      sx={{
        height: "100%",
        "& .MuiTabs-indicator": {
          left: 0,
        },
        "& .MuiTab-root": {
          minWidth: 50,
          minHeight: 50,
          padding: 0,
          zIndex: 0
        },
      }}
    >
      <TooltipPers title={t("global.contact")} placement="left">
        <Tab
          icon={<Person2Outlined />}
          onClick={() =>
            scrollToSection(sectionRefs.contactDataRef, "contactData")
          }
          {...a11yProps(0)}
        />
      </TooltipPers>
      <TooltipPers title={t("conversations.conversations")} placement="left">
        <Tab
          icon={
            <Badge sx={badgeStyle} badgeContent={counters?.conversations > 0 ? counters.conversations : null} color="error">
              <QuestionAnswerOutlined />
            </Badge>
          }
          onClick={() =>
            scrollToSection(
              sectionRefs.contactConversationRef,
              "contactConversation"
            )
          }
          {...a11yProps(1)}
        />
      </TooltipPers>
      {/* <TooltipPers title={"Tickets"} placement="left">
        <Tab
        disabled
          icon={<ConfirmationNumberOutlined />}
          onClick={() =>
            scrollToSection(sectionRefs.contactTicketRef, "contactTicket")
          }
          {...a11yProps(2)}
          sx={{
            color: (theme) => theme.palette.action.disabled, // Color de texto deshabilitado
            opacity: 0.5, // Ajusta la opacidad para que parezca desactivado
            '&.Mui-disabled': {
              backgroundColor: (theme) => theme.palette.action.disabledBackground, // Fondo deshabilitado
            },
          }}
        />
      </TooltipPers> */}
      <TooltipPers
        title={t("conversations.sidebar.notes.title")}
        placement="left"
      >
        <Tab          
            icon={
              <Badge sx={badgeStyle} badgeContent={amountNotes > 0 ? amountNotes : counters.notes} color="error">
                <Note />
              </Badge>
            }
          onClick={() =>
            scrollToSection(sectionRefs.contactNotesRef, "contactNotes")
          }
          {...a11yProps(3)}
        />
      </TooltipPers>

      {/* <TooltipPers
        title={t("conversations.sidebar.repairs.title")}
        placement="left"
      >
        <Tab
          disabled
          icon={<HandymanOutlined />}
          onClick={() =>
            scrollToSection(sectionRefs.contactRepairsRef, "contactRepairs")
          }
          {...a11yProps(4)}
          sx={{
            color: (theme) => theme.palette.action.disabled, // Color de texto deshabilitado
            opacity: 0.5, // Ajusta la opacidad para que parezca desactivado
            '&.Mui-disabled': {
              backgroundColor: (theme) => theme.palette.action.disabledBackground, // Fondo deshabilitado
            },
          }}
        />
      </TooltipPers> */}

      <TooltipPers
        title={t("conversations.sidebar.files.title")}
        placement="left"
      >
        <Tab
            icon={
              <Badge sx={badgeStyle} badgeContent={amountDocuments > 0 ? amountDocuments : counters.notes} color="error">
                <TopicOutlined />
              </Badge>
            }
          onClick={() =>
            scrollToSection(sectionRefs.contactFilesRef, "contactFiles")
          }
          {...a11yProps(5)}
        />
      </TooltipPers>
      <TooltipPers
        title={t("conversations.sidebar.history.title")}
        placement="left"
      >
        <Tab
          icon={<Schedule />}
          onClick={() =>
            scrollToSection(sectionRefs.contactHistoryRef, "contactHistory")
          }
          {...a11yProps(6)}
        />
      </TooltipPers>

      <TooltipPers
        title={t("conversations.sidebar.events.title")}
        placement="left"
      >
        <Tab
           icon={
            <Badge sx={badgeStyle} badgeContent={amountEvents > 0 ? amountEvents : counters.notes} color="error">
              <CalendarMonthOutlined />
            </Badge>
          }
          onClick={() =>
            scrollToSection(sectionRefs.contactEventRef, "contactEvent")
          }
          {...a11yProps(7)}
        />
      </TooltipPers>

      <Divider />

      {buttons.map((button, index) => (
        <TooltipPers
          title={button.name}
          placement="left"
          key={index}
        >
          <Tab
            
            icon={
              <img
                src={button.icon_class}
                alt=""
                style={{
                  width: "25px",
                  filter:
                    theme.palette.mode === "dark"
                      ? "invert(0.7)"
                      : "invert(0.4)",
                }}
              />
            }
            onClick={() =>
              handleButtonTabClick(
                button,
                sectionRefs.contactButtonsRef[index],
                `contact${button.name}`
              )
            }
            {...a11yProps(8 + index)}
          />
        </TooltipPers>
      ))}
    </Tabs>
  );
}

export default TabsMenu;
