import { useContext, useEffect, useState } from 'react';
// Moment
import moment from 'moment';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Divider, Typography, styled } from '@mui/material';
// Icons
import { ThumbUp } from '@mui/icons-material';
// Context
import socialWallsContext from 'features/Views/Walls/context/SocialWallsContext';
import { ChannelType } from '@trii/types/dist/Common/Channels';

const FORMAT_DATE = 'DD/MM/YYYY';

const ImageBox = styled(Box)(({ theme }) => ({
  maxHeight: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden', // Para evitar desbordamientos
}));

interface DetailsBoxProps {
  imageUrl?: string;
}

const DetailsBox = styled(Box)<DetailsBoxProps>(({ theme, imageUrl }) => ({
  height: 'calc(100% - 20rem)',
  borderTop: imageUrl ? `1px solid ${theme.palette.divider}` : 'none',
  padding: theme.spacing(1, 3),
  margin: theme.spacing(0.1, 0),
}));

const LikesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const ItemDetails = () => {
  const { t } = useTranslation();
  const { postSelected } = useContext(socialWallsContext);
  const [likes, setLikes] = useState<number>(0);
  const { title, details, imageUrl, createdAt } = postSelected || {};

  useEffect(() => {
    if (postSelected && postSelected.counters) {
      const { likes } = postSelected.counters;
      setLikes(likes || 0);
    }
  }, [postSelected]);

  return (
    <>
      {/* imagen */}
      {imageUrl && (
        <ImageBox>
          <img
            src={imageUrl}
            alt={title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain', // Asegura que la imagen se ajuste dentro sin recortarse
            }}
          />
        </ImageBox>
      )}
      {/* detalles */}
      <DetailsBox imageUrl={imageUrl}>
        {/* me gusta y fecha */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height="2rem"
        >
          <LikesBox>
            {postSelected.channelInfo.type !== ChannelType.MERCADOLIBRE && (
              <>
                <ThumbUp fontSize="small" color="primary" />
                <Typography
                  color={'text.primary'}
                  variant="subtitle2"
                  sx={{
                    userSelect: 'none',
                  }}
                >
                  {t('socialWalls.likes', { likes })}
                </Typography>
              </>
            )}
          </LikesBox>
          <Typography
            variant="subtitle2"
            sx={{
              userSelect: 'none',
            }}
          >
            {moment(createdAt).format(FORMAT_DATE)}
          </Typography>
        </Box>
        <Typography
          variant="subtitle1"
          sx={{ mt: 1 }}
          height="calc(100% - 3rem)"
          overflow={'auto'}
        >
          {details}
        </Typography>
      </DetailsBox>
    </>
  );
};

export default ItemDetails;
