import { createContext } from 'react';

export type EmailMessageContextType = {
  handleSubjectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sendEmailAndReset: (
    conversationId: string,
    newBody: string,
    subject: string
  ) => void;
  body: string;
  setBody: (body: string) => void;
};

export const EmailMessageContext = createContext<EmailMessageContextType>({
  handleSubjectChange: () => {},
  sendEmailAndReset: () => {},
  body: '',
  setBody: () => {},
});
