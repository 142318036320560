import { useContext, useEffect, useState } from "react";
// Components/ui
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Chip,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
// Moment
import moment from "moment";
// Context
import socialWallsContext from "features/Views/Walls/context/SocialWallsContext";
// Types
import { Post } from "@trii/types/dist/Conversations";
import { ImageIcon } from "types/ImageIcon";
import getImage from "functions/getImage";
import { PostAction } from "redux/features/socialWallSlice/types/PostAction";
import { useUserInfo } from "hooks/useUserInfo";
import { Markdown } from "functions/Markdown";

const FORMAT_DATE = "DD/MM/YYYY";

interface Props {
  post: Post;
}

const ItemList = ({ post }: Props) => {
  const { postSelected, setPostSelected, getComments, fetchAction } =
    useContext(socialWallsContext);
  const userInfo = useUserInfo();
  const {
    id,
    title,
    details,
    imageUrl,
    channelInfo,
    newMessagesCount,
    updatedAt,
    participants,
  } = post;
  const [iconChannel, setIconChannel] = useState<ImageIcon>(null);
  const [labelInfoDate, setLabelInfoDate] = useState<string>("");

  const handleSelectWall = () => {
    if (postSelected?.id === id) return;
    setPostSelected(post);
    getComments(id);
    const data = {
      postId: id,
      action: "in",
    } as PostAction;
    fetchAction(data);
  };

  useEffect(() => {
    if (channelInfo) {
      const newDate = moment(updatedAt).format(FORMAT_DATE);
      const iconInfo = getImage(channelInfo.type);
      setIconChannel(iconInfo);
      setLabelInfoDate(newDate);
      participants.map(async (participant) => {
        await userInfo.getUserInfo(participant.id);
      });
    }
  }, [post]);
  const titleMarkdown = Markdown(title, false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 0.5,
          pr: 0,
          pl: 1,
          justifyContent: "space-between",
          height: "5rem",
          position: "relative",
          // borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.action.hover,
            cursor: "pointer",
          },
          backgroundColor:
            postSelected?.id === id
              ? (theme) => theme.palette.action.active
              : "transparent",
        }}
        onClick={handleSelectWall}
      >
        <Box display="flex">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={iconChannel?.icon}
            sx={{
              "& .MuiBadge-badge": {
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.dropdownMenu,
                width: 20,
                height: 20,
                borderRadius: "50%",
              },
              "& .MuiBadge-badge svg": {
                width: "0.7em",
                height: "0.7em",
              },
            }}
          >
            <Avatar
              alt="Publicacion"
              src={imageUrl}
              sx={{
                width: 35,
                height: 35,
                border: (theme) => `1px solid ${theme.palette.text.primary}`,
                padding: "2px",
                "& .MuiAvatar-img": {
                  borderRadius: "50%",
                },
              }}
            />
          </Badge>
          <Box display="flex" flexDirection="column" maxWidth={"14vw"}>
            <Typography
              variant="body2"
              sx={{
                color: "text.primary",
                fontSize: ".8rem",
                flexGrow: 1,
                ml: 2,
                mb: "-5px",
                fontWeight: "bold",
                display: "block",
                alignItems: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
              className="conversations-ellipsified"
              dangerouslySetInnerHTML={{ __html: titleMarkdown }}
            />
            <Typography
              variant="caption"
              sx={{
                color: "text.disabled",
                fontSize: "0.6rem",
                flexGrow: 1,
                ml: 2,
              }}
              noWrap
              className="conversations-ellipsified"
            >
              {details}
            </Typography>
          </Box>
        </Box>
        <Box position="absolute" right={"10px"} top={"5px"}>
          <Typography variant="caption" color="text.disabled">
            {labelInfoDate}
          </Typography>
        </Box>
        <Box position="absolute" right={"10px"} bottom={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "flex-end",
            }}
          >
            <AvatarGroup
              sx={{
                "& .MuiAvatar-root": {
                  width: "1.5rem",
                  height: "1.5rem",
                  fontSize: "0.75rem",
                },
              }}
            >
              {participants &&
                participants.length > 0 &&
                participants.map((participant) => (
                  <Tooltip title={participant.name} arrow key={participant.id}>
                    <Avatar
                      alt={participant.name}
                      src={participant?.imageUrl ? participant.imageUrl : "/"}
                      sx={{
                        width: "1.5rem",
                        height: "1.5rem",
                      }}
                    />
                  </Tooltip>
                ))}
            </AvatarGroup>
            {newMessagesCount > 0 && (
              <Chip
                size="small"
                label={newMessagesCount}
                sx={{
                  width: "max-content",
                  "&.MuiChip-root": {
                    height: "1.225rem",
                    borderRadius: "50%",
                  },
                  "& .MuiChip-label": {
                    paddingLeft: "0.375rem",
                    paddingRight: "0.375rem",
                  },
                }}
                color="error"
              />
            )}
          </Box>
        </Box>
      </Box>
      <Divider sx={{ padding: 0 }}></Divider>
    </>
  );
};

export default ItemList;
