import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Components/ui
import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import { Audio, Document, Image, Text, Video } from './components';
// Icons
import { Close } from '@mui/icons-material';
import { IMessage } from '@trii/types/dist/Common/Messages';
// Hooks
import { useUserInfo } from 'hooks/useUserInfo';
import useSAS from 'hooks/useSAS'; // Importa el hook
// Slice
import { selectUser } from 'redux/features/userSlice/userSlice';

interface Props {
  messageReply: IMessage;
  isReplying?: boolean;
  setRepliesInitializing?: Dispatch<SetStateAction<string[]>>;
  handleClose?: () => void;
}

const ReplyMessage = ({
  messageReply,
  isReplying = false,
  handleClose = () => {},
  setRepliesInitializing,
}: Props) => {
  const user = useSelector(selectUser);
  const { handleNavigateToRepliedMessage } = useContext(messagesContext);
  const { t } = useTranslation();
  const { getUserInfo } = useUserInfo();
  const [userName, setUserName] = useState<string>('');

  // Usa el hook useSAS
  const { getURLWithAccessToken } = useSAS();

  const [mediaUrl, setMediaUrl] = useState<string | null>(null);

  const getUser = async () => {
    if (user) {
      if (user.uid === messageReply.userId) {
        setUserName(t('conversations.message.your'));
        return;
      }
    } else {
      const newUser = await getUserInfo(messageReply.userId);
      setUserName(newUser?.name);
    }

    if (setRepliesInitializing)
      setRepliesInitializing((prev) => prev.filter((id) => id !== messageReply.id));
  };

  useEffect(() => {
    if (messageReply) {
      getUser();
    }
  }, [messageReply]);

  useEffect(() => {
    const fetchMediaUrl = async () => {
      // Verifica que messageReply y sus propiedades existen antes de acceder a ellas
      if (messageReply?.images?.[0]?.url) {
        const urlWithSas = await getURLWithAccessToken(messageReply.images[0].url);
        setMediaUrl(urlWithSas);
      } else if (messageReply?.videos?.[0]?.url) {
        const urlWithSas = await getURLWithAccessToken(messageReply.videos[0].url);
        setMediaUrl(urlWithSas);
      } else {
        setMediaUrl(null); // Reinicia el valor de mediaUrl si no hay imagen o video
      }
    };

    fetchMediaUrl();
  }, [messageReply, getURLWithAccessToken]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        onClick={() => handleNavigateToRepliedMessage(messageReply.id)}
        sx={{
          cursor: 'pointer',
          bgcolor: 'rgba(0, 0, 0, 0.05)',
          borderRadius: '0.5rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0.5rem 0',
          padding: '5px 0px 5px 5px',
          width: '100%',
          height: 'max-content',
          maxHeight: '5rem',
          overflow: 'hidden',
          userSelect: 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 'max-content',
            padding: '0 5px',
          }}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: '1rem',
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-word',
            }}
          >
            {userName}
          </Typography>

          {messageReply?.text && <Text text={messageReply.text.body} />}
          {messageReply?.audio && <Audio messageId={messageReply.id} />}
          {messageReply?.images && messageReply.images.length > 0 && (
            <Image image={messageReply.images[0]} />
          )}
          {messageReply?.videos && messageReply.videos.length > 0 && (
            <Video video={messageReply.videos[0]} />
          )}
          {messageReply?.documents && messageReply.documents.length > 0 && (
            <Document documents={messageReply.documents} />
          )}
        </Box>
        {mediaUrl && (
          <CardMedia
            component={messageReply.images?.length ? 'img' : 'video'}
            src={mediaUrl}
            sx={{
              width: 'auto',
              height: '5rem',
              objectFit: 'contain',
              borderRadius: '5px',
            }}
          />
        )}
      </Box>
      {isReplying && (
        <IconButton onClick={handleClose} sx={{ color: 'text.disabled' }}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
};

export default ReplyMessage;
