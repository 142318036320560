import React from 'react';
import { Box, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
// import GoogleBusinessIcon from '../../../../../utils/icons/GoogleBusinessIcon';
import MmsIcon from '@mui/icons-material/Mms';
const RCS = () => {
  const { t } = useTranslation();
  return (
    <Button>
      <NavLink to="/a/conversations/system/RCS">
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent="center"
          alignItems={'center'}
          padding={2}
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          <Box marginBottom={4}>
            {/* <GoogleBusinessIcon size={40} /> */}
            <MmsIcon sx={{ fontSize: '2.5rem' }}/>

          </Box>
          <Typography variant={'body2'}>
            RCS
          </Typography>
        </Box>
      </NavLink>
    </Button>
  );
};

export default RCS;
