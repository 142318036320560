// Components/ui
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ConversationsIcon, PostsIcon } from '../components';
// Types
import { INavIcon } from '../types/types';

export const iconsData: INavIcon[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    to: '/a/conversations/Dashboard',
    icon: <DashboardIcon />,
    //isDevelopment: true,
  },
  // {
  //   title: 'navBar.conversations',
  //   to: '/a/conversations/conversations',
  //   icon: <InboxIcon />,
  // },
  {
    id: 'conversations',
    title: 'navBar.conversations',
    to: '/a/conversations/Conversations',
    icon: <ConversationsIcon />,
    // isDevelopment: true,
  },
  {
    id: 'socialWalls',
    title: 'navBar.socialWalls',
    to: '/a/conversations/socialwalls',
    icon: <PostsIcon />,
  },
  {
    id: 'myReports',
    title: 'navBar.myReports',
    to: '/a/conversations/MyReports',
    icon: <AssessmentIcon />,
    //isDevelopment: true,
  },
  // {
  //   title: 'navBar.system',
  //   to: '/a/conversations/system',
  //   icon: <SettingsIcon />,
  // },
  {
    id: 'system',
    title: 'navBar.system',
    to: '/a/conversations/System',
    icon: <SettingsIcon />,
    //    isDevelopment: true,
  },
];
