import { NewRCS } from 'redux/features/RCSSlice/types/NewRCS';
import { fieldInitialDef } from 'utils/fieldInitialDef';

const createRCSInitialState = {
  input: {
    nameField: fieldInitialDef,
    descriptionField: fieldInitialDef,
  },
  actions: {
    sendRCS: () => {},
    resetAll: () => {},
    getNewRCS: () => ({} as NewRCS),
  },
  attributes: {
    creationError: false,
  },
};
export default createRCSInitialState;
