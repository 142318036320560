import { useTranslation } from 'react-i18next';
// Components/ui
import {
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Context
import { useContext } from 'react';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { ConversationBotVar } from '@trii/types/dist/Conversations';
import { DataItem } from 'features/Views/Conversations/components/components/ChatDetailsSidebar/components/PanelView/components/BotsInfo/components';

interface Props {
  data: ConversationBotVar;
  index: number;
}
const FormItem = ({ data: { title, value } }: Props) => {

  const { t } = useTranslation();

  return (
    <ListItem
    >
      <ListItemButton divider>
        <ListItemText
          primary={title}
          // secondary={data.description}
          sx={{
            color: (theme) => theme.palette.text.primary,
            width: "100%"

          }}
        />
        <ListItemText
          primary={value}
          // secondary={data.description}
          sx={{
            color: (theme) => theme.palette.text.primary,
            width: "100%"
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
export default FormItem;
