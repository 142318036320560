import useField from 'hooks/useField';
import { useState } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { createRCS } from 'redux/features/RCSSlice/RCSSlice';
// Types
import { NewRCS } from 'redux/features/RCSSlice/types/NewRCS';
import { UseCreateRCS } from './types';

const useCreateRCS = (): UseCreateRCS => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const [creationError, setCreationError] = useState<boolean>(false);
  const nameField = useField('text');
  const descriptionField = useField('text');

  // Actions
  const resetAll = () => {
    setCreationError(false);
    nameField.actions.resetValue();
    descriptionField.actions.resetValue();
  };
  const getNewRCS = (): NewRCS => {
    return {
      name: nameField.attributes.value,
      description: descriptionField.attributes.value,
    };
  };
  const sendRCS = async () => {
    const newRCS = getNewRCS();
    const response = await dispatch(createRCS(newRCS));
    // @ts-ignore
    if (!response.payload) {
      setCreationError(true);
    }
  };
  //

  return {
    actions: {
      resetAll,
      getNewRCS,
      sendRCS,
    },
    input: {
      nameField,
      descriptionField,
    },
    attributes: {
      creationError,
    },
  };
};

export default useCreateRCS;
