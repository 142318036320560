import { useContext, useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, IconButton, Tab, Tabs, TextField } from '@mui/material';
// Context
import { QuickResponseContext } from 'features/Views/QuickResponse/context/QuickResponseContext';
import ActionButtons from '../ActionButtons';
import { EmailEditor, EmojiPopup, Input } from 'components';
// Lexical
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import PlaygroundNodes from 'components/EmailEditor/nodes/PlaygroundNodes';
import PlaygroundEditorTheme from 'components/EmailEditor/themes/PlaygroundEditorTheme';
// Slice
import {
  selectCreateFetchStatus,
  selectUpdateFetchStatus,
} from 'redux/features/quickResponseSlice/quickResponseSlice';
import { Mood } from '@mui/icons-material';

const Response = () => {
  const { t } = useTranslation();
  const {
    categorySelected,
    quickResponseSelected,
    setCategorySelected,
    setEditionType,
    setNewQuickResponse,
    updateQuickResponse,
  } = useContext(QuickResponseContext);
  const createFetchStatus = useSelector(selectCreateFetchStatus);
  const updateFetchStatus = useSelector(selectUpdateFetchStatus);
  const isCreating = createFetchStatus === 'loading';
  const isUpdating = updateFetchStatus === 'loading';
  const [title, setTitle] = useState<string>('');
  const [bodyIM, setBodyIM] = useState<string>('');
  const [bodyHTML, setBodyHTML] = useState<string>('');
  const [tabValue, setTabValue] = useState<number>(0);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState<HTMLDivElement | null>(null);
  const [newEmoji, setNewEmoji] = useState<string>('');

  const initialConfig = {
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  const handleClear = () => {
    setCategorySelected(null);
    setEditionType(null);
    setTitle('');
    setBodyIM('');
    setBodyHTML('');
    setTabValue(0);
  };

  const handleSave = async () => {
    if (quickResponseSelected) {
      const data = {
        ...quickResponseSelected,
        title,
        bodyIM,
        bodyHTML,
      };
      updateQuickResponse(data);
      return;
    }
    const { id: categoryId, name: categoryName } = categorySelected;
    const data = {
      categoryId,
      categoryName,
      title,
      bodyIM,
      bodyHTML,
    };
    await setNewQuickResponse(data);
    handleClear();
  };

  const handleTab = (e: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleChange =
    (setState: React.Dispatch<React.SetStateAction<string>>) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setState(value);
      };

  const handleClick =
    (setState: React.Dispatch<React.SetStateAction<HTMLElement>>) =>
      (event: React.MouseEvent<HTMLElement>) => {
        setState(event.currentTarget);
      };

  const handleClose = () => {
    setEmojiAnchorEl(null);
  };

  const handleEmojiSelect = (emoji) => {
    setNewEmoji(emoji.native);
    setEmojiAnchorEl(null);
  };

  useEffect(() => {
    if (quickResponseSelected) {
      setTitle(quickResponseSelected.title);
      setBodyIM(quickResponseSelected.bodyIM);
      setBodyHTML(quickResponseSelected.bodyHTML);
    } else {
      setTitle('');
      setBodyIM('');
      setBodyHTML('');
    }
    setTabValue(0);
  }, [quickResponseSelected]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'calc(100% - 2rem)',
          justifyContent: 'flex-start',
          alignItems: 'start',
          gap: 1,
        }}
      >
        <TextField
          fullWidth
          label={t('quickResponse.response.title')}
          value={title}
          onChange={handleChange(setTitle)}
          size="small"
          sx={{
            height: '2rem',
          }}
        />
        <Tabs
          value={tabValue}
          onChange={handleTab}
          sx={{
            height: '3rem',
          }}
        >
          <Tab label={t('quickResponse.response.messaging')} />
          <Tab label={t('quickResponse.response.email')} />
        </Tabs>
        <Box
          sx={{
            height: '20rem',
            overflowY: 'hidden',
            width: '100%',
            position: 'relative',
          }}
        >
          {tabValue === 0 && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <Input
                value={bodyIM}
                newEmoji={newEmoji}
                maxHeigth="inherit"
                conversationId={''}
                contactId={''}
                setValue={setBodyIM}
                setNewEmoji={() => { }}
                handleSendMessage={() => { }}
              />
              <IconButton
                onClick={handleClick(setEmojiAnchorEl)}
                sx={{
                  color: 'text.disabled',
                  height: 'max-content',
                }}
              >
                <Mood color={Boolean(emojiAnchorEl) ? 'primary' : 'inherit'} />
              </IconButton>
              <EmojiPopup
                open={Boolean(emojiAnchorEl)}
                anchorEl={emojiAnchorEl}
                onClose={handleClose}
                onSelect={handleEmojiSelect}
              />
            </Box>
          )}
          {tabValue === 1 && (
            <LexicalComposer initialConfig={initialConfig}>
              <EmailEditor
                // isReplyingEmail={isReplyingEmail}
                // setIsReplyingEmail={setIsReplyingEmail}
                openModal={true}
                setBody={setBodyHTML}
                body={bodyHTML}
                hideActions
                hideMentions
              />
            </LexicalComposer>
          )}
        </Box>
      </Box>
      <ActionButtons
        handleClear={handleClear}
        handleSave={handleSave}
        isLoading={isCreating || isUpdating}
      />
    </>
  );
};

export default Response;
