import React, { useContext, useEffect } from 'react';
import { RCSContext } from 'features/Views/RCS/context/RCSProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, InputLabel, TextField, Divider } from '@mui/material';
import { CrudContainer } from 'components';
import { PanelHeader } from 'components';
import { DeleteButtons } from './components';

function DeletePanel() {
  const { t } = useTranslation();
  const { deleteRCSName, endDeleting, selectedRCS } = useContext(RCSContext);

  useEffect(() => {
    const cleanUp = () => {
      deleteRCSName.actions.resetValue();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CrudContainer>
      <PanelHeader
        title={t('RCSView.deletionPanel.title')}
        handleCloseButton={endDeleting}
      />
      <Box
        display={'flex'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems={'center'}
        p={2}
      >
        <InputLabel
          sx={{
            whiteSpace: 'break-spaces',
          }}
        >
          {t('RCSView.deletionPanel.description')}
          <strong style={{ marginLeft: 3 }}>{selectedRCS?.name}</strong>
        </InputLabel>
        <TextField
          {...deleteRCSName.attributes}
          className="fadein"
          variant="outlined"
          size="small"
          fullWidth
          label={t('global.name')}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            marginTop: '15px',
          }}
        />
      </Box>
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <DeleteButtons />
    </CrudContainer>
  );
}

export default DeletePanel;
