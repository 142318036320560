import { TextNode, $applyNodeReplacement } from "lexical";

// Define a custom TextNode class to handle the HTML content
export class HTMLTextNode extends TextNode {
  static getType(): string {
    return "html-text";
  }

  static clone(node: HTMLTextNode): HTMLTextNode {
    return new HTMLTextNode(node.__text);
  }

  constructor(text: string) {
    super(text);
  }

  // Render method to display HTML content
  createDOM(): HTMLElement {
    const element = document.createElement("span");
    element.innerHTML = this.__text;
    element.attributes["data-lexical-text"] = true;
    return element;
  }

  updateDOM(prevNode: HTMLTextNode, dom: HTMLElement): boolean {
    if (prevNode.__text !== this.__text) {
      dom.innerHTML = this.__text;
      return true;
    }
    return false;
  }
}

export function $createHTMLNode(html: string) {
  return $applyNodeReplacement(new HTMLTextNode(html));
}
