/**
 * Checks if the input string contains any `{{variable}}` pattern.
 * @param inputString - The string to check for `{{variable}}` patterns.
 * @returns `true` if at least one `{{variable}}` pattern is found, otherwise `false`.
 */
function hasVariables(inputString: string): boolean {
  const regex = /{{(.*?)}}/;
  return regex.test(inputString);
}

/**
 * Extracts all `{{variable}}` occurrences from a string and returns them in an array.
 * @param inputString - The string to search for `{{variable}}` patterns.
 * @returns An array of unique variables found in the input string.
 */
function extractVariables(inputString: string): string[] {
  const regex = /{{(.*?)}}/g;
  const matches = new Set<string>();
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    matches.add(match[0]); // Add the entire `{{variable}}` match
  }

  return Array.from(matches);
}

/**
 * Replaces a specific `{{variable}}` in the input string with a given value.
 * @param inputString - The string where replacement will occur.
 * @param variable - The `{{variable}}` to replace (e.g., `{{name}}`).
 * @param value - The value to replace the `{{variable}}` with.
 * @returns The updated string with the replacement applied.
 */
function replaceVariable(
  inputString: string,
  variable: string,
  value: string
): string {
  if (!hasVariables(inputString)) {
    return inputString; // No variables to replace, return the original string
  }

  const regex = new RegExp(variable.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'); // Escape the variable for regex
  return inputString.replace(regex, value);
}

// Example Usage
const text = 'Hello, {{name}}! Your account number is {{accountNumber}}.';

// Extract variables
const variables = extractVariables(text);
console.log('Extracted Variables:', variables); // Output: ['{{name}}', '{{accountNumber}}']

// Replace a variable
const replacedText = replaceVariable(text, '{{name}}', 'Ezequiel');
console.log('Replaced Text:', replacedText); // Output: "Hello, Ezequiel! Your account number is {{accountNumber}}."

export default { extractVariables, replaceVariable, hasVariables };
