import { useContext, useRef, useEffect, useState, useMemo, Fragment } from 'react';
import { useParams } from 'react-router-dom';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Components/ui
import { Box, LinearProgress, useTheme } from '@mui/material';
import {
  MessageItem,
  Date,
  NewMessages,
  ArrowDownButton,
  NewMessagesLabel,
} from './components';
import { style } from './style';
// Types
import { IMessage, MessageType } from '@trii/types/dist/Common/Messages';
import { IConversation } from '@trii/types/dist/Conversations';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import {
  getNextMessages,
  selectMessagesFetchStatus,
  selectNextMessagesFetchStatus,
} from 'redux/features/messagesSlice/messagesSlice';
// Moment
import moment from 'moment';
// DB
import { dbWorker } from 'db/db';

const FORMAT_DATE = 'DD/MM/YYYY';

type BodyProps = {
  conversationSelected: IConversation;
};

const Body = ({ conversationSelected }: BodyProps) => {
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const { contactId } = useParams<{ contactId: string }>();

  const messagesFetchStatus = useSelector(selectMessagesFetchStatus);
  const nextMessagesFetchStatus = useSelector(selectNextMessagesFetchStatus);

  const {
    selectMessagesMode,
    selectedMessages,
    conversationsDb,
    setSelectedMessages,
  } = useContext(conversationsContext);
  const {
    backgroundImage,
    messages,
    getNextNewMessages,
    setFooterSize,
    infoMessagesHandler,
    setFrom,
    setTo,
    setCc,
    setBcc,
    setSubjectDraft,
    setBodyDraft,
    setAttachments,
    handleOpenEmailMode,
    setTextDraft,
    setEmailConversationHasDraft,
  } = useContext(messagesContext);

  const conversationSelectedRef = useRef(conversationSelected);
  const containerRef = useRef<HTMLDivElement>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const messagesRef = useRef<IMessage[]>([]);
  const firstTimeMessageLoaded = useRef(false);

  messagesRef.current = messages;

  const today = moment();
  const todayFormat = today.format(FORMAT_DATE);
  const yesterday = today.subtract(1, 'days').format(FORMAT_DATE);
  const isNextLoading = nextMessagesFetchStatus === 'loading';

  const [filesDoneLoading, setFilesDoneLoading] = useState<boolean>(false);
  const [repliesInitializing, setRepliesInitializing] = useState<string[]>([]);
  // const [lastMessageId, setLastMessageId] = useState<string>(
  //   messages[messages.length - 1]?.id
  // );
  // const lastMessageId = messages[messages.length - 1]?.id;
  const [loadedMoreMessages, setLoadedMoreMessages] = useState<{
    status: boolean;
    scrollPosition: number;
    scrollHeight: number;
  }>({
    status: false,
    scrollPosition: 0,
    scrollHeight: 0,
  }); // Data used to keep track of the scroll bar position when loading more messages
  const [conversationsAllLoaded, setConversationsAllLoaded] = useState<string[]>([]);
  const [newMessageCount, setNewMessageCount] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(true);
  // Unread messages state
  const [unreadMessagesIds, setUnreadMessagesIds] = useState<string[]>([]);
  const [firstUnreadMessageId, setFirstUnreadMessageId] = useState<string>(null); // Used to render the unread messages chip in the chat

  let previousDate: string | null = null;

  // Functions
  const handleSelectMessage = (message: IMessage) => {
    const isMessageAdded = selectedMessages.some(
      (messageSelected) => messageSelected.id === message.id
    );
    if (
      selectMessagesMode &&
      message?.type !== MessageType.N2B &&
      message?.type !== MessageType.INFO
    ) {
      if (!isMessageAdded) {
        setSelectedMessages([...selectedMessages, message]);
      } else {
        setSelectedMessages(
          selectedMessages.filter(
            (messageSelected) => messageSelected.id !== message.id
          )
        );
      }
    }
  };

  const getNewMessageList = async (conversationId: string) => {
    const lastMessageId = messages[messages.length - 1]?.id;
    const newMessages = await getNextNewMessages({
      contactId: '',
      conversationId,
      pos: lastMessageId,
    });
    const oldMessagesIdsList = messages.map((message) => message.id);
    const oldMessagesIds = oldMessagesIdsList.flat();
    const messagesIsNews = newMessages?.filter(
      (message: IMessage) => !oldMessagesIds.includes(message.id)
    );
    return messagesIsNews;
  };

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    // Infinite scroll function
    e.preventDefault();
    e.stopPropagation();

    if (isNextLoading) return;

    // Scroll to top
    const top = e.currentTarget.scrollTop === 0;
    const current = e.currentTarget;

    setIsScrollAtBottom(
      current.scrollHeight - current.scrollTop === current.clientHeight ||
        current.scrollHeight - current.scrollTop < 700
    );

    if (top) {
      if (conversationSelected) {
        const fetchData = {
          contactId: conversationSelected.contactInfo.id,
          conversationId: conversationSelected.id,
          pos: messages[0]?.id,
        };

        setLoadedMoreMessages({
          status: true,
          scrollPosition: current.scrollTop,
          scrollHeight: current.scrollHeight,
        });
        await dispatch(getNextMessages(fetchData));
      } else {
        const conversationList = conversationsDb.filter(
          (conversation) => conversation.contactInfo.id === contactId
        );

        if (conversationList) {
          const conversationListSorted = conversationList.sort((a, b) => {
            const dateA = moment(a.updatedAt);
            const dateB = moment(b.updatedAt);
            return dateB.diff(dateA);
          });
          const result = await Promise.all(
            conversationListSorted.map(async (conversation, i) => {
              if (conversationsAllLoaded.includes(conversation.id)) return [];

              const messagesConversation = await getNewMessageList(conversation.id);

              if (messagesConversation && messagesConversation.length > 0) {
                return messagesConversation;
              } else {
                setConversationsAllLoaded([
                  ...conversationsAllLoaded,
                  conversation.id,
                ]);

                return [];
              }
            })
          );

          const resultList = result.filter((item) => item.length > 0).flat();

          if (resultList.length > 20) {
            const splitList = resultList.slice(0, 20);
            const lastMessage = splitList[splitList.length - 1];
            const removeLastConversation = conversationsAllLoaded.filter(
              (item) => item !== lastMessage.conversationId
            );
            setConversationsAllLoaded(removeLastConversation);
          }
        }
      }
    }
  };

  const handleScrollToBottom = (event?: React.MouseEvent) => {
    event?.preventDefault();
    const current = messageContainerRef.current;
    if (current) {
      current.scrollTo({
        top: current.scrollHeight,
        behavior: 'smooth',
      });
      setNewMessageCount(0);
    }
  };

  useEffect(() => {
    conversationSelectedRef.current = conversationSelected;
  }, [conversationSelected]);

  useEffect(() => {
    // Resets the state of the conversation selected when the component unmounts, used to stop reading new messages
    return () => {
      conversationSelectedRef.current = null;
      conversationSelected = null;
    };
  }, []);

  // Subscribe to get all messages event from db web worker
  useEffect(() => {
    dbWorker.onmessage = (event) => {
      if (event.data.action === 'getDraft' && event.data.data) {
        console.log('Draft received from db worker: ', event.data.data);
        const draft = event.data.data;
        // Check if the draft object has any of the EMAIL draft attributes like for example "to"
        // If it has any of them it means the draft is an email draft
        // If it does not has it it means its a Text draft

        if (draft.hasOwnProperty('to')) {
          setFrom(draft.from);
          setTo(draft.to);
          setCc(draft.cc);
          setBcc(draft.bcc);
          setAttachments(draft.attachments);
          if (draft.body && draft.body !== '') {
            setBodyDraft(draft.body);
          }
          setSubjectDraft(draft.subject);
          handleOpenEmailMode();
          setEmailConversationHasDraft(true);
        } else {
          setTextDraft(draft.text);
        }
      }
    };

    return () => {
      dbWorker.onmessage = null;
    };
  }, []);

  // On first load of a conversation: it scrolls to bottom after the messages are loaded
  // On adding new messages when the user scrolls at the top: it keeps the scroll position
  useEffect(() => {
    const current = messageContainerRef.current;

    if (unreadMessagesIds.length === 0 && loadedMoreMessages.status && current) {
      const newContentHeight =
        current.scrollHeight - loadedMoreMessages.scrollHeight;

      current.scrollTop = loadedMoreMessages.scrollPosition + newContentHeight;
      setLoadedMoreMessages({
        status: false,
        scrollPosition: 0,
        scrollHeight: 0,
      });

      return;
    }
    if (unreadMessagesIds.length === 0 || isScrollAtBottom) {
      current?.scrollTo({
        top: current.scrollHeight,
      });
    }

    return () => {
      setFilesDoneLoading(false);
    };
  }, [filesDoneLoading, repliesInitializing]);

  useEffect(() => {
    if (!firstTimeMessageLoaded.current && messages?.length > 0) {
      firstTimeMessageLoaded.current = true;

      // Create an array of message IDs with messageReference attribute
      const messageIdsWithReference = messages
        .filter((message) => message.messageReference)
        .map((message) => message.id);

      setRepliesInitializing(messageIdsWithReference);
    }

    return () => {
      setRepliesInitializing([]);
    };
  }, [messages]);

  useEffect(() => {
    setUnreadMessagesIds([]);
    setFirstUnreadMessageId(null);
  }, [conversationSelected]);

  useEffect(() => {
    if (messages && messagesFetchStatus === 'succeeded') {
      const messagesIds = messages.map((msg) => msg.id);

      let newMessages = messages.filter(
        (message) =>
          !messagesIds.includes(message.id) &&
          message.contactId === conversationSelected?.contactInfo.id
      );

      if (messages.length > 0) {
        const newMessagesIds = newMessages.map((message) => message.id);
        const isMessageFromUser = newMessages.some(
          (message) => message.direction === 2
        );
        // Add 'read' attribute to each new message that enters after the first load
        newMessages = newMessages.map((message) => ({ ...message, read: false }));

        // Check if any of the new messages has the "direction" attribute === 2 (it means the message has OUT direction not IN)
        if (!isMessageFromUser) {
          // Set new unread messages ids without erasing the previous ones
          setUnreadMessagesIds((unreadMessagesIds) => [
            ...unreadMessagesIds,
            ...newMessagesIds,
          ]);

          // Check if its the first time there is unread messages in the state (firstUnreadMessageId === false)
          // and set the first unread message id
          if (!firstUnreadMessageId) {
            setFirstUnreadMessageId(newMessagesIds[0]);
          } else if (firstUnreadMessageId && isMessageFromUser) {
            // Reset the unread messages alert if the user sends a message
            setFirstUnreadMessageId(null);
          }
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    setFooterSize('auto');
  }, []);

  return (
    <Box
      sx={{
        ...style.container,
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: theme.palette.mode == 'dark' ? '' : '#F0EBE3',
      }}
      ref={containerRef}
    >
      {!isScrollAtBottom && (
        <ArrowDownButton
          handleScrollToBottom={handleScrollToBottom}
          // newMessageCount={newMessageCount}
          unreadMessagesIds={unreadMessagesIds}
          showSnackbar={showSnackbar}
          setShowSnackbar={setShowSnackbar}
        />
      )}
      {newMessageCount > 0 && (
        <NewMessages
          isScrollAtBottom={isScrollAtBottom}
          onChipClick={handleScrollToBottom}
        />
      )}
      {isNextLoading && (
        <Box sx={style.progressContainer}>
          <LinearProgress />
        </Box>
      )}
      {useMemo(
        () => (
          <Box
            sx={style.secondaryContainer}
            onScroll={handleScroll}
            ref={messageContainerRef}
          >
            {messages?.map((message) => {
              if (
                message.type === MessageType.INFO &&
                !infoMessagesHandler.showMessages
              ) {
                return null;
              }

              // Format both dates to 'YYYY-MM-DD' to compare only the date part
              const currentDate = moment(message.timestamp).format('YYYY-MM-DD');
              const shouldRenderDate = previousDate !== currentDate;
              // Update the previousDate for the next iteration
              previousDate = currentDate;

              return (
                <Fragment key={message.id}>
                  {shouldRenderDate && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pr: 1,
                        pl: 1,
                        position: 'sticky',
                        top: '1rem',
                        zIndex: 10,
                      }}
                    >
                      <Date
                        day={moment(message.timestamp).format('DD/MM/YYYY')}
                        today={todayFormat}
                        yesterday={yesterday}
                      />
                    </Box>
                  )}
                  {firstUnreadMessageId === message.id && <NewMessagesLabel />}
                  <MessageItem
                    setUnreadMessagesIds={setUnreadMessagesIds}
                    handleSelectMessage={handleSelectMessage}
                    selectMessagesMode={selectMessagesMode}
                    selectedMessages={selectedMessages}
                    message={message}
                    setFilesDoneLoading={setFilesDoneLoading}
                    setRepliesInitializing={setRepliesInitializing}
                  />
                </Fragment>
              );
            })}
          </Box>
        ),
        [
          messages,
          containerRef,
          messageContainerRef,
          isNextLoading,
          selectMessagesMode,
          selectedMessages,
          contactId,
          infoMessagesHandler.showMessages,
        ]
      )}
    </Box>
  );
};

export default Body;
