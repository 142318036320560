// import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; // eslint-disable-line
import { Route, Routes as ReactRoutes, Navigate } from 'react-router-dom';
/** Components */
import MainLayout from './features/Layout/MainLayout';
import { Walls, SystemMVC } from './features/Views';
import Conversations from './features/Views/Conversations';
import Groups from './features/Views/Groups';
import WebHooksContainer from './features/Views/WebHooks';
import ScheduleContainer from './features/Views/Schedules';
import EndingContainer from './features/Views/Ending';
import LabelContainer from './features/Views/Label';
import FlowContainer from './features/Views/Flow';
import EditFlows from './features/Views/EditFlows';
import WebChatContainer from './features/Views/WebChat';
import WebChatEditContainer from './features/Views/WebChatEdit';
import EmailContainer from './features/Views/Email';
import EmailEditContainer from './features/Views/EmailEdit';
import SmsContainer from './features/Views/Sms';
import FormContainer from './features/Views/Form';
import FormEditContainer from './features/Views/FormEdit';
import PbxContainer from './features/Views/Pbx';
import WhatsAppContainer from './features/Views/WhatsApp';
import FacebookContainer from './features/Views/Facebook';
import FacebookEditContainer from './features/Views/FacebookEdit';
import InstagramContainer from './features/Views/Instagram';
import InstagramEditContainer from './features/Views/InstagramEdit';
import MercadoLibreContainer from './features/Views/MercadoLibre';
import MercadoLibreEditContainer from './features/Views/MercadoLibreEdit';
import GoogleBusinessContainer from './features/Views/GoogleBusiness';
import GoogleBusinessEditContainer from './features/Views/GoogleBusinessEdit';
import SipContainer from './features/Views/Sip';
import WhatsAppEditContainer from './features/Views/WhatsAppEdit';
import SmsEditContainer from './features/Views/SmsEdit';
import { Dashboard } from 'features/Views/Dashboard';
import ResponsesContainer from './features/Views/Responses';
import MyReports from './features/Views/MyReports/MyReports';
import { ReportDetails } from './features/Views/MyReports/components';
import SchedulesEditContainer from 'features/Views/SchedulesEdit';
import QuickResponseContainer from 'features/Views/QuickResponse/QuickResponseContainer';
import TemplatesContainer from 'features/Views/Templates/TemplatesContainer';
import DateTimeProvider from 'context/DateTime/DateTimeProvider';
// Context
import { useContext, useEffect } from 'react';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Types
import { IUserUpdate } from '@trii/types/dist/Common/EventsQueue/UserUpdate';
import { selectUser, updateUser } from 'redux/features/userSlice/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import RCS from 'features/Views/RCS/RCS';
import RCSEditContainer from 'features/Views/RCSEdit/RCSEditContainer';
import NotAllowed from 'features/Views/NotAllowed/NotAllowed';
import ProtectedRoute from 'functions/ProtectedRoute';
// Hooks
import useNotificationSound from 'features/Views/Conversations/hooks/useNotificationSound';

const FAVICONS = {
  ACTIVE: '/favicon/TriiNotification.svg',
  ORIGINAL: '/favicon.ico',
};

const Routes = () => {
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const { subscribeEvent, unsubscribeEvent, socketConnection } =
    useContext(socketContext);

  const notificationSound = useNotificationSound();

  useEffect(() => {
    const handleUserUpdate = (updatedUserData: IUserUpdate) => {
      dispatch(updateUser(updatedUserData));

      if (updatedUserData.userId === user.uid) {
        localStorage.setItem('userStatus', JSON.stringify(updatedUserData.status));
      }
    };

    if (socketConnection) {
      subscribeEvent('user_update', handleUserUpdate);
    }

    return () => {
      unsubscribeEvent('user_update');
    };
  }, [socketConnection]);

  // Handle favicon changes and notifications
  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    const originalFavicon = favicon?.href;

    const handleInactiveNotification = () => {
      if (document.hidden) {
        favicon.href = FAVICONS.ACTIVE;
        notificationSound.playNotification();
      }
    };

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        favicon.href = originalFavicon;
      }
    });

    if (socketConnection) {
      subscribeEvent('conversation_update', handleInactiveNotification);
    }

    return () => {
      unsubscribeEvent('conversation_update');

      document.removeEventListener('visibilitychange', () => {
        favicon.href = originalFavicon;
      });
    };
  }, [socketConnection]);

  return (
    <DateTimeProvider>
      <MainLayout>
        <ReactRoutes>
          <Route
            path="/a/conversations"
            element={<Navigate to="/a/conversations/Conversations" replace />}
          />
          <Route path="/a/conversations/notAllowed" element={<NotAllowed />} />

          {/* Protected Routes */}
          <Route
            path="/a/conversations/*"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.enabled']}
              >
                <Conversations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/Dashboard/:dashboardType?"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.dashboard']}
              >
                <Dashboard />
              </ProtectedRoute>
            }
            caseSensitive
          />
          <Route
            path="/a/conversations/socialwalls"
            element={
              <ProtectedRoute>
                <Walls />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/Conversations/:contactId?"
            element={
              <ProtectedRoute>
                <Conversations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/quick-responses"
            element={
              <ProtectedRoute>
                <QuickResponseContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/templates"
            element={
              <ProtectedRoute>
                <TemplatesContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/System"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <SystemMVC />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/webhooks"
            element={
              <ProtectedRoute>
                <WebHooksContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/System/endings"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <EndingContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/labels"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <LabelContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/groups"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <Groups />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/schedule"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <ScheduleContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/schedule/:id"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <SchedulesEditContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/flows"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <FlowContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/flows/:id"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <EditFlows />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/emails"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <EmailContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/emails/:id"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <EmailEditContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/webchats"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <WebChatContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/webchats/:id"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <WebChatEditContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/forms"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <FormContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/forms/:id"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <FormEditContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/pbx"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <PbxContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/whatsapp"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <WhatsAppContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/whatsapp/:id"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <WhatsAppEditContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/facebook"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <FacebookContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/facebook/:id"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <FacebookEditContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/instagram"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <InstagramContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/instagram/:id"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <InstagramEditContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/mercadolibre"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <MercadoLibreContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/mercadolibre/:id"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <MercadoLibreEditContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/sms"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <SmsContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/sms/:id"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <SmsEditContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/googlebusiness"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <GoogleBusinessContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/googlebusiness/:id"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <GoogleBusinessEditContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/sip"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <SipContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/responses"
            element={
              <ProtectedRoute
                requiredPermissions={['profile.conversations.editConfig']}
              >
                <ResponsesContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/schedule"
            element={
              <ProtectedRoute>
                <ScheduleContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/MyReports"
            element={
              <ProtectedRoute>
                <MyReports />
              </ProtectedRoute>
            }
            caseSensitive
          />
          <Route
            path="/a/conversations/myReports/view"
            element={
              <ProtectedRoute>
                <ReportDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/RCS"
            element={
              <ProtectedRoute>
                <RCS />
              </ProtectedRoute>
            }
          />
          <Route
            path="/a/conversations/system/RCS/:id"
            element={
              <ProtectedRoute>
                <RCSEditContainer />
              </ProtectedRoute>
            }
          />
        </ReactRoutes>
      </MainLayout>
    </DateTimeProvider>
  );
};

export default Routes;
