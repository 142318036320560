import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectRCSCreateStatus } from 'redux/features/RCSSlice/RCSSlice';
// Components/ui
import { Box, Button, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { RCSContext } from 'features/Views/RCS/context/RCSProvider';

function CreateButtons() {
  const { t } = useTranslation();
  const { endCreating, handleCreateRCS } = useContext(RCSContext);
  const RCSCreateStatus = useSelector(selectRCSCreateStatus);
  const isLoading = RCSCreateStatus === 'loading';
  const createSuccess = RCSCreateStatus === 'succeeded';

  const handleCreateButton = async () => {
    await handleCreateRCS();
  };

  useEffect(() => {
    if (createSuccess) {
      endCreating();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess]);
  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}

      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={endCreating}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loadingPosition="start"
        disabled={isLoading}
        loading={isLoading}  
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
        onClick={handleCreateButton}
      >
        {t('global.create')}
      </LoadingButton>
    </Box>
  );
}

export default CreateButtons;
