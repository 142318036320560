import React from 'react';
// Components/ui
import { Box, IconButton, Modal, Slide, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

type CustomModalProps = {
  handleClose: () => void;
  open: boolean;
  modalTitle: string;
  maxWidth: string;
  children: React.ReactNode;
};

/**
 * Renders a modal dialog box.
 * @param {Object} props - The component props.
 * @param {function} props.handleClose - Callback function to be called when the modal is closed.
 * @param {boolean} props.open - Determines whether the modal is currently visible.
 * @param {string} props.modalTitle - The title of the modal.
 * @param {string} props.maxWidth - The maximum width of the modal.
 * @param {React.ReactNode} props.children - The content of the modal.
 * @returns {React.ReactNode} The rendered modal dialog box.
 */
function CustomModal({
  handleClose,
  open,
  modalTitle,
  maxWidth,
  children,
}: CustomModalProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        marginTop: '5rem',
        background: 'transparent',
        // '& .MuiBackdrop-root': {
        //   background: 'transparent',
        // },
      }}
    >
      <Slide direction="down" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth,
            maxHeight: '40rem',
            minHeight: '10rem',
            height: 'max-content',
            borderRadius: 2,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
            backgroundColor: (theme) => theme.palette.background.default,
            mx: '50px'      
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '3rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1em',
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography variant="h6" color="textPrimary">
              {modalTitle}
            </Typography>
            <IconButton onClick={handleClose}>
              <Close
                sx={{
                  color: (theme) => theme.palette.text.disabled,
                }}
              />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Slide>
    </Modal>
  );
}

export default CustomModal;
