import { useEffect, useContext, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { selectCreteConversationStatus } from 'redux/features/conversationsSlice/conversationsSlice';
// Moment
import moment from 'moment';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Avatar, Box, CircularProgress, Typography } from '@mui/material';
// Context
import socialWallsContext from 'features/Views/Walls/context/SocialWallsContext';
// Components
import { Reply } from './components';
// Markdown
import { Markdown } from 'functions/Markdown';
// Hooks
import { useUserInfo } from 'hooks/useUserInfo';
// Types
import { WallMessage } from '@trii/types/dist/Conversations';
import { CreateConversation } from 'features/Views/Conversations/context/ConversationsProvider/types/CreateConversation';
import { markAsAnswered } from 'redux/features/socialWallSlice/socialWallSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

interface Props {
  message: WallMessage;
  margin?: number;
  keepScrollAtBottom: () => void;
  postIsMercadoLibre: boolean;
  postIsParent: boolean;
  postId?: string;
}

const Comment = ({
  message,
  margin = 0,
  keepScrollAtBottom,
  postIsMercadoLibre,
  postIsParent,
  postId,
}: Props) => {
  const dispatch = useAppDispatch();

  const { commentToConversationId, postSelected, createNewConversation } =
    useContext(socialWallsContext);
  const markdown = Markdown(message.message, false);
  const newText = markdown.replace(/\n/g, '<br />');
  const createConversationStatus = useSelector(selectCreteConversationStatus);
  const isLoading = createConversationStatus === 'loading';
  const { t } = useTranslation();
  const [userName, setUserName] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const userInfo = useUserInfo();

  const getUserInfo = async (userId: string) => {
    const { name, imageUrl } = await userInfo.getUserInfo(userId);
    setUserName(name);
    setImageUrl(imageUrl);
    keepScrollAtBottom();
  };

  const handleCreateChat = () => {
    const { contactId, contactAddressId } = message;
    const { type: channelType, id: channelId } = postSelected.channelInfo;
    const data = {
      contactId,
      contactAddressId: [contactAddressId],
      channelType,
      channelId,
    };
    createNewConversation(data as CreateConversation, message.id);
  };

  const handleMarkAsAnswered = () => {
    const markAsAnsweredData = {
      commentId: message.id,
      postId,
    };

    dispatch(markAsAnswered(markAsAnsweredData));
  };

  useEffect(() => {
    if (message) {
      if (message.userSender) {
        getUserInfo(message.userSender);
      } else if (message.externalUserSender) {
        setImageUrl(message.externalUserSender.imageUrl);
        setUserName(message.externalUserSender.name);
      }
      const date = moment(message.createdAt);
      const now = moment();
      const diff = now.diff(date, 'days');
      if (diff === 0) {
        setTime(t('socialWalls.today'));
      } else if (diff >= 0 && diff < 7) {
        setTime(t('socialWalls.daysAgo', { days: diff }));
      } else if (diff >= 7 && diff < 30) {
        setTime(t('socialWalls.weeksAgo', { weeks: Math.floor(diff / 7) }));
      } else if (diff >= 30 && diff < 365) {
        setTime(t('socialWalls.monthsAgo', { months: Math.floor(diff / 30) }));
      } else if (diff >= 365) {
        setTime(t('socialWalls.yearsAgo', { years: Math.floor(diff / 365) }));
      }
    }
  }, [message]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        color="text.primary"
        height="auto"
        my={1}
        ml={`${margin}rem`}
        sx={{
          borderLeft: (theme) => margin > 0 && `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          width={'100%'}
          justifyContent={'flex-start'}
        >
          <Avatar
            src={imageUrl}
            sx={{
              width: '22px',
              height: '22px',
              border: (theme) => `1px solid ${theme.palette.text.primary}`,
              padding: '2px',
              '& .MuiAvatar-img': {
                borderRadius: '50%',
              },
            }}
          />
          <Box ml={1} width="max-content">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.background.default
                    : theme.palette.grey[200],
                borderRadius: '5px',
                padding: '5px',
              }}
            >
              <Typography
                variant="subtitle1"
                fontSize={12}
                mr={1}
                fontWeight={'bold'}
                sx={{
                  textWrap: 'noWrap',
                }}
              >
                {userName}
              </Typography>
              <Box dangerouslySetInnerHTML={{ __html: newText }} />
            </Box>
            <Box display="flex" alignItems="center" gap={'5px'}>
              <Typography
                variant="caption"
                sx={{
                  userSelect: 'none',
                }}
              >
                {time}
              </Typography>
              {!postIsMercadoLibre && <Reply parentId={message.id} />}
              {postIsParent && postIsMercadoLibre && <Reply parentId={message.id} />}
              {message.contactId &&
                message.contactAddressId &&
                !postIsMercadoLibre &&
                (isLoading && commentToConversationId === message.id ? (
                  <CircularProgress size={15} thickness={3} />
                ) : (
                  <Typography
                    variant="caption"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleCreateChat}
                  >
                    - {t('socialWalls.chat')}
                  </Typography>
                ))}

              {(!message.responses || message.responses?.length === 0) &&
                postIsParent &&
                !message.readed && (
                  <Typography
                    variant="caption"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleMarkAsAnswered}
                  >
                    - {t('socialWalls.markAsAnswered')}
                  </Typography>
                )}
            </Box>
          </Box>
        </Box>
      </Box>
      {message.responses &&
        message.responses.length > 0 &&
        message.responses.map((resp) => (
          <Comment
            key={resp.id}
            message={resp}
            margin={margin + 2}
            keepScrollAtBottom={keepScrollAtBottom}
            postIsMercadoLibre={postIsMercadoLibre}
            postIsParent={false}
          />
        ))}
    </>
  );
};
export default Comment;
