import axios from 'axios';
import getRequestConfig from 'redux/functions/getRequestConfig';
// Types
import { IMessage } from '@trii/types/dist/Common/Messages';
import { ForwardMessage } from './types/ForwardMessage';
import { Comment } from './types/Comment';
import { HighlightMessage } from './types/HighlightMessage';
import { FormattedFormMessage } from './types/FormattedFormMessage';
import { ReactionData } from './types/ReactionData';
import { SearchMessageData } from './types/SearchMessageData';
import { UploadURLParams } from './types/UploadURLParams';
import { GetMessagesData } from './types/GetMessagesData';
import { OpenConversationOnFloatingWindowData } from './types/OpenConversationOnFloatingWindowData';

const fetchMessages = async (
  jwt: string,
  URL: string,
  getMessagesData: GetMessagesData,
  pos: string
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const { conversationId, contactId } = getMessagesData;

  const response =
    conversationId !== ''
      ? await axios.get(
          `${URL}/Messages?conversationId=${conversationId}&limit=20&pos=${pos}`,
          requestConfig
        )
      : await axios.get(
          `${URL}/Messages?contactId=${contactId}&limit=20&pos=${pos}`,
          requestConfig
        );

  return response.data;
};

const sendMessages = async (
  jwt: string,
  URL: string,
  message: IMessage | FormattedFormMessage
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const messageJson = JSON.stringify(message);
  const response = await axios.post(`${URL}/Messages`, messageJson, requestConfig);
  return response.data;
};

const searchMessage = async (
  jwt: string,
  URL: string,
  searchMessageData: SearchMessageData
): Promise<IMessage[]> => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const response = await axios.post<IMessage[]>(
    `${URL}/Messages/search`,
    searchMessageData,
    requestConfig
  );

  return response.data;
};

const sendReaction = async (
  jwt: string,
  URL: string,
  reactionData: ReactionData
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.patch(
    `${URL}/Messages/reaction`,
    reactionData,
    requestConfig
  );

  return response.data;
};

const uploadMedia = async (
  jwt: string,
  URL: string,
  file: FormData,
  name: string,
  id: string,
  URLParams?: UploadURLParams
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const UPLOAD_URL = URLParams
    ? `${URL}/upload?module=${URLParams.module}&folderType=${URLParams.folderType}`
    : `${URL}/upload`;

  const response = await axios.post(UPLOAD_URL, file, requestConfig);

  const data = {
    name,
    url: response.data,
    id,
  };
  return data;
};

const uploadAudio = async (
  jwt: string,
  URL: string,
  file: FormData,
  name: string,
  id: string,
  URLParams: UploadURLParams
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const response = await axios.post(
    `${URL}/upload/audio?module=${URLParams.module}&folderType=${URLParams.folderType}`,
    file,
    requestConfig
  );
  const data = {
    name,
    url: response.data,
    id,
  };
  return data;
};

const fetchVariables = async (
  jwt: string,
  URL: string,
  variable: string,
  contactId: string,
  conversationId: string
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(
    `${URL}/Vars?q=${variable}&contactId=${contactId}&conversationId=${conversationId}`,
    requestConfig
  );
  return response.data;
};

const fetchForwardMessage = async (
  jwt: string,
  URL: string,
  data: ForwardMessage
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.put(`${URL}/Actions/Forward`, data, requestConfig);
  return response.data;
};

const fetchAddComment = async (jwt: string, URL: string, data: Comment) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.post(
    `${URL}/Actions/Comment/${data.messageId}/${data.shardKey}`,
    {
      text: data.text,
    },
    requestConfig
  );
  return response.data;
};

const fetchDeleteComment = async (jwt: string, URL: string, data: Comment) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.delete(
    `${URL}/Actions/Comment/${data.messageId}/${data.shardKey}`,
    requestConfig
  );
  return response.data;
};

const fetchHighlightMessage = async (
  jwt: string,
  URL: string,
  data: HighlightMessage
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.patch(
    `${URL}/Actions/Highlighted/${data.messageId}/${data.shardKey}`,
    {
      isHighlighted: data.isHighlighted,
    },
    requestConfig
  );
  return response.data;
};

const openConversationOnFloatingWindow = async (
  jwt: string,
  URL: string,
  data: OpenConversationOnFloatingWindowData
) => {
  const { uid, conversationId, chatId } = data;
  const requestConfig = getRequestConfig.contentTypeJson(jwt);

  try {
    const response = await axios.post(
      `${URL}/FloatingWindows/${uid}`,
      { conversationId, chatId },
      requestConfig
    );
    return response;
  } catch (error) {
    return { ...error, chatWindowData: { conversationId, chatId } };
  }
};

const messagesService = {
  fetchMessages,
  sendMessages,
  uploadMedia,
  fetchVariables,
  fetchForwardMessage,
  fetchAddComment,
  fetchDeleteComment,
  fetchHighlightMessage,
  sendReaction,
  uploadAudio,
  searchMessage,
  openConversationOnFloatingWindow,
};

export default messagesService;
