// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Avatar, Box, IconButton, Modal, Tooltip, Typography } from '@mui/material';
// Icons
import { Campaign, Star, StarBorder } from '@mui/icons-material';
// Types
import { useContext, useEffect, useState } from 'react';
import { ModalSubscription } from './components';
import { IContact, IContactAddress } from '@trii/types/dist/Contacts';
import { SetFavoriteAddressData } from 'redux/features/contactInfoSlice/types/SetFavoriteAddressData';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setFavoriteAddress } from 'redux/features/contactInfoSlice/contactInfoSlice';
import { Dispatch, SetStateAction } from 'react';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import axios from 'axios';
import { styled } from '@mui/system';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { setCreateConversationFromCreateButton } from 'redux/features/conversationsSlice/conversationsSlice';
interface ContactProps {
  contactList: IContactAddress[];
  icon: React.ReactNode;
  title: string;
  showCampaign?: boolean;
  showTitle?: boolean;
  contactId: string;
  setContactInfo: Dispatch<SetStateAction<IContact>>;
  contactInfo: IContact;
  type: ChannelType;
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.dropdownMenu,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  color: theme.palette.text.primary,
  width: '50%',
}));
const Contact = ({
  contactList,
  icon,
  title,
  showCampaign = false,
  showTitle = true,
  contactId,
  setContactInfo,
  contactInfo,
  type,
}: ContactProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [showModalSubs, setShowModalSubs] = useState(false);
  const [contactDataModal, setContactDataModal] = useState<IContactAddress>();

  const iconStyle = { color: 'text.disabled'};

  const openModalSubs = (data: IContactAddress) => {
    setContactDataModal(data);
    setShowModalSubs(true);
  };
  const closeModalSubs = () => {
    setShowModalSubs(false);
  };
  const StyledAvatar = styled(Avatar)(({ theme }) => ({
    display: 'flex',
    border: `1px solid ${theme.palette.disabled}`,
    padding: '2px',
    width: 18,
    height: 18,
    position: 'absolute',
    bottom: '7px',
    fontSize: '0.85rem',
    '& .MuiAvatar-img': {
      borderRadius: '50%',
    },
  }));
  const { setModalTitle, setOpenModalList } = useContext(conversationsContext);

  const handleCreateConversation = () => {
    setModalTitle(t('conversations.createConversation.title'));
    dispatch(setCreateConversationFromCreateButton(false));

    setOpenModalList(true);
  };
  const handleCallContact = (address: string) => {
    if (!address) return;

    const event = new CustomEvent('call-contact-conversations', {
      detail: { phoneAddress: address },
    });

    window.dispatchEvent(event);
  };

  const handleSetFavorite = async (contactAddressId: string, value: boolean) => {
    const data: SetFavoriteAddressData = {
      contactId,
      contactAddressId,
      value,
    };

    const res = await dispatch(setFavoriteAddress(data));

    if (res.payload === 'OK') {
      switch (type) {
        case ChannelType.PHONE:
          const newPhones = contactInfo.phones.map((phone) => {
            if (phone.id === contactAddressId) {
              return { ...phone, isFavorite: value };
            }
            return phone;
          });

          setContactInfo({
            ...contactInfo,
            phones: newPhones,
          });
          break;

        case ChannelType.EMAIL:
          const newEmails = contactInfo.emails.map((email) => {
            if (email.id === contactAddressId) {
              return { ...email, isFavorite: value };
            }
            return email;
          });

          setContactInfo({
            ...contactInfo,
            emails: newEmails,
          });
          break;

        case ChannelType.WHATSAPP:
          const newWhatsapp = contactInfo.ims_whatsapp.map((whatsapp) => {
            if (whatsapp.id === contactAddressId) {
              return { ...whatsapp, isFavorite: value };
            }
            return whatsapp;
          });

          setContactInfo({
            ...contactInfo,
            ims_whatsapp: newWhatsapp,
          });
          break;

        case ChannelType.FACEBOOK:
          const newFacebook = contactInfo.ims_facebook.map((facebook) => {
            if (facebook.id === contactAddressId) {
              return { ...facebook, isFavorite: value };
            }
            return facebook;
          });

          setContactInfo({
            ...contactInfo,
            ims_facebook: newFacebook,
          });
          break;

        case ChannelType.INSTAGRAM:
          const newInstagram = contactInfo.ims_instagram.map((instagram) => {
            if (instagram.id === contactAddressId) {
              return { ...instagram, isFavorite: value };
            }
            return instagram;
          });

          setContactInfo({
            ...contactInfo,
            ims_instagram: newInstagram,
          });
          break;

        default:
          break;
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'max-content',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {showTitle && (
        <Box
          sx={{
            width: '100%',
            borderBottom: '1px solid',
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight="semi-bold"
            color={'text.disabled'}
          >
            {t(title)}
          </Typography>
        </Box>
      )}
      {contactList.length > 0 &&
        contactList.map((contact: IContactAddress) => (
          <StyledBox
            key={contact.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: 1,
              paddingBottom: '0px',
              minHeight: '50px',
              position: 'relative',
              // '&:hover': {
              //   backgroundColor: (theme) => theme.palette.action.hover,
              // },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 1,
                maxWidth: '70%',
              }}
            >
              <Box
                display="flex"
                flexDirection={'column'}
                sx={{
                  minHeight: '40px',
                  justifyContent: 'space-between',
                }}
              >
                {type === ChannelType.PHONE ? (
                  <IconButton
                    sx={{ marginTop: '-3px', padding: 0 }}
                    onClick={() => handleCallContact(contact.address)}
                  >
                    {icon}
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ marginTop: '-3px', padding: 0 }}
                    onClick={handleCreateConversation}
                  >
                    {icon}
                  </IconButton>
                )}
                {contact.profileUrl !== '' && (
                  <StyledAvatar src={contact.profileUrl} alt={contact.profileName}>
                    {/* {contact.profileName
                        ? contact.profileName.charAt(0).toUpperCase()
                        : null} */}
                  </StyledAvatar>
                )}          
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    height: '30px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      maxWidth: '-webkit-fill-available',
                      height: '11px',
                    }}
                  >
                    <Tooltip title={contact.address} arrow placement="top">
                      <Typography
                        variant="subtitle1"
                        fontWeight="semi-bold"
                        fontSize={14}
                        color="text.primary"
                        sx={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          cursor: 'default',
                          maxWidth: '-webkit-fill-available',
                        }}
                      >
                        {contact.address}
                      </Typography>
                    </Tooltip>
                  </Box>
                  {contact.profileName && (
                    <Typography
                      variant="body2"
                      fontSize={10}
                      color="text.disabled"
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        maxWidth: '-webkit-fill-available',
                        marginLeft: '10px'
                      }}
                    >
                      {contact.profileName}
                    </Typography>
                  )}                    
                </Box>
                {contact.note && (
                  <Typography
                    variant="body2"
                    fontSize={12}
                    color="text.secondary"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: '-webkit-fill-available',
                      position: 'absolute',
                      bottom: '0',
                    }}
                  >
                    {contact.note}
                  </Typography>
                )}             
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '-25px',
              }}
            >
              {showCampaign && (
                <Tooltip
                  title={t('conversations.contactInfo.suscriptions')}
                  placement="top"
                  arrow
                >
                  <IconButton
                    size="small"
                    onClick={() => openModalSubs(contact)}
                    sx={{ marginTop: '-5px', marginRight: '5px', padding: 0 }}
                  >
                    <Campaign sx={iconStyle} />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                onClick={() => handleSetFavorite(contact.id, !contact.isFavorite)}
                size="small"
                sx={{ marginTop: '-5px', padding: 0 }}
              >
                {contact.isFavorite ? (
                  <Star
                    sx={{
                      color: '#fcac34',
                    }}
                  />
                ) : (
                  <StarBorder sx={iconStyle} />
                )}
              </IconButton>
            </Box>
          </StyledBox>
        ))}
      <ModalSubscription
        openModal={showModalSubs}
        onClose={closeModalSubs}
        icon={icon}
        contactAddress={contactDataModal}
      />
    </Box>
  );
};

export default Contact;
