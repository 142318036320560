import { useCallback, useContext, useEffect, useRef } from 'react';
// Router
import { useSearchParams } from 'react-router-dom';
// Context
import FilePreviewerProvider from '../context/FilePreviewerProvider/FilePreviewerProvider';
import { conversationsContext } from '../context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from '../context/MessagesProvider/MessagesProvider';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Components/ui
import Box from '@mui/material/Box';
import {
  Conversation,
  SidebarContactList,
  ChatDetailsSidebar,
  SelectConversationScreen,
  FilePreviewer,
  ImageViewer,
  ModalList,
  SearchMessage,
} from './components';
import { Slide } from '@mui/material';
// Redux
import { fetchConversation } from 'redux/features/conversationSlice/conversationSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  resetConversationsOfContact,
  resetConversationsOfContactFetchedTypes,
  selectConversationSelected,
  setConversationSelected,
  setSelectedContactInfo,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { resetState } from 'redux/features/contactInfoSlice/contactInfoSlice';
import { useSelector } from 'react-redux';
import { getMessages } from 'redux/features/messagesSlice/messagesSlice';
// Hooks
import { useOpenCallPanelWidth } from 'features/Layout/MainLayout/utils/useOpenCallPanelWidth';
// Db
import { dbWorker } from 'db/db';
// Types
import { IConversation } from '@trii/types/dist/Conversations';

const ConversationsContainer = () => {
  const dispatch = useAppDispatch();
  const {
    conversationsDb,
    openConversation,
    loadContactDetails,
    setConversationNotFound,
    resetConversationState,
  } = useContext(conversationsContext);
  const { isSearching, fileSelectorMode } = useContext(messagesContext);
  const { subscribeEvent, unsubscribeEvent, socketConnection } =
    useContext(socketContext);

  const conversationSelected = useSelector(selectConversationSelected);

  const { boxChatWidth } = useOpenCallPanelWidth();
  const [searchParams] = useSearchParams();

  const containerRef = useRef(null);

  const contactId = searchParams.get('contactId');
  const conversationId = searchParams.get('conversationId');

  // Handles key press events like Escape
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        dispatch(setConversationSelected(null));
        dispatch(setSelectedContactInfo(null));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (socketConnection) {
      subscribeEvent('message_update', async (data) => {
        await dbWorker.postMessage({
          action: 'updateMessage',
          data: data,
        });
      });
    }

    return () => {
      unsubscribeEvent('message_update');
    };
  }, [socketConnection]);

  // Handle selecting conversations via URL parameters
  useEffect(() => {
    const selectConversationViaParams = async (paramName: string, id: string) => {
      if (!id || !conversationsDb) return;

      dispatch(resetConversationsOfContact());
      dispatch(resetConversationsOfContactFetchedTypes());
      dispatch(resetState());

      resetConversationState();

      if (paramName === 'conversationId') {
        const conversation = conversationsDb.find((conv) => conv.id === id);

        if (conversation) {
          openConversation(conversation);
        } else {
          const response = await dispatch(fetchConversation(id));
          const conversation = response.payload as IConversation;

          openConversation(conversation);
        }
      } else if (paramName === 'contactId') {
        const conversation = conversationsDb.find(
          (conv) => conv.contactInfo?.id === id
        );

        if (conversation) {
          openConversation(conversation);
        } else {
          await dispatch(getMessages({ conversationId: '', contactId: id }));

          loadContactDetails(id);
          dispatch(setConversationSelected(null));
          setConversationNotFound(true);
        }
      } else {
        setConversationNotFound(false);
      }
    };

    if (contactId) selectConversationViaParams('contactId', contactId);
    if (conversationId)
      selectConversationViaParams('conversationId', conversationId);
  }, [searchParams, conversationsDb]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  return (
    <Box
      display="flex"
      margin="1rem"
      sx={{
        height: 'calc(100% - 2rem)',
        width: 'calc(100vw - 5rem)',
        backgroundColor: (theme) => theme.palette.background.default,
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        maxWidth: boxChatWidth,
      }}
    >
      <Box display="flex" width="100%" height="100%" ref={containerRef}>
        <SidebarContactList />
        {conversationSelected || contactId ? (
          <>
            <Conversation
              notFound={!conversationSelected}
              conversationSelected={conversationSelected}
              contactId={contactId}
            />
            <FilePreviewerProvider>
              {fileSelectorMode && <FilePreviewer />}
            </FilePreviewerProvider>
            <ImageViewer />
            <Box sx={{ minWidth: '25%', maxWidth: '25%', height: '100%' }}>
              {isSearching ? (
                <Slide direction="left" in={isSearching} mountOnEnter unmountOnExit>
                  <SearchMessage />
                </Slide>
              ) : (
                <ChatDetailsSidebar />
              )}
            </Box>
          </>
        ) : (
          <SelectConversationScreen />
        )}
      </Box>
      <ModalList />
    </Box>
  );
};

export default ConversationsContainer;
