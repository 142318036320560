import React, { useEffect, useState } from 'react';
import Handlebars from 'handlebars';
// Redux
import { useSelector } from 'react-redux';
import { selectJsonButtonDataByIds } from 'redux/features/buttonsSlice/buttonsSlice';

interface TemplateRendererProps {
  template: string;
  buttonId: number;
  contactId: string;
}

const TemplateRenderer: React.FC<TemplateRendererProps> = ({
  template,
  buttonId,
  contactId,
}) => {
  const [html, setHtml] = useState<string>('');
  const buttonData = useSelector(selectJsonButtonDataByIds(buttonId, contactId));

  useEffect(() => {
    if (buttonData) {
      console.log('button', template, buttonData);
      const compiledTemplate = Handlebars.compile(template);
      const result = compiledTemplate(buttonData.data);

      console.log('button', result);

      setHtml(result);
    }
  }, [template, buttonData]);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default TemplateRenderer;
