import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
// Types
import {
  ConversationState,
  FilterGif,
  FilterStickers,
} from './types/ConversationState';
import { RootState } from '../../store';
// Axios
import axios from 'axios';
import { initRequestData } from 'redux/functions/initRequestData';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Utils
import conversationService from './service';

const API_KEY = 'AIzaSyBmwG8duMvNfnhQOaOek4HnssawPjiyu84';
const API_KEY_GIPHY = 'Ck8kjVBEmylb6nbRLuZo8nmeLminb9hE';
const LIMIT = 50;
const URL = 'https://tenor.googleapis.com/v2/';

const initialState: ConversationState = {
  conversation: null,
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
    update: 'idle',
    gif: 'idle',
    newGifts: 'idle',
    messages: 'idle',
    stickers: 'idle',
  },
  gif: null,
  newGifts: null,
  messages: null,
  stickers: null,
  newStickers: null,
};

export const fetchConversation = createAsyncThunk(
  'conversation/fetchConversation',
  async (contactId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationService.fetch(
      jwtToken,
      URL_CONVERSATIONS,
      contactId
    );

    return response;
  }
);

export const getTenorGifts = createAsyncThunk(
  'conversation/getTenorGifts',
  async () => {
    const response = await axios.get(`${URL}featured?key=${API_KEY}&limit=${LIMIT}`);

    return response.data;
  }
);

export const getNextTenorGifts = createAsyncThunk(
  'conversation/getNextTenorGifts',
  async (next: string) => {
    const response = await axios.get(
      `${URL}featured?key=${API_KEY}&limit=${LIMIT}&pos=${next}`
    );

    return response.data;
  }
);

export const getFilterTenorGifts = createAsyncThunk(
  'conversation/getFilterTenorGifts',
  async (search: string) => {
    const response = await axios.get(
      `${URL}search?q=${search}&key=${API_KEY}&limit=${LIMIT}`
    );

    return response.data;
  }
);

export const getNextFilterTenorGifts = createAsyncThunk(
  'conversation/getNextFilterTenorGifts',
  async ({ search, next }: FilterGif) => {
    const response = await axios.get(
      `${URL}search?q=${search}&key=${API_KEY}&limit=${LIMIT}&pos=${next}`
    );

    return response.data;
  }
);

export const getStickers = createAsyncThunk('conversation/getStickers', async () => {
  const response = await axios.get(`https://api.giphy.com/v1/stickers/trending/`, {
    params: {
      api_key: API_KEY_GIPHY,
      limit: LIMIT,
    },
  });

  return response.data;
});

export const getNextStickers = createAsyncThunk(
  'conversation/getNextStickers',
  async (next: number) => {
    const response = await axios.get(`https://api.giphy.com/v1/stickers/trending/`, {
      params: {
        api_key: API_KEY_GIPHY,
        limit: LIMIT,
        offset: next,
      },
    });
    return response.data;
  }
);

export const getFilterStickers = createAsyncThunk(
  'conversation/getFilterStickers',
  async ({ value, language }: FilterStickers) => {
    const response = await axios.get(`https://api.giphy.com/v1/stickers/search`, {
      params: {
        api_key: API_KEY_GIPHY,
        limit: LIMIT,
        q: value,
        lang: language,
      },
    });

    return response.data;
  }
);

export const getNextFilterStickers = createAsyncThunk(
  'conversation/getNextFilterStickers',
  async ({ value, language, next }: FilterStickers) => {
    const response = await axios.get(`https://api.giphy.com/v1/stickers/search`, {
      params: {
        api_key: API_KEY_GIPHY,
        limit: LIMIT,
        q: value,
        offset: next,
        lang: language,
      },
    });

    return response.data;
  }
);

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTenorGifts.pending, (state) => {
        state.status.gif = 'loading';
      })
      .addCase(getTenorGifts.fulfilled, (state, action) => {
        state.status.gif = 'succeeded';
        state.gif = action.payload;
      })
      .addCase(getFilterTenorGifts.pending, (state) => {
        state.status.gif = 'loading';
      })
      .addCase(getFilterTenorGifts.fulfilled, (state, action) => {
        state.status.gif = 'succeeded';
        state.gif = action.payload;
      })
      .addCase(getNextTenorGifts.pending, (state) => {
        state.status.newGifts = 'loading';
      })
      .addCase(getNextTenorGifts.fulfilled, (state, action) => {
        state.status.newGifts = 'succeeded';
        state.newGifts = action.payload;
      })
      .addCase(getNextFilterTenorGifts.pending, (state) => {
        state.status.newGifts = 'loading';
      })
      .addCase(getNextFilterTenorGifts.fulfilled, (state, action) => {
        state.status.newGifts = 'succeeded';
        state.newGifts = action.payload;
      })
      .addCase(getStickers.pending, (state) => {
        state.status.stickers = 'loading';
      })
      .addCase(getStickers.fulfilled, (state, action) => {
        state.status.stickers = 'succeeded';
        state.stickers = action.payload;
      })
      .addCase(getNextStickers.pending, (state) => {
        state.status.stickers = 'loading';
      })
      .addCase(getNextStickers.fulfilled, (state, action) => {
        state.status.stickers = 'succeeded';
        state.newStickers = action.payload;
      })
      .addCase(getFilterStickers.pending, (state) => {
        state.status.stickers = 'loading';
      })
      .addCase(getFilterStickers.fulfilled, (state, action) => {
        state.status.stickers = 'succeeded';
        state.stickers = action.payload;
      })
      .addCase(getNextFilterStickers.pending, (state) => {
        state.status.stickers = 'loading';
      })
      .addCase(getNextFilterStickers.fulfilled, (state, action) => {
        state.status.stickers = 'succeeded';
        state.newStickers = action.payload;
      });
  },
});

const conversationsState = (state: RootState) => state.Conversation;
export const selectConversation = createSelector(
  conversationsState,
  (conversation) => conversation.conversation
);
export const selectTenorGifts = createSelector(
  conversationsState,
  (conversation) => conversation.gif
);
export const selectTenorGiftsFetchStatus = createSelector(
  conversationsState,
  (conversation) => conversation.status.gif
);
export const selectNewTenorGifts = createSelector(
  conversationsState,
  (conversation) => conversation.newGifts
);
export const selectNewTenorGiftsFetchStatus = createSelector(
  conversationsState,
  (conversation) => conversation.status.newGifts
);
export const selectTenorStickers = createSelector(
  conversationsState,
  (conversation) => conversation.stickers
);
export const selectTenorStickersFetchStatus = createSelector(
  conversationsState,
  (conversation) => conversation.status.stickers
);
export const selectNewTenorStickers = createSelector(
  conversationsState,
  (conversation) => conversation.newStickers
);

export default conversationSlice.reducer;
