// Components/ui
import Badge from '@mui/material/Badge';
// Icons
import { Inbox, MarkEmailRead, Markunread, Email, Forum } from '@mui/icons-material';
// Types
import { ConversationFilters } from 'features/Views/Conversations/context/ConversationsProvider/types/ConversationFilters';

const conversationFilters: ConversationFilters[] = [
  {
    id: 1,
    label: 'conversations.filters.main',
    value: 'main',
    icon: <Inbox />,
  },
  {
    id: 2,
    label: 'conversations.filters.new',
    value: 'new',
    icon: <Markunread />,
  },
  {
    id: 4,
    label: 'conversations.filters.active',
    value: 'active',
    icon: (
      <Badge badgeContent=" " color="success" variant="dot">
        <Email />
      </Badge>
    ),
  },
  {
    id: 5,
    label: 'conversations.filters.activeByOther',
    value: 'activeByOthers',
    icon: (
      <Badge badgeContent=" " color="success" variant="dot">
        <Forum />
      </Badge>
    ),
  },
  {
    id: 6,
    label: 'conversations.filters.finalized',
    value: 'finished',
    icon: <MarkEmailRead />,
  },
];

export default conversationFilters;
