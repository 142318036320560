import { Box, CircularProgress, List, Typography } from '@mui/material';
import { FormItem } from './components';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchForms,
  selectAllForms,
  selectFormsFetchStatus,
} from 'redux/features/formSlice/formSlice';
import { RequestStatus } from 'redux/types/RequestStatus';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'redux/rootReducer';
import { AnyAction } from 'redux';
import { useContext, useEffect } from 'react';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

const BotAnswer = () => {

  const conversationSelected = useSelector(selectConversationSelected);

  const botAnswers = conversationSelected?.botVars.map((data, index) => (
    <FormItem key={index} data={data} index={index} />
  ));

  return (
    <Box
      borderRadius={1}
      px={{ md: '1rem', lg: '1rem' }}
      sx={{
        color: (theme) => theme.palette.text.primary,
        //@ts-ignore
        backgroundColor: (theme) => theme.palette.background.panel,
      }}
    >
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 6rem)',
          overflow: 'auto',
        }}
      >
        {
          botAnswers?.length > 0 ? (
            botAnswers
          ) : null
        }
      </List>
    </Box>
  );
};

export default BotAnswer;
