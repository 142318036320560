import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
// Types
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { IConversation } from '@trii/types/dist/Conversations';
import { RootState } from '../../store';
import { ConversationsState } from './types/ConversationsState';
import { TransferConversationData } from './types/TransferConversationData';
import { ModifyConversationLabelData } from './types/ModifyConversationLabelData';
import { FinalizeConversationData } from './types/FinalizeConversationData';
import type { FetchConversationsFilter } from './types/FetchConversationsFilter';
import { IGroup } from '@trii/types/dist/Conversations/Groups/Group';
import { CreateConversation } from 'features/Views/Conversations/context/ConversationsProvider/types/CreateConversation';
import { IContact } from '@trii/types/dist/Contacts';
import { Chat } from './types/Chat';
import { UpdateChat } from './types/UpdateChat';
import { OpenInFooter } from './types/OpenInFooter';
import { ConversationsOfContact } from './types/ConversationsOfContact';
import { Pin } from './types/Pin';
// Service
import conversationsService from './conversationsService';
import contactInfoService from '../contactInfoSlice/contactInfoService';
// Db
import db, { dbWorker } from 'db/db';
import { ChatRequestFilter } from './types/ChatRequestFilter';
import { ChannelType } from '@trii/types/dist/Common/Channels';

const initialState: ConversationsState = {
  loadingConversation: false,
  conversationSelected: null,
  conversations: [],
  newEvents: false,
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
    update: 'idle',
    pin: 'idle',
    in: 'idle',
    out: 'idle',
    transfer: 'idle',
    modify: 'idle',
    finalize: 'idle',
    groups: 'idle',
    channels: 'idle',
    openInFooter: 'idle',
    contactInfo: 'idle',
    chat: 'idle',
    chats: 'idle',
    updateChat: 'idle',
    conversationsOfContact: 'idle',
    unarchiveChat: 'idle',
    archiveChat: 'idle',
  },
  groups: [],
  channels: [],
  contactInfo: null,
  chat: null,
  chats: [],
  conversationsOfContact: [],
  conversationsOfContactFetchedTypes: {
    //Fetch state for the 'active' and 'unactive' conversations on the contact details panel, where they fetched?
    active: false,
    finished: false,
  },
  creatingConversationFromCreateButton: false,
};

export const getConversations = createAsyncThunk(
  'conversations/getConversations',
  async (filter: FetchConversationsFilter, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await conversationsService.fetchConversations(
      jwtToken,
      URL_CONVERSATIONS,
      filter
    );

    return response;
  }
);
export const pinConversation = createAsyncThunk(
  'conversations/pinConversation',
  async (data: Pin, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.pinConversation(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);
export const inConversation = createAsyncThunk(
  'conversations/inConversation',
  async (conversationId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.inConversation(
      jwtToken,
      URL_CONVERSATIONS,
      conversationId
    );

    return response;
  }
);
export const readConversation = createAsyncThunk(
  'conversations/readConversation',
  async (
    data: { conversationId: string; type: 'internal' | 'external' },
    { dispatch }
  ) => {
    try {
      const { conversationId, type } = data;
      const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
        .payload as InitRequestDataReturn;

      const response = await conversationsService.readConversation(
        jwtToken,
        URL_CONVERSATIONS,
        conversationId,
        type
      );

      return response;
    } catch (error) {
      return error.response;
    }
  }
);
export const outConversation = createAsyncThunk(
  'conversations/outConversation',
  async (conversationId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.outConversation(
      jwtToken,
      URL_CONVERSATIONS,
      conversationId
    );

    return response;
  }
);
export const transferConversation = createAsyncThunk(
  'conversations/transferConversation',
  async (data: TransferConversationData, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.transferConversation(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);
export const modifyConversationLabel = createAsyncThunk(
  'conversations/modifyConversationLabel',
  async (data: ModifyConversationLabelData, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.modifyLabel(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);
export const finalizeConversation = createAsyncThunk(
  'conversations/finalizeConversation',
  async (data: FinalizeConversationData, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await conversationsService.finalizeConversation(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return data.conversationId;
  }
);

export const fetchGroups = createAsyncThunk<IGroup[]>(
  'contacts/fetchGroups',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.fetchGroups(
      jwtToken,
      URL_CONVERSATIONS
    );

    return response;
  }
);

export const fetchChannels = createAsyncThunk(
  'contacts/fetchChannels',
  async (channelType: ChannelType, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.fetchChannels(
      jwtToken,
      URL_CONVERSATIONS,
      channelType
    );

    return response;
  }
);

export const fetchOpenInFooter = createAsyncThunk(
  'contacts/fetchOpenInFooter',
  async (data: OpenInFooter, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.fetchOpenInFooter(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);

export const createConversation = createAsyncThunk(
  'conversations/createConversation',
  async (data: CreateConversation, { dispatch }) => {
    try {
      const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
        .payload as InitRequestDataReturn;
      const response = await conversationsService.createConversation(
        jwtToken,
        URL_CONVERSATIONS,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }
);

export const getContactInfo = createAsyncThunk(
  'conversations/getContactInfo',
  async (userId: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await contactInfoService.fetchContactInfo(
      jwtToken,
      URL_CONTACTS,
      userId
    );

    return response;
  }
);

export const fetchNewChat = createAsyncThunk(
  'conversations/fetchNewChat',
  async (data: Chat, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await conversationsService.fetchNewChat(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);

export const fetchChats = createAsyncThunk(
  'conversations/fetchChats',
  async (filter: ChatRequestFilter, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await conversationsService.fetchChats(
      jwtToken,
      URL_CONVERSATIONS,
      filter
    );

    return response;
  }
);

export const fetchUpdateChat = createAsyncThunk(
  'conversations/fetchUpdateChat',
  async (data: UpdateChat, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await conversationsService.fetchUpdateChat(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);

export const unarchiveChat = createAsyncThunk(
  'conversations/unarchiveChat',
  async (conversationId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.unarchiveChat(
      jwtToken,
      URL_CONVERSATIONS,
      conversationId
    );
    return response;
  }
);

export const archiveChat = createAsyncThunk(
  'conversations/archiveChat',
  async (conversationId: string, { dispatch }) => {
    try {
      const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
        .payload as InitRequestDataReturn;

      const response = await conversationsService.archiveChat(
        jwtToken,
        URL_CONVERSATIONS,
        conversationId
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }
);

export const fetchConversationsOfContact = createAsyncThunk(
  'conversations/fetchConversationsOfContact',
  async (data: ConversationsOfContact, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { contactId, status } = data;
    const response = await conversationsService.fetchConversationsOfContact(
      jwtToken,
      URL_CONVERSATIONS,
      contactId,
      status
    );

    return { response, status };
  }
);

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    setCreateConversationFromCreateButton(state, action: PayloadAction<boolean>) {
      state.creatingConversationFromCreateButton = action.payload;
    },
    setConversationSelected(state, action: PayloadAction<IConversation>) {
      state.conversationSelected = action.payload;
    },
    setLoadingConversation(state, action: PayloadAction<boolean>) {
      state.loadingConversation = action.payload;
    },
    setChat(state, action: PayloadAction<IConversation>) {
      const chatIndex = state.chats?.findIndex(
        (chat) => chat.id === action.payload.id
      );

      if (chatIndex !== -1 && chatIndex !== undefined) {
        console.log('setting chat found, updating...,', action.payload);
        state.chats[chatIndex] = action.payload;
      } else {
        console.log('setting chat NOT found, adding...,', action.payload);

        state.chats.push(action.payload);
      }
    },
    setChats(state, action: PayloadAction<IConversation[]>) {
      state.chats = action.payload;
    },
    resetConversationsOfContactFetchedTypes(state) {
      state.conversationsOfContactFetchedTypes = {
        active: false,
        finished: false,
      };
    },
    resetConversationsOfContact(state) {
      state.conversationsOfContact = [];
    },
    setSelectedContactInfo(state, action: PayloadAction<IContact>) {
      state.contactInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConversations.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(
        getConversations.fulfilled,
        (state, action: PayloadAction<IConversation[]>) => {
          state.status.fetch = 'succeeded';

          dbWorker.postMessage({
            action: 'updateConversations',
            data: action.payload,
          });
        }
      )
      .addCase(getConversations.rejected, (state, action) => {
        console.error(action.error);
        state.status.fetch = 'rejected';
      })
      .addCase(pinConversation.pending, (state) => {
        state.status.pin = 'loading';
      })
      .addCase(
        pinConversation.fulfilled,
        (state, action: PayloadAction<IConversation>) => {
          state.status.pin = 'succeeded';
        }
      )
      .addCase(inConversation.pending, (state) => {
        state.status.in = 'loading';
      })
      .addCase(
        inConversation.fulfilled,
        (state, action: PayloadAction<IConversation>) => {
          state.status.in = 'succeeded';
        }
      )
      .addCase(outConversation.pending, (state) => {
        state.status.out = 'loading';
      })
      .addCase(
        outConversation.fulfilled,
        (state, action: PayloadAction<IConversation>) => {
          state.status.out = 'succeeded';
          // const conversationIndex = state.conversations.findIndex(
          //   (conversation) => conversation.id === action.payload.id
          // );
          // state.conversations[conversationIndex] = action.payload;
        }
      )
      .addCase(transferConversation.pending, (state) => {
        state.status.transfer = 'loading';
      })
      .addCase(
        transferConversation.fulfilled,
        (state, action: PayloadAction<IConversation>) => {
          state.status.transfer = 'succeeded';
          console.log('transferConversation succeeded: ', action.payload);
        }
      )
      .addCase(modifyConversationLabel.pending, (state) => {
        state.status.modify = 'loading';
      })
      .addCase(
        modifyConversationLabel.fulfilled,
        (state, action: PayloadAction<IConversation>) => {
          state.status.modify = 'succeeded';
          console.log('modifyConversationLabel succeeded: ', action.payload);
          // const conversationIndex = state.conversations.findIndex(
          //   (conversation) => conversation.id === action.payload.id
          // );
          // state.conversations[conversationIndex] = action.payload;
        }
      )
      .addCase(finalizeConversation.pending, (state) => {
        state.status.finalize = 'loading';
      })
      .addCase(
        finalizeConversation.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.status.finalize = 'succeeded';
        }
      )
      .addCase(unarchiveChat.pending, (state) => {
        state.status.unarchiveChat = 'loading';
      })
      .addCase(unarchiveChat.fulfilled, (state, action) => {
        state.status.unarchiveChat = 'succeeded';

        const updatedChat = action.payload;
        const chatIndex = state.chats.findIndex(
          (chat) => chat.id === updatedChat.id
        );

        if (chatIndex !== -1) {
          state.chats.splice(chatIndex, 1);
        }
      })
      .addCase(archiveChat.pending, (state) => {
        state.status.archiveChat = 'loading';
      })
      .addCase(archiveChat.fulfilled, (state, action) => {
        state.status.archiveChat = 'succeeded';

        const updatedChat = action.payload;
        const chatIndex = state.chats.findIndex(
          (chat) => chat.id === updatedChat.id
        );

        if (chatIndex !== -1) {
          state.chats.splice(chatIndex, 1);
        }
      })
      .addCase(fetchGroups.fulfilled, (state, action: PayloadAction<IGroup[]>) => {
        state.status.groups = 'succeeded';
        console.log('fetchGroups succeeded: ', action.payload);
        state.groups = action.payload;
      })
      .addCase(fetchChannels.pending, (state) => {
        state.status.channels = 'loading';
      })
      .addCase(fetchChannels.rejected, (state, action) => {
        state.status.channels = 'rejected';
        console.log('fetchChannels rejected: ', action.payload);
      })
      .addCase(fetchChannels.fulfilled, (state, action: PayloadAction<IGroup[]>) => {
        state.status.channels = 'succeeded';
        console.log('fetchChannels succeeded: ', action.payload);
        // @ts-ignore
        state.channels = action.payload;
      })
      .addCase(fetchOpenInFooter.pending, (state) => {
        state.status.openInFooter = 'loading';
      })
      .addCase(fetchOpenInFooter.rejected, (state, action) => {
        state.status.openInFooter = 'rejected';
        console.log('fetchOpenInFooter rejected: ', action.payload);
      })
      .addCase(
        fetchOpenInFooter.fulfilled,
        (state, action: PayloadAction<IGroup[]>) => {
          state.status.openInFooter = 'succeeded';
          console.log('fetchOpenInFooter succeeded: ', action.payload);
        }
      )
      .addCase(createConversation.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createConversation.rejected, (state, action) => {
        state.status.create = 'rejected';
        console.log('createConversation rejected: ', action.payload);
      })
      .addCase(
        createConversation.fulfilled,
        (state, action: PayloadAction<IConversation>) => {
          state.status.create = 'succeeded';
          console.log('createConversation succeeded: ', action.payload);
        }
      )
      .addCase(getContactInfo.pending, (state) => {
        state.contactInfo = null;
        state.status.contactInfo = 'loading';
      })
      .addCase(getContactInfo.rejected, (state, action) => {
        state.status.contactInfo = 'rejected';
        state.contactInfo = null;
        console.log('getContactInfo rejected: ', action.payload);
      })
      .addCase(
        getContactInfo.fulfilled,
        (state, action: PayloadAction<IContact>) => {
          state.status.contactInfo = 'succeeded';
          state.contactInfo = action.payload;
          console.log('getContactInfo succeeded: ', action.payload);
        }
      )
      .addCase(fetchNewChat.pending, (state) => {
        state.status.chat = 'loading';
      })
      .addCase(fetchNewChat.rejected, (state, action) => {
        state.status.chat = 'rejected';
        state.chat = null;
        console.log('fetchNewChat rejected: ', action.payload);
      })
      .addCase(
        fetchNewChat.fulfilled,
        (state, action: PayloadAction<IConversation>) => {
          state.status.chat = 'succeeded';
          state.chat = action.payload;
          console.log('fetchNewChat succeeded: ', action.payload);
        }
      )
      .addCase(fetchChats.pending, (state) => {
        state.status.chats = 'loading';
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.status.chats = 'rejected';
        console.log('fetchChats rejected: ', action.payload);
      })
      .addCase(
        fetchChats.fulfilled,
        (state, action: PayloadAction<IConversation[]>) => {
          const newChats = action.payload;
          state.status.chats = 'succeeded';
          state.chats = newChats;

          console.log('fetchChats succeeded: ', action.payload);
        }
      )
      .addCase(fetchUpdateChat.pending, (state) => {
        state.status.chat = 'loading';
      })
      .addCase(fetchUpdateChat.rejected, (state, action) => {
        state.status.chat = 'rejected';
        console.log('fetchUpdateChat rejected: ', action.payload);
      })
      .addCase(fetchUpdateChat.fulfilled, (state) => {
        state.status.chat = 'succeeded';
      })
      .addCase(fetchConversationsOfContact.pending, (state) => {
        state.status.conversationsOfContact = 'loading';
      })
      .addCase(fetchConversationsOfContact.rejected, (state, action) => {
        state.status.conversationsOfContact = 'rejected';
      })
      .addCase(fetchConversationsOfContact.fulfilled, (state, action) => {
        const { status, response } = action.payload;

        state.status.conversationsOfContact = 'succeeded';
        state.conversationsOfContactFetchedTypes[status] = true;

        if (
          state.conversationsOfContactFetchedTypes.active === true ||
          state.conversationsOfContactFetchedTypes.finished === true
        ) {
          // Add to the state without replacing it if there is already data of the opossite type
          if (status === 'active') {
            state.conversationsOfContact = [
              ...state.conversationsOfContact,
              ...response,
            ];
          } else if (
            status === 'finished' &&
            state.conversationsOfContactFetchedTypes.active
          ) {
            state.conversationsOfContact = [
              ...state.conversationsOfContact,
              ...response,
            ];
          }
        } else {
          state.conversationsOfContact = response;
        }
      });
  },
});

export const {
  setConversationSelected,
  setChat,
  setChats,
  resetConversationsOfContactFetchedTypes,
  resetConversationsOfContact,
  setSelectedContactInfo,
  setLoadingConversation,
  setCreateConversationFromCreateButton,
} = conversationsSlice.actions;

const conversationsState = (state: RootState) => state.Conversations;
export const selectConversationSelected = createSelector(
  conversationsState,
  (conversations) => conversations.conversationSelected
);
export const selectConversationsFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.fetch
);
export const selectLoadingConversation = createSelector(
  conversationsState,
  (conversations) => conversations.loadingConversation
);
export const selectCreatingConversationFromCreateButton = createSelector(
  conversationsState,
  (conversations) => conversations.creatingConversationFromCreateButton
);
export const selectCreteConversationStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.create
);
export const selectConversationsPinStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.pin
);
export const selectConversationsInStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.in
);
export const selectConversationsOutStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.out
);
export const selectConversationsTransferStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.transfer
);
export const selectConversationsModifyStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.modify
);
export const selectConversationsFinalizeStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.finalize
);
export const selectGroups = createSelector(
  conversationsState,
  (conversations) => conversations.groups
);
export const selectGroupsFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.groups
);
export const selectChannels = createSelector(
  conversationsState,
  (conversations) => conversations.channels
);
export const selectChannelsFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.channels
);
export const selectOpenInFooterFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.openInFooter
);
export const selectContactInfo = createSelector(
  conversationsState,
  (conversations) => conversations.contactInfo
);
export const selectContactInfoFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.contactInfo
);
export const selectNewInternalChat = createSelector(
  conversationsState,
  (conversations) => conversations.chat
);
export const selectNewInternalChatFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.chat
);
export const selectChats = createSelector(
  conversationsState,
  (conversations) => conversations.chats
);
export const selectChatsFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.chats
);
export const selectUpdateChatFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.updateChat
);
export const selectChatUnarchiveStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.unarchiveChat
);
export const selectChatArchiveStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.archiveChat
);
export const selectConversationsOfContact = createSelector(
  conversationsState,
  (conversations) => conversations.conversationsOfContact
);
export const selectConversationsOfContactFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.conversationsOfContact
);

export default conversationsSlice.reducer;
