// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, TextField, Typography } from '@mui/material';
// Redux
import {
  selectEmailWriterSubject,
  setWriterSubject,
} from 'redux/features/emailSlice/emailSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';

const Subject = () => {
  const dispatch = useAppDispatch();
  const subject = useSelector(selectEmailWriterSubject);

  const { t } = useTranslation();

  const handleSubjectChange = (value: string) => {
    dispatch(setWriterSubject(value));
  };

  return (
    <Box height="3rem" width="100%" display="flex" alignItems="center" gap={1}>
      <Typography
        variant="body2"
        fontSize=".8rem"
        width="4rem"
        color={(theme) => theme.palette.text.primary}
      >
        {t('conversations.message.email.subject')}
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          value={subject}
          onChange={(e) => handleSubjectChange(e.target.value)}
          size="small"
        />
      </Box>
    </Box>
  );
};

export default Subject;
