import {
  IQuickResponse,
  IQuickResponseCategory,
} from '@trii/types/dist/Conversations';
import axios from 'axios';
import getRequestConfig from 'redux/functions/getRequestConfig';
import { CreateCategory } from './types/CreateCategory';
import { CreateQuickResponse } from './types/CreateQuickResponse';

// Quick Responses
const fetchQuickResponses = async (jwt: string, URL: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(`${URL}/QuickResponses/replies`, requestConfig);

  return response.data;
};

const replaceVariables = async (jwt: string, URL: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get<string>(URL, requestConfig);

  return response.data;
};

const getVariableValue = async (jwt: string, URL: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get<string>(URL, requestConfig);

  return response.data;
};

const fetchQuickResponseById = async (jwt: string, URL: string, id: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(
    `${URL}/QuickResponses/replies/${id}`,
    requestConfig
  );

  return response.data;
};

const fetchCreateQuickResponse = async (
  jwt: string,
  URL: string,
  data: CreateQuickResponse
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const response = await axios.post(
    `${URL}/QuickResponses/replies`,
    data,
    requestConfig
  );

  return response.data;
};

const fetchUpdateQuickResponse = async (
  jwt: string,
  URL: string,
  data: IQuickResponse
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const response = await axios.patch(
    `${URL}/QuickResponses/replies/${data.id}`,
    data,
    requestConfig
  );

  return response.data;
};

const fetchDeleteQuickResponse = async (jwt: string, URL: string, id: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.delete(
    `${URL}/QuickResponses/replies/${id}`,
    requestConfig
  );

  return response.data;
};

// Categories

const fetchCategories = async (jwt: string, URL: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(
    `${URL}/QuickResponses/categories`,
    requestConfig
  );

  return response.data;
};

const fetchCategoryById = async (jwt: string, URL: string, id: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(
    `${URL}/QuickResponses/categories/${id}`,
    requestConfig
  );

  return response.data;
};

const fetchCreateCategory = async (
  jwt: string,
  URL: string,
  data: CreateCategory
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const response = await axios.post(
    `${URL}/QuickResponses/categories`,
    data,
    requestConfig
  );

  return response.data;
};

const fetchUpdateCategory = async (
  jwt: string,
  URL: string,
  data: IQuickResponseCategory
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const response = await axios.patch(
    `${URL}/QuickResponses/categories/${data.id}`,
    data,
    requestConfig
  );

  return response.data;
};

const fetchDeleteCategory = async (jwt: string, URL: string, id: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.delete(
    `${URL}/QuickResponses/categories/${id}`,
    requestConfig
  );

  return response.data;
};

export default {
  fetchQuickResponses,
  fetchQuickResponseById,
  fetchCreateQuickResponse,
  fetchUpdateQuickResponse,
  fetchDeleteQuickResponse,
  replaceVariables,
  getVariableValue,
  // Categories
  fetchCategories,
  fetchCategoryById,
  fetchCreateCategory,
  fetchUpdateCategory,
  fetchDeleteCategory,
};
