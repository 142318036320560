import { useEffect, useState } from 'react';
import useSAS from 'hooks/useSAS';

const useSASAvatarURL = (imageUrl: string) => {
  const [imageURLWithAccess, setImageURLWithAccess] = useState('');
  const URLHandler = useSAS();

  useEffect(() => {
    if (imageUrl) {
      URLHandler.getURLWithAccessToken(imageUrl).then((url) => {
        setImageURLWithAccess(url);
      });
    } else {
      setImageURLWithAccess('');
    }
  }, [imageUrl, URLHandler]);

  return imageURLWithAccess;
};

export default useSASAvatarURL;
