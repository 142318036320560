import React, { useEffect, useRef } from 'react';
// components/ui
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Typography, Box, Divider, IconButton } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

interface ConversationSectionProps {
  title: string;
  children: React.ReactNode;
  isExpanded?: boolean;
  fetchFinishedConversationsOfContact?: () => void;
}

const ConversationSection = ({
  title,
  children,
  fetchFinishedConversationsOfContact,
  isExpanded = false,
}: ConversationSectionProps) => {
  const [expanded, setExpanded] = React.useState(false);

  const hasExpandedOnceRef = useRef(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (expanded && !hasExpandedOnceRef.current) {
      hasExpandedOnceRef.current = true;

      fetchFinishedConversationsOfContact();
    }
  }, [expanded]);

  return (
    <Box marginBottom={2.5}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          transition: 'height 0.5s ease-in-out',
        }}
      >
        <Box display={'flex'} alignItems={'center'} gap={0.5}>
          <QuestionAnswerOutlinedIcon sx={{ fontSize: 16, color: 'text.primary' }} />
          <Typography
            sx={{
              fontWeight: 600,
              mb: 0.5,
              fontSize: 13,
              color: 'text.primary',
              userSelect: 'none',
            }}
          >
            {title}
          </Typography>
        </Box>
        {isExpanded && (
          <IconButton
            onClick={handleExpand}
            sx={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.5s ease-in-out',
            }}
          >
            <ArrowDropDown
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            />
          </IconButton>
        )}
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box
        sx={{
          display: isExpanded ? (expanded ? 'flex' : 'none') : 'flex',
          width: '100%',
          gap: 0.5,
          flexDirection: 'column',
          px: 0.7,
          overflow: 'hidden',
          animation: 'fadeIn 0.5s ease-in-out',
          '@keyframes fadeIn': {
            '0%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            },
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ConversationSection;
