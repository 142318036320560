import React, { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
import useCreateRCS from '../hooks/useCreateRCS/useCreateRCS';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  deleteRCS,
  changeRCSCreateStatus,
} from 'redux/features/RCSSlice/RCSSlice';
// Types
import type { CrudPanelState, IRCSContext } from './types';
import { UseFieldType } from 'hooks/useField';
import createRCSInitialState from '../hooks/useCreateRCS/createRCSInitialState';

export const RCSContext = createContext<IRCSContext>({
  crudPanelState: 'closed',
  endCreating: () => {},
  openCreate: () => {},
  handleCreateRCS: () => {},
  openDelete: () => {},
  endDeleting: () => {},
  selectedRCS: null,
  deleteRCSName: fieldInitialDef,
  createRCSState: createRCSInitialState,
  handleDeleteRCS: () => {},
  navigateToEdit: () => {},
});

const RCSProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedRCS, setSelectedRCS] = useState<any>(null);
  // Create state
  const createRCSState = useCreateRCS();
  // Delete state:
  const deleteRCSName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreateRCS = async () => {
    await createRCSState.actions.sendRCS();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createRCSState.actions.resetAll();
    dispatch(changeRCSCreateStatus('idle'));
    closeCrudPanel();
  };
  // end Create functions

  // Delete functions
  const handleDeleteRCS = async () => {
    const { id } = selectedRCS;

    await dispatch(deleteRCS(id));
    endDeleting();
  };

  const openDelete = (RCS: any) => {
    setSelectedRCS(RCS);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteRCSName.actions.resetValue();
    setSelectedRCS(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (RCSId: string) => {
    navigate(`/a/conversations/system/RCS/${RCSId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <RCSContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreateRCS,
        openDelete,
        endDeleting,
        deleteRCSName,
        createRCSState,
        selectedRCS,
        handleDeleteRCS,
        navigateToEdit,
      }}
    >
      {children}
    </RCSContext.Provider>
  );
};

export default RCSProvider;
