import React, { useContext, useMemo, useState } from 'react';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
// Components/ui
import { Box } from '@mui/material';
import { ConversationCard } from './components';
import { ContactActions } from './components/ConversationCard/components/Body/components';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';

interface Props {
  conversation: IConversation;
}

const ConversationItem = ({ conversation }: Props) => {
  const { openConversation, conversationSelectedIdRef } =
    useContext(conversationsContext);
  const {
    endFileSelectorMode,
    setIsSearching,
    setIsReplying,
    setMessage,
    setShowActions,
  } = useContext(messagesContext);

  const [contextMenuAnchor, setContextMenuAnchor] = useState<HTMLElement | null>(
    null
  );
  const [menuPosition, setMenuPosition] = useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>({ mouseX: null, mouseY: null });

  const handleContextMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    // Close the previous menu if it's already open
    if (contextMenuAnchor) {
      handleCloseContextMenu();
      return;
    }
    // Set new coordinates for the context menu
    setContextMenuAnchor(event.currentTarget);
    setMenuPosition({ mouseX: event.clientX, mouseY: event.clientY });
  };

  const handleCloseContextMenu = () => {
    setContextMenuAnchor(null);
    setMenuPosition({ mouseX: null, mouseY: null });
  };

  function handleClickConversation(
    conversation: IConversation,
    handleCloseContextMenu: () => void,
    incognito: boolean
  ) {
    if (conversationSelectedIdRef.current !== conversation.id) {
      endFileSelectorMode();
    }

    handleCloseContextMenu();
    openConversation(conversation, incognito);
    setShowActions(false);
    setIsSearching(false);
    setIsReplying(false);
    setMessage(null);
  }

  const memoizedItem = useMemo(
    () => (
      <Box
        onClick={() =>
          handleClickConversation(conversation, handleCloseContextMenu, false)
        }
        onContextMenu={handleContextMenu}
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <ConversationCard conversation={conversation} />
        <ContactActions
          handleClickConversation={() =>
            handleClickConversation(conversation, handleCloseContextMenu, true)
          }
          conversationId={conversation.id}
          anchorEl={contextMenuAnchor}
          onClose={handleCloseContextMenu}
          menuPosition={menuPosition}
        />
      </Box>
    ),
    [conversation, contextMenuAnchor, menuPosition]
  );

  return memoizedItem;
};
export default ConversationItem;
