import moment from 'moment';
//Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Chip, Divider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
// Types
import { IMessage, MessageInfo } from '@trii/types/dist/Common/Messages';
// Redux
import { useSelector } from 'react-redux';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { useEffect, useState } from 'react';

const InfoType = ({ data, message }: { data: MessageInfo; message: IMessage }) => {
  const { t } = useTranslation();

  const { timestamp } = message;

  const dateHour = moment(timestamp).format('HH:mm');

  const conversationSelected = useSelector(selectConversationSelected);
  const dataMessageInfo = data.data;

  const [label, setLabel] = useState<string>('');

  const getLabel = () => {
    if (['100', '101', '102', '103', '104', '105', '106'].includes(data.code)) {
      return `${dateHour} - ${t(`conversationCode.${data.code}`, dataMessageInfo)}`;
    }
  };

  useEffect(() => {
    setLabel(getLabel());
  }, []);

  return (
    <Box>
      <Divider>
        <Chip
          label={label}
          size="small"
          sx={{
            color: 'primary.contrastText',
            backgroundColor: '#92a4ad',
            border: '1px solid',
            borderColor:
              message.conversationId === conversationSelected?.id
                ? //@ts-ignore
                  (theme) => theme.palette.warning.light
                : 'transparent',
          }}
          icon={<InfoIcon style={{ color: 'primary.contrastText' }} />}
        />
      </Divider>
    </Box>
  );
};

export default InfoType;
