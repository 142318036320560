import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  fetchRCSEdit,
  selectRCSEditFetchStatus,
  selectRCSEdit,
  selectRCSEditUpdateStatus,
  updateRCSEdit,
  selectRCSEditName,
} from 'redux/features/RCSEditSlice/RCSEditSlice';
import { fetchGroup, selectGroupStatus } from 'redux/features/groupSlice/groupSlice';
import {
  fetchSchedules,
  selectScheduleStatus,
} from 'redux/features/scheduleSlice/scheduleSlice';
// Components/ui
import { ViewContainer, ChannelEditHeader } from 'components';
import { Body } from './components';
import { Box, Divider } from '@mui/material';
import MmsIcon from '@mui/icons-material/Mms';

const RCSEditContainer = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const groupFetchStatus = useSelector(selectGroupStatus);
  const scheduleFetchStatus = useSelector(selectScheduleStatus);
  const RCSFetchStatus = useSelector(selectRCSEditFetchStatus);
  const RCSUpdateStatus = useSelector(selectRCSEditUpdateStatus);
  const RCSName = useSelector(selectRCSEditName);
  const allFetched =
    groupFetchStatus === 'succeeded' &&
    scheduleFetchStatus === 'succeeded' &&
    RCSFetchStatus === 'succeeded';
  const RCS = useSelector(selectRCSEdit);

  useEffect(() => {
    dispatch(fetchRCSEdit(id));
    dispatch(fetchGroup());
    dispatch(fetchSchedules());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("RCS", RCS)

  return (
    <ViewContainer>
      <Box >
        <ChannelEditHeader
          channelUpdateStatus={RCSUpdateStatus}
          channelName={RCSName}
          channelFetchStatus={RCSFetchStatus}
          updateChannel={updateRCSEdit}
        >
          <MmsIcon
            sx={{
              fontSize: 20,
              color: 'primary.main',
            }}
          />
        </ChannelEditHeader>
        <Divider />
        <Body data={RCS} allFetched={allFetched} />
      </Box>
    </ViewContainer>
  );
};

export default RCSEditContainer;
