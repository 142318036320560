import { useState, useContext } from 'react';
// Components/ui
import { Box } from '@mui/material';
// Components
import { Body, Header } from './components';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import EmailMessageProvider from './context/EmailMessageProvider';
import { containerStyles, overlayStyles } from './styles/emailWriterStyles';
// Styles

interface EmailWriterProps {
  handleClose: () => void;
}

const EmailWriter = ({ handleClose }: EmailWriterProps) => {
  const { openEmailModal } = useContext(messagesContext);
  const [isMaximized, setIsMaximized] = useState(false);

  return (
    <EmailMessageProvider>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        {isMaximized && (
          <Box sx={overlayStyles} onClick={() => setIsMaximized(false)} />
        )}
        <Box sx={containerStyles(isMaximized)}>
          <Header
            handleClose={handleClose}
            isMaximized={isMaximized}
            setIsMaximized={setIsMaximized}
          />
          <Box height="calc(100% - 2rem)" width="100%" padding={1} overflow="auto">
            <Body openModal={openEmailModal} />
          </Box>
        </Box>
      </Box>
    </EmailMessageProvider>
  );
};

export default EmailWriter;
