import { useState, useEffect, useCallback } from 'react';

type ConversationOrderState = {
  orderingByLastMessageDate: boolean;
  orderingByStartConversationDate: boolean;
  isLastMessageAscending: boolean;
  isStartConversationAscending: boolean;
};

type UseConversationOrderReturn = {
  state: ConversationOrderState;
  handleOrderByLastMessage: () => void;
  handleOrderByStartConversation: () => void;
};

export const useConversationOrder = (): UseConversationOrderReturn => {
  const [orderingByLastMessageDate, setOrderingByLastMessageDate] =
    useState<boolean>(true);
  const [orderingByStartConversationDate, setOrderingByStartConversationDate] =
    useState<boolean>(false);
  const [isLastMessageAscending, setIsLastMessageAscending] =
    useState<boolean>(false);
  const [isStartConversationAscending, setIsStartConversationAscending] =
    useState<boolean>(false);

  // Load initial state from localStorage
  useEffect(() => {
    const storedIsLastMessageAscending = localStorage.getItem(
      'isLastMessageAscending'
    );
    const storedIsStartConversationAscending = localStorage.getItem(
      'isStartConversationAscending'
    );
    const storedOrderingByLastMessageDate = localStorage.getItem(
      'orderingByLastMessageDate'
    );
    const storedOrderingByStartConversationDate = localStorage.getItem(
      'orderingByStartConversationDate'
    );

    if (storedIsLastMessageAscending) {
      setIsLastMessageAscending(JSON.parse(storedIsLastMessageAscending));
    }
    if (storedIsStartConversationAscending) {
      setIsStartConversationAscending(
        JSON.parse(storedIsStartConversationAscending)
      );
    }
    if (storedOrderingByLastMessageDate) {
      setOrderingByLastMessageDate(storedOrderingByLastMessageDate === 'true');
    }
    if (storedOrderingByStartConversationDate) {
      setOrderingByStartConversationDate(
        storedOrderingByStartConversationDate === 'true'
      );
    }
  }, []);

  const handleOrderByLastMessage = useCallback(() => {
    setIsStartConversationAscending(false);
    setOrderingByLastMessageDate(true);
    setOrderingByStartConversationDate(false);

    if (orderingByLastMessageDate) {
      const newValue = !isLastMessageAscending;
      setIsLastMessageAscending(newValue);
      localStorage.setItem('isLastMessageAscending', JSON.stringify(newValue));
    }

    localStorage.setItem('isStartConversationAscending', JSON.stringify(false));
    localStorage.setItem('orderingByLastMessageDate', JSON.stringify(true));
    localStorage.setItem('orderingByStartConversationDate', JSON.stringify(false));
  }, [orderingByLastMessageDate, isLastMessageAscending]);

  const handleOrderByStartConversation = useCallback(() => {
    setOrderingByLastMessageDate(false);
    setOrderingByStartConversationDate(true);
    setIsLastMessageAscending(false);

    if (orderingByStartConversationDate) {
      const newValue = !isStartConversationAscending;
      setIsStartConversationAscending(newValue);
      localStorage.setItem('isStartConversationAscending', JSON.stringify(newValue));
    }

    localStorage.setItem('isLastMessageAscending', JSON.stringify(false));
    localStorage.setItem('orderingByLastMessageDate', JSON.stringify(false));
    localStorage.setItem('orderingByStartConversationDate', JSON.stringify(true));
  }, [orderingByStartConversationDate, isStartConversationAscending]);

  const state: ConversationOrderState = {
    orderingByLastMessageDate,
    orderingByStartConversationDate,
    isLastMessageAscending,
    isStartConversationAscending,
  };

  return {
    state,
    handleOrderByLastMessage,
    handleOrderByStartConversation,
  };
};
