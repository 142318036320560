import { useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  CircularProgress,
  Fab,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
// Icons
import { Clear, FileUpload, Replay } from '@mui/icons-material';

interface LoaderProps {
  handleTryAgain: () => void;
  isLoading: boolean;
  messageId: string;
  // isLoaded: boolean;
}

const LoaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1,
}));

const InnerBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

const RetryButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(0,0,0,0.6)',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  borderRadius: '10rem',
}));

const RetryText = styled(Typography)(({ theme }) => ({
  color: 'white',
  marginLeft: '0.5rem',
  fontWeight: 'bold',
  lineHeight: '0',
}));

const ActionFab = styled(Fab)(({ theme }) => ({
  width: 35,
  height: 35,
  bgcolor: 'rgba(0, 0, 0, 0.5)',
  '&:hover': {
    bgcolor: 'rgba(0, 0, 0, 0.6)',
  },
  position: 'absolute',
  zIndex: 2,
}));

const Loader = ({
  handleTryAgain,
  isLoading,
}: // isLoaded,
LoaderProps) => {
  const [cancel, setCancel] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleMouseOver = () => {
    setCancel(true);
  };

  const handleMouseOut = () => {
    setCancel(false);
  };

  return (
    <LoaderContainer>
      <InnerBox>
        {!isLoading ? (
          <RetryButton onClick={() => handleTryAgain()}>
            <Replay sx={{ color: 'white' }} />
            <RetryText variant="caption">
              {t('conversations.message.tryAgain')}
            </RetryText>
          </RetryButton>
        ) : (
          <>
            <ActionFab
              aria-label="action"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              {isLoading && cancel ? (
                <IconButton>
                  <Clear sx={{ color: 'white' }} />
                </IconButton>
              ) : (
                <IconButton>
                  <FileUpload sx={{ color: 'white' }} />
                </IconButton>
              )}
            </ActionFab>
            <CircularProgress
              size={45}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
              }}
            />
          </>
        )}
      </InnerBox>
    </LoaderContainer>
  );
};

export default Loader;
