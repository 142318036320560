// Components/ui
import { Box, useTheme } from '@mui/material';
//Components
import { ConversationActions, ConversationInfo } from './components';

const Header = ({ disabled }: { disabled: boolean }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor={(theme) => theme.palette.background.default}
      color={(theme) => theme.palette.text.primary}
      sx={{
        maxHeight: '3rem',
        height: '100%',
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
      }}
      px={1}
      borderBottom={`1px solid ${theme.palette.divider}`}
      position="relative"
    >
      {disabled ? <div /> : <ConversationInfo />}
      <ConversationActions />
    </Box>
  );
};

export default Header;
