import { Box, Card, CardActionArea, Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ButtonReports from './components/ButtonReports';
import DetailsChannels from './IconsListReports/DetailsChannels/DetailsChannels';
import ConversationsLogs from './IconsListReports/ConversationsLogs/ConversationsLogs';
import ConversationTrends from './IconsListReports/ConversationTrends/ConversationTrends';
import Summary from './IconsListReports/Summary/Summary';
import TimeDistribution from './IconsListReports/TimeDistribution/TimeDistribution';
import DetailsUserGroup from './IconsListReports/DetailsUserGroup/DetailsUserGroup';
import UserPerformance from './IconsListReports/UserPerformance/UserPerformance';
import ResponseTimes from './IconsListReports/ResponseTimes/ResponseTimes';
import Finals from './IconsListReports/Finals/Finals';
import FlowStatisctic from './IconsListReports/FlowStatisctic/FlowStatisctic';
import SatisfactionSurvey from './IconsListReports/SatisfactionSurvey/SatisfactionSurvey';
import MessagesLogs from './IconsListReports/MessagesLogs/MessagesLogs';
const MyReports = () => {
  const { t } = useTranslation();
  return (
    <Box className={'conversations-fadeinup'}>
      {/* ANALISIS */}
      <Box
        style={{
          display: 'flex',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {t('myReports.analysis')}
        </Typography>
      </Box>

      <Box display={'flex'} margin={2} sx={{ flexWrap: 'wrap', gap: '1rem 3rem' }}>
        <MessagesLogs />
        <ConversationsLogs />
        <ConversationTrends />
        <TimeDistribution />
      </Box>
      {/* PRODUCTIVIDAD  */}
      <Box
        style={{
          display: 'flex',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {t('myReports.productivity')}
        </Typography>
      </Box>

      <Box display={'flex'} margin={2} sx={{ flexWrap: 'wrap', gap: '1rem 3rem' }}>
        <UserPerformance></UserPerformance>
        <ResponseTimes></ResponseTimes>
        <DetailsUserGroup></DetailsUserGroup>
        <Finals></Finals>
      </Box>
      {/* USO DE CANALES  */}

      <Box
        style={{
          display: 'flex',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {t('myReports.useOfChannels')}
        </Typography>
      </Box>
      <Box display={'flex'} margin={2} sx={{ flexWrap: 'wrap', gap: '1rem 3rem' }}>
        <Summary></Summary>
        <DetailsChannels></DetailsChannels>
      </Box>
      {/* USO DE FLOWS  */}

      <Box
        style={{
          display: 'flex',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {t('myReports.useOfFlows')}
        </Typography>
      </Box>
      <Box display={'flex'} margin={2} sx={{ flexWrap: 'wrap', gap: '1rem 3rem' }}>
        <FlowStatisctic></FlowStatisctic>
      </Box>
      {/* SATISFACCION DEL CLIENTE */}
      <Box
        style={{
          display: 'flex',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {t('myReports.customerSatisfaction')}
        </Typography>
      </Box>
      <Box display={'flex'} margin={2} sx={{ flexWrap: 'wrap', gap: '1rem 3rem' }}>
        <SatisfactionSurvey></SatisfactionSurvey>
      </Box>
      {/* <Divider sx={{ marginTop: "30px" }} /> */}
    </Box>
  );
};

export default MyReports;
