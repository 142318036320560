import { useContext, useEffect } from 'react';
// Context
import { EmailMessageContext } from './EmailMessageContext';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Types
import type { EmailMessageContextType } from './EmailMessageContext';
// Redux
import { setWriterSubject } from 'redux/features/emailSlice/emailSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

const EmailMessageProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();

  const {
    subjectDraft,
    bodyDraft,
    sendEmail,
    emailConversationHasDraft,
    body,
    setBody,
  } = useContext(messagesContext);

  const setSubject = (value: string) => {
    dispatch(setWriterSubject(value));
  };

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setWriterSubject(e.target.value));
  };

  const sendEmailAndReset = (
    conversationId: string,
    newBody: string,
    subject: string
  ) => {
    sendEmail(conversationId, newBody, subject);
    setSubject('');
  };

  useEffect(() => {
    if (subjectDraft) {
      setSubject(subjectDraft);
    }
  }, [subjectDraft, emailConversationHasDraft]);

  useEffect(() => {
    if (emailConversationHasDraft) {
      setBody(bodyDraft);
    }
  }, [bodyDraft, emailConversationHasDraft]);

  const value: EmailMessageContextType = {
    handleSubjectChange,
    sendEmailAndReset,
    body,
    setBody,
  };

  return (
    <EmailMessageContext.Provider value={value}>
      {children}
    </EmailMessageContext.Provider>
  );
};

export default EmailMessageProvider;
