import React, { useContext } from 'react';
// Components/ui
import { CreatePanel, DeletePanel } from './components/index.js';
import { RCSContext } from '../../context/RCSProvider';

function CrudPanel() {
  const { crudPanelState } = useContext(RCSContext);

  return (
    <>
      {crudPanelState === 'create' && <CreatePanel />}
      {crudPanelState === 'delete' && <DeletePanel />}
    </>
  );
}

export default CrudPanel;
