import { combineReducers } from '@reduxjs/toolkit';
// Reducers
import sessionReducer from './features/sessionSlice/sessionSlice';
import groupReducer from './features/groupSlice/groupSlice';
import poolReducer from './features/poolSlice/poolSlice';
import themeReducer from './features/themeSlice';
import userReducer from './features/userSlice/userSlice';
import webHooksReducer from './features/webHooksSlice/webHooksSlice';
import labelReducer from './features/labelSlice/labelSlice';
import endingReducer from './features/endingSlice/endingSlice';
import flowReducer from './features/flowSlice/flowSlice';
import editFlowSlice from './features/editFlowSlice/editFlowSlice';
import scheduleReducer from './features/scheduleSlice/scheduleSlice';
import emailReducer from './features/emailSlice/emailSlice';
import emailEditReducer from './features/emailEditSlice/emailEditSlice';
import smsReducer from './features/smsSlice/smsSlice';
import smsEditReducer from './features/smsEditSlice/smsEditSlice';
import webChatReducer from './features/webChatSlice/webChatSlice';
import webChatEditReducer from './features/webChatEditSlice/webChatEditSlice';
import formReducer from './features/formSlice/formSlice';
import formEditReducer from './features/formEditSlice/formEditSlice';
import pbxReducer from './features/pbxSlice/pbxSlice';
import whatsAppReducer from './features/whatsAppSlice/whatsAppSlice';
import whatsAppEditReducer from './features/whatsAppEditSlice/whatsAppEditSlice';
import facebookReducer from './features/facebookSlice/facebookSlice';
import facebookEditReducer from './features/facebookEditSlice/facebookEditSlice';
import instagramReducer from './features/instagramSlice/instagramSlice';
import instagramEditReducer from './features/instagramEditSlice/instagramEditSlice';
import mercadoLibreReducer from './features/mercadoLibreSlice/mercadoLibreSlice';
import mercadoLibreEditReducer from './features/mercadoLibreEditSlice/mercadoLibreEditSlice';
import googleBusinessReducer from './features/googleBusinessSlice/googleBusinessSlice';
import googleBusinessEditReducer from './features/googleBusinessEditSlice/googleBusinessEditSlice';
import sipReducer from './features/sipSlice/sipSlice';
import responsesReducer from './features/responsesSlice/responsesSlice';
import notificationsReducer from './features/notificationsSlice/notificationsSlice';
import conversationsReducer from './features/conversationsSlice/conversationsSlice';
import conversationReducer from './features/conversationSlice/conversationSlice';
import socialWallReducer from './features/socialWallSlice/socialWallSlice';
import spaceSlice from './features/spaceSlice/spaceSlice';
import spacesSlice from './features/spacesSlice/spacesSlice';
import contactInfoSlice from './features/contactInfoSlice/contactInfoSlice';
import messagesSlice from './features/messagesSlice/messagesSlice';
import activitiesSlice from './features/activitiesSlice/activitiesSlice';
import quickResponseSlice from './features/quickResponseSlice/quickResponseSlice';
import businessSlice from './features/businessSlice/businessSlice';
import buttonsSlice from './features/buttonsSlice/buttonsSlice';
import reportsSlice from './features/reportsSlice/reportsSlice';
import dashboardSlice from './features/dashboardSlice/dashboardSlice';
import templatesSlice from './features/templatesSlice/templatesSlice';
import googleSyncReducer from './features/googleSyncSlice/googleSyncSlice';
import RCSSlice from './features/RCSSlice/RCSSlice';
import RCSEditSlice from './features/RCSEditSlice/RCSEditSlice';



const rootReducer = combineReducers({
  Messages: messagesSlice,
  ContactInfo: contactInfoSlice,
  BusinessInfo: businessSlice,
  Spaces: spacesSlice,
  Space: spaceSlice,
  Session: sessionReducer,
  Pool: poolReducer,
  Theme: themeReducer,
  User: userReducer,
  WebHooks: webHooksReducer,
  Group: groupReducer,
  Ending: endingReducer,
  Flow: flowReducer,
  EditFlow: editFlowSlice,
  Schedule: scheduleReducer,
  Label: labelReducer,
  Email: emailReducer,
  EmailEdit: emailEditReducer,
  Sms: smsReducer,
  SmsEdit: smsEditReducer,
  WebChat: webChatReducer,
  WebChatEdit: webChatEditReducer,
  Form: formReducer,
  FormEdit: formEditReducer,
  Pbx: pbxReducer,
  WhatsApp: whatsAppReducer,
  WhatsAppEdit: whatsAppEditReducer,
  Facebook: facebookReducer,
  FacebookEdit: facebookEditReducer,
  Instagram: instagramReducer,
  InstagramEdit: instagramEditReducer,
  MercadoLibre: mercadoLibreReducer,
  MercadoLibreEdit: mercadoLibreEditReducer,
  GoogleBusiness: googleBusinessReducer,
  GoogleBusinessEdit: googleBusinessEditReducer,
  GoogleSync: googleSyncReducer,
  Sip: sipReducer,
  Response: responsesReducer,
  Notifications: notificationsReducer,
  Conversations: conversationsReducer,
  Conversation: conversationReducer,
  SocialWall: socialWallReducer,
  Activities: activitiesSlice,
  QuickResponse: quickResponseSlice,
  Buttons: buttonsSlice,
  Reports: reportsSlice,
  Dashboard: dashboardSlice,
  Templates: templatesSlice,
  RCS: RCSSlice,
  RCSEdit: RCSEditSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
