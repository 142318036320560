import { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  selectConversationSelected,
  selectConversationsFetchStatus,
  setConversationSelected,
  setCreateConversationFromCreateButton,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Components/ui
import { Box, CircularProgress, SpeedDial } from '@mui/material';
// Icons
import { ChatBubbleOutline } from '@mui/icons-material';
import { ConversationList, Filter, OrderBy } from './components';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
// DB
import { dbWorker } from 'db/db';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
const ExternalChat = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    expandedExternal,
    setOpenModalList,
    setModalTitle,
    setConversationId,
    conversationSelectedIdRef,
  } = useContext(conversationsContext);
  const { subscribeEvent, socketConnection } = useContext(socketContext);

  const fetchStatus = useSelector(selectConversationsFetchStatus);

  const isLoading = fetchStatus === 'loading';

  const [showSpeedDial, setShowSpeedDial] = useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenModalList(true);
    setModalTitle(t('conversations.createConversation.title'));
    dispatch(setCreateConversationFromCreateButton(true));
  };

  useEffect(() => {
    const handleDocumentKeyDown = (event: KeyboardEvent) => {
      const focusedElement = document.activeElement as HTMLElement;

      if (
        !(
          focusedElement?.tagName === 'INPUT' ||
          focusedElement?.tagName === 'TEXTAREA' ||
          focusedElement?.tagName === 'SELECT' ||
          focusedElement?.isContentEditable
        ) &&
        (event.key === 'N' || event.key === 'n')
      ) {
        event.preventDefault();
        handleOpenModal();
      }
    };

    document.addEventListener('keydown', handleDocumentKeyDown);
    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, []);

  // Subscribe to events
  useEffect(() => {
    if (socketConnection) {
      subscribeEvent('conversation_finalize', async (data: IConversation) => {
        dbWorker.postMessage({
          action: 'setConversation',
          data: data,
        });
      });

      subscribeEvent('conversation_update', async (data) => {
        dbWorker.postMessage({
          action: 'updateConversation',
          data: data,
        });
      });
      subscribeEvent('conversation_new', async (data) => {
        dbWorker.postMessage({
          action: 'setConversation',
          data: data,
        });
      });
    }
  }, [socketConnection]);

  // Show speed dial
  useEffect(() => {
    if (expandedExternal) {
      const time = setTimeout(() => {
        setShowSpeedDial(expandedExternal);
      }, 1500);
      return () => clearTimeout(time);
    } else {
      setShowSpeedDial(false);
    }
  }, [expandedExternal]);

  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        maxHeight: `${!expandedExternal ? '3rem' : `calc(100% - 3rem)`}`,
        height: '100%',
        transition: 'max-height 0.5s ease-in-out',
        position: 'relative',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p={1}
        sx={{
          maxHeight: '3rem',
          height: '100%',
        }}
        alignItems="center"
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Box>
          <Filter />
        </Box>
        {isLoading && <CircularProgress size={20} />}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem',
          }}
        >
          <Box>
            <OrderBy />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: expandedExternal ? '100%' : '0',
        }}
      >
        <ConversationList />
      </Box>
      <SpeedDial
        ariaLabel="SpeedDial Create Chat"
        sx={{
          display: showSpeedDial ? 'flex' : 'none',
          position: 'absolute',
          bottom: '-2rem',
          right: '2rem',
          '-webkit-animation': 'zoomin 0.3s ease-in-out',
          animation: 'zoomin 0.3s ease-in-out',
          '@keyframes zoomin': {
            '0%': {
              transform: 'scale(0)',
            },
            '50%': {
              transform: 'scale(1.1)',
            },
            '100%': {
              transform: 'scale(1)',
            },
          },
        }}
        FabProps={{
          size: 'small',
        }}
        onClick={handleOpenModal}
        icon={<ChatBubbleOutline fontSize="small" />}
      />
    </Box>
  );
};

export default ExternalChat;
