import {
  ChangeEvent,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchTemplates } from 'redux/features/templatesSlice/templatesSlice';
import {
  getMessages,
  setIsReplyingEmail,
  uploadMedia,
} from 'redux/features/messagesSlice/messagesSlice';
import {
  createConversation,
  fetchChannels,
  fetchGroups,
  fetchOpenInFooter,
  fetchNewChat,
  finalizeConversation,
  getContactInfo,
  inConversation,
  modifyConversationLabel,
  outConversation,
  transferConversation,
  fetchChats,
  fetchConversationsOfContact,
  selectConversationSelected,
  setConversationSelected,
  readConversation,
  selectContactInfo,
  setLoadingConversation,
  selectLoadingConversation,
} from 'redux/features/conversationsSlice/conversationsSlice';
import {
  fetchEndings,
  selectEndingsFetchStatus,
} from 'redux/features/endingSlice/endingSlice';
import { fetchLabels } from 'redux/features/labelSlice/labelSlice';
import {
  fetchContactInfo,
  fetchDeleteFile,
  fetchDeleteNote,
  fetchFiles,
  fetchNewFile,
  fetchNewNote,
  fetchNotes,
  fetchUpdateNote,
  resetContacts,
  setIsEditingName,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
import { selectUser } from 'redux/features/userSlice/userSlice';
import { fetchActivities } from 'redux/features/activitiesSlice/activitiesSlice';
import { fetchUsers } from 'redux/features/userSlice/userSlice';
// Dexie
import db, { dbWorker } from 'db/db';
import { useLiveQuery } from 'dexie-react-hooks';
// Types
import { ContactNotes } from 'redux/features/contactInfoSlice/types/ContactNotes';
import { ConversationsContext } from './types/ConversationsContext';
import { ConversationsProviderProps } from './types/ConversationsProviderProps';
import { IChatMemeber, IConversation } from '@trii/types/dist/Conversations';
import { IMessage, MessageImage } from '@trii/types/dist/Common/Messages';
import { ImageIcon } from '../../../../../types/ImageIcon';
import { TransferConversationData } from 'redux/features/conversationsSlice/types/TransferConversationData';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import { IBusiness, IContact, IContactInfo, IFile } from '@trii/types/dist/Contacts';
import { OpenInFooter } from 'redux/features/conversationsSlice/types/OpenInFooter';
import { ContactFile } from 'redux/features/contactInfoSlice/types/ContactFile';
import { ConversationsOfContact } from 'redux/features/conversationsSlice/types/ConversationsOfContact';
import { AssignedTo, CreateConversation } from './types/CreateConversation';
import { ModifyConversationLabelData } from 'redux/features/conversationsSlice/types/ModifyConversationLabelData';
import { Chat } from 'redux/features/conversationsSlice/types/Chat';
// Utils
import getImage from 'functions/getImage';
import { v4 as uuidv4 } from 'uuid';
import { UploadURLParams } from 'redux/features/messagesSlice/types/UploadURLParams';
import conversationFilters from './utils/conversationFilters';
import { ConversationFilters } from './types/ConversationFilters';
import { useConversationOrder } from 'hooks/useConversationOrder';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';

export const conversationsContext = createContext<ConversationsContext>({
  resetConversationState: () => {},
  conversationSelectedIdRef: null,
  filterCondition: conversationFilters[0],
  setFilterCondition: () => {},
  conversationNotFound: false,
  setConversationNotFound: (boolean: boolean) => {},
  loadContactDetails: () => {},
  handleOpenForwardModal: () => {},
  handleEscapeConversation: () => {},
  conversationsDb: [],
  openConversation: (
    conversation: IConversation,
    incognitoMode: boolean = false
  ) => {},
  selectMessagesMode: false,
  setSelectMessagesMode: () => {},
  selectedMessages: [],
  setSelectedMessages: (messages: IMessage[]) => {},
  openImageViewer: false,
  setOpenImageViewer: (boolean: boolean) => {},
  imageViewerSrc: [],
  setImageViewerSrc: (imageList: MessageImage[]) => {},
  currentIndex: 0,
  setCurrentIndex: (index: number) => {},
  openModalList: false,
  setOpenModalList: (boolean: boolean) => {},
  handleFinalizeConversation: (conversationId: string) => {},
  conversationId: '',
  setConversationId: (conversationId: string) => {},
  modalTitle: '',
  setModalTitle: (title: string) => {},
  handleTransferTo: (data: TransferConversationData) => {},
  channel: null,
  setChannel: (channel: ImageIcon) => {},
  getImage: (type: number) => {
    return null;
  },
  isEmailMode: false,
  setIsEmailMode: (boolean: boolean) => {},
  getUserInfo: (userId: string) => {},
  getChannelList: (channel: ChannelType) => {},
  contactInfo: null,
  businessInfo: null,
  setContactInfo: (contactInfo: IContact) => {},
  setBusinessInfo: (businessInfo: IBusiness) => {},
  handleOpenInFooter: (data: OpenInFooter) => {},
  expandedExternal: true,
  setExpandedExternal: (boolean: boolean) => {},
  expandedInternal: false,
  setExpandedInternal: (boolean: boolean) => {},
  handleChangeAccordion: (
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    state: boolean
  ) => {},
  getContact: (contactId: string) => {},
  getEndings: () => {},
  getUsers: () => {},
  getGroups: () => {},
  getLabels: () => {},
  // Internal Chat
  internalGroupInfo: [],
  setInternalGroupInfo: (internalGroupInfo: IChatMemeber[]) => {},
  setConversation: (data: CreateConversation) => {
    return new Promise(() => {});
  },
  originSelected: '',
  setOriginSelected: (originSelected: string) => {},
  destinationSelected: [],
  setDestinationSelected: (destinationSelected: string[]) => {},
  assignedTo: null,
  setAssignedTo: (assignedTo: AssignedTo) => {},
  contactSelected: null,
  setContactSelected: (contactSelected: IContactInfo) => {},
  handleExitConversation: () => {},
  handleAddLabel: (data: ModifyConversationLabelData) => {},
  // Internal Chat
  setInternalChat: (data: Chat) => {},
  getInternalChats: () => {},
  // Notes
  getNotes: (contactId: string) => {},
  setNewNote: (data: ContactNotes) => {},
  updateNote: (data: ContactNotes) => {},
  deleteNote: (data: ContactNotes) => {},
  // Files
  getFiles: (contactId: string) => {},
  filesToUpload: [],
  setFilesToUpload: (files: IFile[]) => {},
  handleUploadFile: (event: ChangeEvent<HTMLInputElement>) => {},
  setNewFile: (data: ContactFile) => {
    return new Promise(() => {});
  },
  deleteFile: (fileId: string) => {},
  // Activities
  getActivities: (contactId: string) => {},
  // Conversations of Contact
  getConversationsOfContact: (data: ConversationsOfContact) => {},
  // Alert
  error: false,
  setError: (boolean: boolean) => {},
  finalizedCondition: false,
  setFinalizedCondition: (boolean: boolean) => {},
  errorMessage: '',
  setErrorMessage: (message: string) => {},
  handleCloseAlert: () => {},
  orderingByLastMessageDate: true,
  orderingByStartConversationDate: false,
  isLastMessageAscending: false,
  isStartConversationAscending: false,
  handleOrderByLastMessage: () => {},
  handleOrderByStartConversation: () => {},
});

const ConversationsProvider = ({ children }: ConversationsProviderProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { emitEvent } = useContext(socketContext);

  const [searchParams] = useSearchParams();
  const contactId = searchParams.get('contactId');

  const conversationSelected = useSelector(selectConversationSelected);
  const spaceInfo = useSelector(selectSpaceInfo);
  const user = useSelector(selectUser);
  const fetchStatus = useSelector(selectEndingsFetchStatus);
  const selectedContactInfo = useSelector(selectContactInfo);
  const loadingConversation = useSelector(selectLoadingConversation);

  const conversationSelectedIdRef = useRef(conversationSelected?.id);
  const loadingConversationRef = useRef(loadingConversation);

  // Internal Chat
  const [internalGroupInfo, setInternalGroupInfo] = useState<IChatMemeber[]>([]);
  const [selectMessagesMode, setSelectMessagesMode] = useState<boolean>(false);
  const [selectedMessages, setSelectedMessages] = useState<IMessage[]>([]);
  const [openImageViewer, setOpenImageViewer] = useState<boolean>(false);
  const [imageViewerSrc, setImageViewerSrc] = useState<MessageImage[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [openModalList, setOpenModalList] = useState(false);
  const [conversationId, setConversationId] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [channel, setChannel] = useState<ImageIcon>(null);
  const [isEmailMode, setIsEmailMode] = useState<boolean>(false);
  const [contactInfo, setContactInfo] = useState<IContact>(null);
  const [businessInfo, setBusinessInfo] = useState<IBusiness>(null);
  const [expandedExternal, setExpandedExternal] = useState<boolean>(true);
  const [expandedInternal, setExpandedInternal] = useState<boolean>(false);
  const [finalizedCondition, setFinalizedCondition] = useState<boolean>(false);
  const [originSelected, setOriginSelected] = useState<string>('');
  const [destinationSelected, setDestinationSelected] = useState<string[]>(null);

  const [assignedTo, setAssignedTo] = useState<AssignedTo>(null);
  // Conversations of contact
  const [contactSelected, setContactSelected] = useState<IContactInfo>(null);
  // Files
  const [filesToUpload, setFilesToUpload] = useState<IFile[]>([]);
  // Alert
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [conversationNotFound, setConversationNotFound] = useState(false);
  // Filter
  const [filterCondition, setFilterCondition] = useState<ConversationFilters>(
    conversationFilters[0]
  );
  const {
    state: {
      orderingByLastMessageDate,
      orderingByStartConversationDate,
      isLastMessageAscending,
      isStartConversationAscending,
    },
    handleOrderByLastMessage,
    handleOrderByStartConversation,
  } = useConversationOrder();

  const getConversationsQuery = (
    spaceId: string,
    userId: string,
    condition: 'main' | 'finished' | 'drafts' | 'new' | 'active' | 'activeByOthers',
    sortBy: 'updatedAt' | 'createdAt' | null,
    isAscending: boolean
  ) => {
    return db.conversations
      .where('spaceId')
      .equals(spaceId)
      .filter((conversation) => {
        switch (condition) {
          case 'new':
            return (
              conversation.finalizedAt === null &&
              (conversation.participants.length === 0 ||
                (conversation.transferTo &&
                  conversation.transferTo.userIds.includes(userId)))
            );
          case 'active':
            return (
              conversation.finalizedAt === null &&
              ((conversation.participants.length > 0 &&
                conversation.participants.some((p) => p.id === userId)) ||
                (conversation.transferTo &&
                  conversation.transferTo.userIds.includes(userId)))
            );
          case 'activeByOthers':
            return (
              conversation.finalizedAt === null &&
              conversation.participants.length > 0 &&
              !conversation.participants.some((p) => p.id === userId)
            );
          case 'finished':
            return (
              conversation.finalizedAt !== null &&
              conversation.assignedTo &&
              conversation.assignedTo.userIds.includes(userId)
            );
          case 'main':
            return (
              conversation.finalizedAt === null &&
              (conversation.participants.length === 0 ||
                conversation.participants.some((p) => p.id === userId) ||
                (conversation.transferTo &&
                  conversation.transferTo.userIds.includes(userId)))
            );
          default:
            return false;
        }
      })
      .sortBy(sortBy)
      .then((conversations) => {
        return isAscending ? conversations : conversations.reverse();
      })
      .then((conversations) => {
        conversations.sort((a, b) => {
          // First, sort by pinned status
          if (a.pinned && !b.pinned) return -1;
          if (!a.pinned && b.pinned) return 1;

          // Then, sort by transferTo.userInfo.id === userId
          const aIsTransferredToUser = a.transferTo?.userInfo?.id === userId;
          const bIsTransferredToUser = b.transferTo?.userInfo?.id === userId;

          if (aIsTransferredToUser && !bIsTransferredToUser) return -1;
          if (!aIsTransferredToUser && bIsTransferredToUser) return 1;

          return 0;
        });

        return conversations;
      });
  };

  // Fetch conversations with sorting applied
  const conversationsDb = useLiveQuery(() => {
    if (filterCondition.value === 'drafts') {
      return [];
    }

    // Sorting preferences
    const sortBy = orderingByLastMessageDate
      ? 'updatedAt'
      : orderingByStartConversationDate
      ? 'createdAt'
      : 'updatedAt';
    const isAscending = orderingByLastMessageDate
      ? isLastMessageAscending
      : orderingByStartConversationDate
      ? isStartConversationAscending
      : true; // Default to ascending

    return getConversationsQuery(
      spaceInfo.id,
      user.uid,
      filterCondition.value,
      sortBy,
      isAscending
    );
  }, [
    spaceInfo,
    user.uid,
    filterCondition.value,
    orderingByLastMessageDate,
    orderingByStartConversationDate,
    isLastMessageAscending,
    isStartConversationAscending,
  ]);

  const isLoading = fetchStatus === 'loading';

  async function loadContactDetails(contactId: string) {
    getContact(contactId);
    getUsers();
  }

  function resetConversationState() {
    setConversationNotFound(false);
    setSelectMessagesMode(false);
    setSelectedMessages([]);
    setIsEmailMode(false);
    dispatch(setIsEditingName(false));
    dispatch(setIsReplyingEmail(false));
  }

  function prepareConversationState(conversation: IConversation) {
    const type = getImage(conversation?.channelInfo?.type);

    setChannel(type);

    if (conversation?.channelInfo?.type === ChannelType.EMAIL) {
      setIsEmailMode(true);
    }
  }

  async function triggerOpenConversationActions(
    incognitoMode: boolean,
    conversation: IConversation
  ) {
    if (conversation?.contactInfo.id) {
      const contactId = conversation.contactInfo.id;
      await loadContactDetails(contactId);
    }
    await emitEvent(
      'subscribeToNewMessages',
      spaceInfo.id,
      conversation.contactInfo.id
    );
    await dispatch(
      getMessages({
        conversationId: conversation.id,
        contactId: conversation.contactInfo.id,
      })
    );
    if (!incognitoMode) {
      dispatch(inConversation(conversation.id));
    }
    if (conversation.newMessagesCount > 0) {
      dispatch(
        readConversation({
          conversationId: conversation.id,
          type: 'external',
        })
      );
    }
    dispatch(fetchTemplates(conversation.channelInfo.id));
  }

  function focusConversationInput() {
    const conversationInput = document.getElementById('conversation-input');

    if (conversationInput) {
      conversationInput.focus();
    }
  }

  const openConversation = async (
    conversation: IConversation,
    incognitoMode: boolean = false
  ) => {
    if (loadingConversationRef.current) return;

    try {
      dispatch(setLoadingConversation(true));

      navigate(`/a/conversations/conversations`);
      resetConversationState();
      prepareConversationState(conversation);
      await triggerOpenConversationActions(incognitoMode, conversation);
      dispatch(setConversationSelected(conversation));

      if (!contactId) {
        focusConversationInput();
      }

      dispatch(setLoadingConversation(false));
    } catch (error) {
      console.error(error);
    }
  };

  const handleFinalizeConversation = async (
    conversationId: string,
    finalizeWithoutExit: boolean = false
  ) => {
    if (isLoading) return;

    setFinalizedCondition(finalizeWithoutExit);
    setConversationId(conversationId);

    const endingsFetch = await dispatch(fetchEndings());
    const endings = endingsFetch.payload;

    if (endings?.length > 0) {
      setModalTitle(t('endingsView.endings'));
      setOpenModalList(true);
    } else {
      dispatch(
        finalizeConversation({
          conversationId,
        })
      );

      if (conversationSelected?.id === conversationId && !finalizeWithoutExit) {
        dispatch(setConversationSelected(null));
      }

      setConversationId('');
    }
  };

  const handleTransferTo = (data: TransferConversationData) => {
    dispatch(transferConversation(data));
    if (conversationSelected && conversationSelected?.id === conversationId) {
      dispatch(setConversationSelected(null));
    }
  };

  // Files
  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const documents = Array.from(event.target.files || []);

    if (documents.length === 0) {
      return;
    }
    const createdBy = {
      id: user.uid,
      name: user.display_name,
      imageUrl: user.imageUrl,
      isActive: user.isActive,
      userType: user.userType,
      verifiedAccount: user.verifiedAccount,
      email: user.email,
      phone: user.phone,
      status: user.status,
      sipNumber: user.sipExtensionConfig.extension,
    };
    const contactId = conversationSelected.contactInfo
      ? conversationSelected.contactInfo.id
      : user.uid;

    documents.forEach(async (file) => {
      const id = uuidv4();
      const { type, name, size } = file;
      const data: IFile = {
        id,
        spaceId: spaceInfo.id,
        contactId,
        fileName: name,
        mimeType: type,
        size: size,
        url: '',
        createdAt: new Date(),
        //@ts-ignore
        createdBy,
      };

      setFilesToUpload((prevFiles) => [...prevFiles, data]);

      const formData = new FormData();

      formData.append('file', file);

      const URLParams: UploadURLParams = {
        module: 'contacts',
        folderType: 'files',
      };

      await dispatch(
        uploadMedia({
          file: formData,
          name: id,
          id: id,
          URLParams,
        })
      );
      await dispatch(fetchFiles(conversationSelected.contactInfo.id))
    });
  };

  const handleAddLabel = (data: ModifyConversationLabelData) => {
    dispatch(modifyConversationLabel(data));
  };

  const getContact = async (contactId: string) => {
    await dispatch(getContactInfo(contactId));
  };

  const getEndings = async () => {
    await dispatch(fetchEndings());
  };

  const getGroups = async () => {
    await dispatch(fetchGroups());
  };

  const getUsers = async () => {
    await dispatch(fetchUsers());
  };

  const getLabels = async () => {
    await dispatch(fetchLabels());
  };

  const getUserInfo = async (userId: string) => {
    await dispatch(fetchContactInfo(userId));
  };

  const getChannelList = async (channelType: ChannelType) => {
    await dispatch(fetchChannels(channelType));
  };

  const setInternalChat = async (data: Chat) => {
    const response = await dispatch(fetchNewChat(data));
    if (response.payload) {
      const chat = response.payload;
      const newChats = [...conversationsDb, chat];

      dbWorker.postMessage({
        action: 'updateChats',
        data: newChats,
      });
      setExpandedInternal(true);
    }
  };

  // Internal Chat
  const getInternalChats = async () => {
    const response = await dispatch(fetchChats('chat'));
    if (response.payload) {
      const chats = response.payload;

      dbWorker.postMessage({
        action: 'updateChats',
        data: chats,
      });
    }
  };

  const handleOpenInFooter = (data: OpenInFooter) => {
    dispatch(fetchOpenInFooter(data));
  };

  const handleExitConversation = () => {
    if (conversationSelected) {
      dispatch(outConversation(conversationSelected.id));
      dispatch(setConversationSelected(null));
    }
  };

  const handleEscapeConversation = () => {
    if (conversationSelected) {
      dispatch(setConversationSelected(null));
    }
  };

  const handleChangeAccordion = (
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    state: boolean
  ) => {
    setState(!state);
  };

  const handleCloseAlert = () => {
    setError(false);
    setErrorMessage('');
  };

  const setConversation = async (data: CreateConversation): Promise<boolean> => {
    const response = await dispatch(createConversation(data));

    if (response.payload.status === 409) {
      const { name } = response.payload?.data?.contactInfo;

      setError(true);
      setErrorMessage(
        t('conversations.createConversation.error.alreadyExists', { contact: name })
      );

      return false;
    } else {
      const newCoverstation = response.payload;

      openConversation(newCoverstation);
      return true;
    }
  };

  // Notes
  const getNotes = async (contactId: string) => {
    await dispatch(fetchNotes(contactId));
  };

  const setNewNote = async (data: ContactNotes) => {
    await dispatch(fetchNewNote(data));
    await dispatch(fetchNotes(data.contactId));
  };

  const updateNote = async (data: ContactNotes) => {
    await dispatch(fetchUpdateNote(data));
  };

  const deleteNote = async (data: ContactNotes) => {
    await dispatch(fetchDeleteNote(data));
    await dispatch(fetchNotes(data.contactId));

  };

  // Files
  const getFiles = async (contactId: string) => {
    await dispatch(fetchFiles(contactId));
  };

  const setNewFile = async (data: ContactFile): Promise<IFile> => {
    const response = await dispatch(fetchNewFile(data));
    return response.payload;
  };

  const deleteFile = async (fileId: string) => {
    await dispatch(
      fetchDeleteFile({
        contactId: conversationSelected.contactInfo.id,
        fileId,
      })
    );
    await dispatch(fetchFiles(conversationSelected.contactInfo.id))

  };

  // Activities
  const getActivities = async (contactId: string) => {
    await dispatch(fetchActivities(contactId));
  };

  // Conversations of Contact
  const getConversationsOfContact = async (data: ConversationsOfContact) => {
    await dispatch(fetchConversationsOfContact(data));
  };

  // Conversation modal
  const handleOpenForwardModal = () => {
    setModalTitle(t('conversations.message.actions.forward'));
    setOpenModalList(true);
  };

  useEffect(() => {
    if (selectedContactInfo) {
      setContactInfo(selectedContactInfo);
    }
  }, [selectedContactInfo]);

  useEffect(() => {
    conversationSelectedIdRef.current = conversationSelected?.id;
  }, [conversationSelected?.id]);

  useEffect(() => {
    dispatch(resetContacts());
  }, [openModalList]);

  useEffect(() => {
    return () => {
      dispatch(setConversationSelected(null));
    };
  }, []);

  useEffect(() => {
    loadingConversationRef.current = loadingConversation;
  }, [loadingConversation]);

  return (
    <conversationsContext.Provider
      value={{
        resetConversationState,
        conversationSelectedIdRef,
        filterCondition,
        setFilterCondition,
        conversationNotFound,
        setConversationNotFound,
        loadContactDetails,
        handleOpenForwardModal,
        handleEscapeConversation,
        conversationsDb,
        openConversation,
        selectMessagesMode,
        setSelectMessagesMode,
        selectedMessages,
        setSelectedMessages,
        openImageViewer,
        setOpenImageViewer,
        imageViewerSrc,
        setImageViewerSrc,
        currentIndex,
        setCurrentIndex,
        openModalList,
        setOpenModalList,
        handleFinalizeConversation,
        conversationId,
        setConversationId,
        modalTitle,
        setModalTitle,
        handleTransferTo,
        channel,
        setChannel,
        getImage,
        isEmailMode,
        setIsEmailMode,
        getUserInfo,
        getChannelList,
        contactInfo,
        setContactInfo,
        businessInfo,
        setBusinessInfo,
        handleOpenInFooter,
        expandedExternal,
        setExpandedExternal,
        expandedInternal,
        setExpandedInternal,
        handleChangeAccordion,
        getContact,
        getEndings,
        getGroups,
        getUsers,
        getLabels,
        setConversation,
        originSelected,
        setOriginSelected,
        destinationSelected,
        setDestinationSelected,
        assignedTo,
        setAssignedTo,
        contactSelected,
        setContactSelected,
        handleExitConversation,
        handleAddLabel,
        // Internal Chat
        internalGroupInfo,
        setInternalGroupInfo,
        setInternalChat,
        getInternalChats,
        // Notes
        getNotes,
        setNewNote,
        updateNote,
        deleteNote,
        // Files
        getFiles,
        filesToUpload,
        setFilesToUpload,
        handleUploadFile,
        setNewFile,
        deleteFile,
        // Activities
        getActivities,
        getConversationsOfContact,
        // Alert
        error,
        setError,
        errorMessage,
        setErrorMessage,
        handleCloseAlert,
        orderingByLastMessageDate,
        orderingByStartConversationDate,
        isLastMessageAscending,
        isStartConversationAscending,
        handleOrderByLastMessage,
        handleOrderByStartConversation,
        finalizedCondition,
        setFinalizedCondition,
      }}
    >
      {children}
    </conversationsContext.Provider>
  );
};

export default ConversationsProvider;
