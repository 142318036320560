import {
  Avatar,
  Badge,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio
} from "@mui/material"
import { UserInfo } from "@trii/types/dist/Users"

interface Props {
  data: UserInfo,
  showCheckbox: boolean,
  handleChecked: (id: string) => boolean,
  handleSelect: (user: UserInfo) => void,
}

const ChatList = ({
  data,
  showCheckbox,
  handleChecked,
  handleSelect,
}: Props) => (
  <ListItem
    key={data.id}
    onClick={() => handleSelect(data)}
    sx={{
      padding: 0,
    }}
  >
    <ListItemButton
      divider
    >
      <ListItemIcon>
        {showCheckbox ? (
          <Radio checked={handleChecked(data.id)} color="primary" />
        ) : (
          <Checkbox
            checked={handleChecked(data.id)}
            color="primary"
          />
        )}
      </ListItemIcon>
      <ListItemAvatar>
        <Badge
          color="success"
          overlap="circular"
          badgeContent=" "
          variant="dot"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Avatar src={data.imageUrl}></Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={data?.name === '' ? data.email : data.name}
        sx={{
          color: (theme) => theme.palette.text.primary,
        }}
      />
    </ListItemButton>
  </ListItem>
)

export default ChatList