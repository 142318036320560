import { useNavigate, useLocation } from 'react-router-dom';
// Translation
import { useTranslation } from 'react-i18next';
import './NavMenu.css';
import { Box, IconButton, styled, Tooltip, tooltipClasses } from '@mui/material';
// Utils
import { iconsData } from './utils/iconsData';
import { useSelector } from 'react-redux';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
import { useEffect, useState } from 'react';
import { selectUser } from 'redux/features/userSlice/userSlice';

type TooltipProps = {
  className?: string;
  title: string;
  placement: 'right';
  children: React.ReactElement;
};

const TooltipPers = styled(
  ({ className, children, placement, title, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      title={title}
      placement={placement}
    >
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    //@ts-ignore
    backgroundColor: theme.palette.background.tooltip,
  },
}));

const NavMenu = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const space = useSelector(selectSpaceInfo);
  const userPermissions = useSelector(selectUser);

  const [selectedIcon, setSelectedIcon] = useState<string | null>(null);

  const filteredIconsData = iconsData.filter((icon) => {
    if (!space.showItemsInDevelopment && icon.isDevelopment) {
      return false;
    }
  
    if (icon.id === 'dashboard' && !userPermissions?.profile?.conversations?.dashboard) {
      return false;
    }
    if (icon.id === 'system' && !userPermissions?.profile?.conversations?.editConfig) {
      return false;
    }
  
    return true;
  });

  useEffect(() => {
    const foundIcon = filteredIconsData.find((icon) =>
      location.pathname.toLowerCase().includes(icon.to.toLowerCase())
    );
    if (foundIcon) {
      setSelectedIcon(foundIcon.to);
    }
  }, [location, filteredIconsData]);

  const handleNavigate = (route: string, button: number) => {
    if (button === 1) {
      window.open(route, '_blank');
    } else {
      navigate(route);
      setSelectedIcon(route);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
          gap: 2,
          marginTop: 2,
        }}
      >
        {filteredIconsData.map((icon, index) => (         
          <TooltipPers title={t(icon.title)} placement="right" key={index}>
            <IconButton
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
                borderRadius: 1,
                backgroundColor:
                  icon.to === selectedIcon
                    ? (theme) => `${theme.palette.grey[200]}30`
                    : 'transparent',
                '&:focus': {
                  outline: 'none',
                },
                '&:hover': {
                  backgroundColor: (theme) => `${theme.palette.grey[200]}30`,
                  color: (theme) => theme.palette.primary.contrastText,
                },
                width: '1.5em',
                height: '1.5em',
              }}
              disableRipple
              onMouseDown={(e) => handleNavigate(icon.to, e.button)}
            >
              {icon.icon}
            </IconButton>
          </TooltipPers>
        ))}
      </Box>
    </Box>
  );
};

export default NavMenu;
