import { ChannelType } from '@trii/types/dist/Common/Channels';

export type JsReportData = {
  templateName: JsReportDataTemplateName;
  moduleName: 'conversations';
  filter: {
    timeRange: JsReportDataTimeRange;
    timeStart: string;
    timeEnd: string;
    channelType: ChannelType[];
    contactName: string;
    users: string[];
    groups: string[];
  };
};

export type JsReportDataTemplateName =
  | 'messagesLogs'
  | 'conversationsLogs'
  | 'conversationtrends'
  | 'timedistribution'
  | 'userperformance'
  | 'responsetimes'
  | 'detailsbyusergroup';

export type JsReportDataTimeRange = 1 | 7 | 30 | 365 | 77 | 1515 | 3030 | 60 | 0;

export function jsReportDataTemplateNameTypeGuard(
  templateName: string
): templateName is JsReportDataTemplateName {
  return (
    templateName === 'messagesLogs' ||
    templateName === 'conversationsLogs' ||
    templateName === 'conversationtrends' ||
    templateName === 'timedistribution' ||
    templateName === 'userperformance' ||
    templateName === 'responsetimes' ||
    templateName === 'detailsbyusergroup'
  );
}
