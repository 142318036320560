import React from 'react';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectEmailsFetchStatus,
  selectAllEmails,
} from 'redux/features/emailSlice/emailSlice';
// Components/ui
import { Box } from '@mui/material';
import { EmailItem } from './components';
import { ListItemSkeleton } from 'components';
import { selectAllRCS, selectRCSFetchStatus } from 'redux/features/RCSSlice/RCSSlice';
import RCSItem from './components/RCSItem/RCSItem';

const ListBody = () => {
  const allRCS = useSelector(selectAllRCS);
  const RCSFetchStatus: RequestStatus = useSelector(selectRCSFetchStatus);

  console.log("All RCS", allRCS);
  const RCS = allRCS.map((data) => <RCSItem key={data.id} data={data} />);

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {RCSFetchStatus === 'succeeded' ? RCS : <ListItemSkeleton />}
    </Box>
  );
};

export default ListBody;
