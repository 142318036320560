import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "redux/features/userSlice/userSlice";
import NotAllowed from "features/Views/NotAllowed/NotAllowed";

interface ProtectedRouteProps {
  children: JSX.Element;
  requiredPermissions?: string[];
  extraPermissions?: Record<string, string[]>; // Permisos adicionales según ruta
}

const hasPermission = (userPermissions: any, permissionKey: string) => {
  const keys = permissionKey.split(".");
  return keys.reduce((acc, key) => acc?.[key], userPermissions) === true;
};

const ProtectedRoute = ({ children, requiredPermissions = []}: ProtectedRouteProps) => {
  const userPermissions = useSelector(selectUser);
  const location = useLocation();
  const pathname = location.pathname;
  if (pathname.includes("/a/conversations") && !hasPermission(userPermissions, "profile.conversations.enabled")) {
    return <NotAllowed />;
  }
  // Verificar permisos básicos para esta ruta
  const hasAllPermissions = requiredPermissions.every(permission => hasPermission(userPermissions, permission));

  // Redirigir si faltan permisos básicos
  if (!hasAllPermissions) {
    return <NotAllowed />;
  }

  return children;
};

export default ProtectedRoute;
