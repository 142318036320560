import { useContext, useEffect } from 'react';
// Components/ui
import {
  ContactData,
  ContactConversation,
  ContactFiles,
  ContactHistory,
  ContactNotes,
  ContactRepairs,
  ContactEvent,
  ContactTicket,
  TabPanel,
  ContactButton,
} from './components';
import BotsInfo from './components/BotsInfo/BotsInfo';
import { Box } from '@mui/material';
// Types
import { SectionRefs } from '../../hooks/useSectionRefs';
import { IIntegrationButton } from '@trii/types/dist/Contacts';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import {
  fetchButtons,
  selectButtons,
} from 'redux/features/buttonsSlice/buttonsSlice';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

type PanelViewProps = {
  sectionRefs: SectionRefs;
  value: number;
  setValue: (value: number) => void;
  isClicked: boolean;
};

const PanelView = ({ sectionRefs, setValue, isClicked }: PanelViewProps) => {
  const conversationSelected = useSelector(selectConversationSelected);

  const getVisibleHeight = (element: HTMLElement, container: HTMLElement) => {
    const scrollTop = container.scrollTop;
    const scrollBot = scrollTop + container.clientHeight;
    const containerRect = container.getBoundingClientRect();
    const eleRect = element.getBoundingClientRect();
    const rect = {
      top: eleRect.top - containerRect.top,
      right: eleRect.right - containerRect.right,
      bottom: eleRect.bottom - containerRect.bottom,
      left: eleRect.left - containerRect.left,
    };
    const eleTop = rect.top + scrollTop;
    const eleBot = eleTop + element.offsetHeight;
    const visibleTop = eleTop < scrollTop ? scrollTop : eleTop;
    const visibleBot = eleBot > scrollBot ? scrollBot : eleBot;

    return visibleBot - visibleTop;
  };

  const buttons = useSelector(selectButtons);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (!isClicked) {
      const { scrollTop } = e.currentTarget;
      const refList = Object.values(sectionRefs);

      // Filter out null refs and flatten the array
      const refs = refList
        .flatMap((ref) => (Array.isArray(ref) ? ref : [ref]))
        .filter((ref) => ref.current !== null);

      if (scrollTop === 0) {
        setValue(0);
      } else {
        refs.forEach((ref, index) => {
          if (ref.current) {
            const { clientHeight } = ref.current;
            const element = ref.current;
            const container = e.currentTarget;
            const visibleHeight = getVisibleHeight(element, container);

            if (visibleHeight >= clientHeight) {
              setValue(index);
            }
          }
        });
      }
    }
  };

  const handleClick = (newValue: number) => () => {
    setValue(newValue);
  };

  return (
    <TabPanel
      value={0}
      index={0}
      onScroll={handleScroll}
      style={{
        padding: '0 8px 8px 8px',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: '100%',
        }}
      >
        {conversationSelected?.botVars?.length > 0 && (
          <BotsInfo conversationBotVars={conversationSelected.botVars} />
        )}
        <ContactData handleClick={handleClick(0)} ref={sectionRefs.contactDataRef} />
        <ContactConversation
          handleClick={handleClick(1)}
          ref={sectionRefs.contactConversationRef}
        />
        {/* <ContactTicket
          handleClick={handleClick(2)}
          ref={sectionRefs.contactTicketRef}
        /> */}
        <ContactNotes
          handleClick={handleClick(3)}
          ref={sectionRefs.contactNotesRef}
        />
        {/* <ContactRepairs
          handleClick={handleClick(4)}
          ref={sectionRefs.contactRepairsRef}
        /> */}
        <ContactFiles
          handleClick={handleClick(5)}
          ref={sectionRefs.contactFilesRef}
        />
        <ContactHistory
          handleClick={handleClick(6)}
          ref={sectionRefs.contactHistoryRef}
        />
        <ContactEvent
          handleClick={handleClick(7)}
          ref={sectionRefs.contactEventRef}
        />
        {buttons &&
          buttons.map((item, index) => {
            return (
              <ContactButton
                key={item.id}
                handleClick={handleClick(index + 8)}
                ref={sectionRefs.contactButtonsRef[index]}
                data={item as IIntegrationButton}
              />
            );
          })}
      </Box>
    </TabPanel>
  );
};

export default PanelView;
