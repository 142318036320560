// Types
import { IConversation } from '@trii/types/dist/Conversations';
// Utils
import axios from 'axios';
import getRequestConfig from 'redux/functions/getRequestConfig';

async function fetch(jwt: string, URL: string, conversationId: string) {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get<IConversation>(
    `${URL}/Conversations/${conversationId}`,
    config
  );

  return response.data;
}

export default { fetch };
