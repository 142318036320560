import { forwardRef, useEffect, useState, useContext, RefObject } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, CircularProgress } from '@mui/material';
import { AddNote, NotesSection } from './components';
import { SectionCard } from '../../layout';
// Slice
import {
  selectNewNote,
  selectNewNoteFetchStatus,
  selectNotes,
  selectNotesFetchStatus,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
// Types
import { INote } from '@trii/types/dist/Contacts';
import { ContactProps } from '../../types/ContactProps';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

const ContactNotes = forwardRef(
  (props: ContactProps, ref: RefObject<HTMLElement>) => {
    const { t } = useTranslation();

    const { deleteNote } = useContext(conversationsContext);

    const conversationSelected = useSelector(selectConversationSelected);
    const noteList = useSelector(selectNotes);
    const fetchStatus = useSelector(selectNotesFetchStatus);
    const newNote = useSelector(selectNewNote);
    const newNoteFetchStatus = useSelector(selectNewNoteFetchStatus);

    const [notes, setNotes] = useState<INote[]>([]);

    const isLoading = fetchStatus === 'loading' || newNoteFetchStatus === 'loading';

    const filterNotes = (noteId: string) => {
      const filteredNotes = notes.filter((note) => note.id !== noteId);
      const contactId = conversationSelected?.contactInfo.id;
      const data = {
        contactId,
        noteId,
      };
      deleteNote(data);
      setNotes(filteredNotes);
    };

    useEffect(() => {
      if (noteList) {
        setNotes(noteList);
      }
    }, [noteList]);

    useEffect(() => {
      if (newNote) {
        const noteIds = notes.map((note) => note.id);
        if (noteIds.includes(newNote.id)) {
          const updateNotes = notes.map((note) => {
            if (note.id === newNote.id) {
              return newNote;
            }
            return note;
          });
          setNotes(updateNotes);
        } else {
          setNotes([...notes, newNote]);
        }
      }
    }, [newNote]);

    return (
      <SectionCard
        title={t('conversations.sidebar.notes.title')}
        onClick={props.handleClick}
        tabName="contactNotes"
      >
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <AddNote />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              height: '100%',
              overflowY: 'auto',
            }}
          >
            {isLoading && (
              <CircularProgress
                size={20}
                sx={{
                  margin: 'auto',
                }}
              />
            )}
            {notes.map((note) => (
              <NotesSection
                key={note.id}
                description={note.text}
                noteId={note.id}
                handleDeleteNote={filterNotes}
              />
            ))}
          </Box>
        </Box>
      </SectionCard>
    );
  }
);

export default ContactNotes;
