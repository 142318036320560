import { useEffect, useState } from 'react';
// Router
import { useNavigate } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
// Dispatch
import { useAppDispatch } from 'hooks/useAppDispatch';
// ObjectID
import ObjectID from 'bson-objectid';
// Context
import socialWallsContext from './SocialWallsContext';
// Slice
import {
  fetchComments,
  fetchPosts,
  postAction,
  selectPosts,
  setMessage,
  setNewPosts,
  setNewComments,
  selectComments,
} from 'redux/features/socialWallSlice/socialWallSlice';
import { getUser, selectUser } from 'redux/features/userSlice/userSlice';
import { createConversation } from 'redux/features/conversationsSlice/conversationsSlice';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
// Types
import { Post, WallMessage } from '@trii/types/dist/Conversations';
import { SendMessage } from 'redux/features/socialWallSlice/types/SendMessage';
import { CreateConversation } from 'features/Views/Conversations/context/ConversationsProvider/types/CreateConversation';
import { PostAction } from 'redux/features/socialWallSlice/types/PostAction';
// Db
import { useLiveQuery } from 'dexie-react-hooks';
import db from 'db/db';

// Images
import bgLight from './assets/images/bg-light.png';
import bgDark from './assets/images/bg-dark.png';
import { useTheme } from '@mui/material';
import useImagePreloader from 'hooks/useImagePreloader';

export const SocialWallsProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  // Posts state
  const [postSelected, setPostSelected] = useState<Post>(null);
  // Messages Wall state
  const [isNewMessage, setIsNewMessage] = useState<boolean>(false);
  // Create Private Conversation
  const [commentToConversationId, setCommentToConversationId] = useState<string>('');
  // Messages Background Image
  const [backgroundImage, setBackgroundImage] = useState<string>('');
  const imageToPreload = theme.palette.mode === 'dark' ? bgDark : bgLight;
  const { imagesPreloaded } = useImagePreloader([imageToPreload]);

  const posts = useSelector(selectPosts);
  const messages = useSelector(selectComments);
  const user = useSelector(selectUser);
  const spaceInfo = useSelector(selectSpaceInfo);

  const postsDb = useLiveQuery(
    () => db.posts.where('spaceId').equals(spaceInfo.id).toArray(),
    [spaceInfo]
  );
  const messagesDb = useLiveQuery(
    async () =>
      postSelected &&
      (await db.comments.where('parentId').equals(postSelected.id).toArray()),
    [postSelected]
  );

  const getPosts = async () => {
    await dispatch(fetchPosts());
  };
  const getComments = async (postId: string) => {
    await dispatch(fetchComments(postId));
  };
  const updateMessage = (
    messageList: WallMessage[],
    newMessage: WallMessage
  ): WallMessage[] => {
    const newMessages = messageList.map((message) => {
      let newResponse = [];
      if (message.id === newMessage.parentId) {
        newResponse = [...(message.responses ? message.responses : []), newMessage];
      } else if (message?.responses?.length > 0) {
        newResponse = updateMessage(message.responses, newMessage);
      }
      return {
        ...message,
        responses: newResponse,
      };
    });
    return newMessages;
  };

  const sendMessage = async (data: SendMessage) => {
    if (data) {
      const id = ObjectID().toString();
      const newMessage: WallMessage = {
        id,
        spaceId: '',
        postId: data.postId,
        parentId: data.parentId,
        externalUserSender: null,
        userSender: user.uid,
        message: data.message,
        responses: [],
        createdAt: new Date(),
        updatedAt: new Date(),
        readed: true,
      };
      const isComment = posts.some((post) => post.id === newMessage.parentId);
      if (isComment) {
        dispatch(setNewComments([...messages, newMessage]));
      } else {
        const newMessages = updateMessage(messages, newMessage);
        dispatch(setNewComments(newMessages));
      }
      await dispatch(
        setMessage({
          ...data,
          id,
        })
      );
    }
  };

  // Action
  const fetchAction = async (data: PostAction) => {
    await dispatch(postAction(data));
  };

  // Create Private Conversation
  const createNewConversation = async (
    data: CreateConversation,
    commentId: string
  ) => {
    setCommentToConversationId(commentId);
    const assignedTo = {
      userId: user.uid,
      groupId: null,
    };
    const response = await dispatch(
      createConversation({
        ...data,
        assignedTo,
      })
    );
    let conversationId = '';
    if (response.payload.status === 409) {
      conversationId = response.payload.data.id;
    } else {
      conversationId = response.payload.id;
    }
    navigate(`/a/conversations/conversations?conversationId=${conversationId}`);
    setCommentToConversationId('');
  };

  useEffect(() => {
    if (!user) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (postsDb) {
      dispatch(setNewPosts(postsDb));
    }
  }, [postsDb]);

  useEffect(() => {
    if (messagesDb) {
      dispatch(setNewComments(messagesDb));
    }
  }, [messagesDb]);
  useEffect(() => {
    if (imagesPreloaded) {
      setBackgroundImage(imageToPreload);
    }
  }, [imagesPreloaded, theme.palette.mode, imageToPreload]);

  return (
    <socialWallsContext.Provider
      value={{
        // Posts
        posts,
        getPosts,
        postSelected,
        setPostSelected,
        // Messages Wall state
        messages,
        isNewMessage,
        setIsNewMessage,
        getComments,
        updateMessage,
        sendMessage,
        // Action
        fetchAction,
        // Create Private Conversation
        commentToConversationId,
        setCommentToConversationId,
        createNewConversation,
        backgroundImage,
      }}
    >
      {children}
    </socialWallsContext.Provider>
  );
};
