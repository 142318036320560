import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components/ui
import { Box, TextField, Typography } from '@mui/material';
import { inputFieldStyle } from '../../../../styles';
import InputsContainer from '../InputsContainer/InputsContainer';

import { RCSServiceAccount } from '@trii/types/dist/Common/Channels';
import { changeAuthProviderX509, changeAuthUri, changeClientEmail, changeClientId, changeClientX509, changePrivateKey, changePrivateKeyId, changeProjectId, changeTokenUri, changeType, changeUniverseDomain } from 'redux/features/RCSEditSlice/RCSEditSlice';

interface Props {
  data: RCSServiceAccount
}

const RCSInputs = ({data}: Props) => {
  const { t } = useTranslation();

  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();


  const handleAuthProviderX509Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeAuthProviderX509(event.target.value));
  };
  const handleAuthUriChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeAuthUri(event.target.value));
  };
  const handleClientEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeClientEmail(event.target.value));
  };
  const handleClientIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeClientId(event.target.value));
  };
  const handleClientX509Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeClientX509(event.target.value));
  };
  const handlePrivateKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changePrivateKey(event.target.value));
  };
  const handlePrivateKeyIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changePrivateKeyId(event.target.value));
  };
  const handleProjectIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeProjectId(event.target.value));
  };
  const handleTokenUriChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeTokenUri(event.target.value));
  };
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeType(event.target.value));
  };
  const handleUniverseDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeUniverseDomain(event.target.value));
  };
  
  return (
    <InputsContainer>     
      <TextField
        size="small"
        label="auth_provider_x509_cert_url"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={data?.auth_provider_x509_cert_url}
        onChange={handleAuthProviderX509Change}
      />     
      <TextField
        size="small"
        label="auth_uri"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={data?.auth_uri}
        onChange={handleAuthUriChange}
      />     
      <TextField
        size="small"
        label="client_email"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={data?.client_email}
        onChange={handleClientEmail}
      />     
      <TextField
        size="small"
        label="client_id"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={data?.client_id}
        onChange={handleClientIdChange}
      />     
      <TextField
        size="small"
        label="client_x509_cert_url"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={data?.client_x509_cert_url}
        onChange={handleClientX509Change}
      />     
      <TextField
        size="small"
        label="private_key"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={data?.private_key}
        onChange={handlePrivateKeyChange}
      />     
      <TextField
        size="small"
        label="private_key_id"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={data?.private_key_id}
        onChange={handlePrivateKeyIdChange}
      />     
      <TextField
        size="small"
        label="project_id"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={data?.project_id}
        onChange={handleProjectIdChange}
      />     
      <TextField
        size="small"
        label="token_uri"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={data?.token_uri}
        onChange={handleTokenUriChange}
      />     
       <TextField
        size="small"
        label={'type'}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={data?.type}
        onChange={handleTypeChange}
      />
      <TextField
        size="small"
        label="universe_domain"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={data?.universe_domain}
        onChange={handleUniverseDomainChange}
      />     
    </InputsContainer>
  );
};

export default RCSInputs;
