// Components/ui
import { Box, LinearProgress, useTheme } from '@mui/material';
import { style } from './style';
// Context
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import {
  ArrowDownButton,
  Date,
  MessageItem,
  NewMessagesLabel,
} from '../Body/components';
import { IMessage, MessageType } from '@trii/types/dist/Common/Messages';
import { Message } from 'features/Views/Conversations/context/MessagesProvider/types/Message';
import moment from 'moment';
import {
  selectMessages,
  selectNextMessagesFetchStatus,
} from 'redux/features/messagesSlice/messagesSlice';
import { useSelector } from 'react-redux';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';

const FORMAT_DATE = 'DD/MM/YYYY';

type BodySkeletonProps = {
  contactId: string;
};

const BodySkeleton = ({ contactId }: BodySkeletonProps) => {
  const theme = useTheme();

  const { selectMessagesMode, setSelectedMessages, selectedMessages } =
    useContext(conversationsContext);
  const { backgroundImage, infoMessagesHandler, getNextNewMessages } =
    useContext(messagesContext);

  const sliceMessages = useSelector(selectMessages);
  const nextMessagesFetchStatus = useSelector(selectNextMessagesFetchStatus);

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAllLoaded, setIsAllLoaded] = useState<boolean>(false);
  const [groupedMessages, setGroupedMessages] = useState<Message[]>([]);
  const [lastMessageId, setLastMessageId] = useState<string | null>(null);
  const [filesDoneLoading, setFilesDoneLoading] = useState<boolean>(false);
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(true);
  const [loadedMoreMessages, setLoadedMoreMessages] = useState<{
    status: boolean;
    scrollPosition: number;
    scrollHeight: number;
  }>({
    status: false,
    scrollPosition: 0,
    scrollHeight: 0,
  }); // Data used to keep track of the scroll bar position when loading more messages
  // Unread messages state
  const [unreadMessagesIds, setUnreadMessagesIds] = useState<string[]>([]);
  const [firstUnreadMessageId, setFirstUnreadMessageId] = useState<string>(null); // Used to render the unread messages chip in the chat
  const [newMessageCount, setNewMessageCount] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const today = moment();
  const todayFormat = today.format(FORMAT_DATE);
  const yesterday = today.subtract(1, 'days').format(FORMAT_DATE);
  const isNextLoading = nextMessagesFetchStatus === 'loading';

  const handleSelectMessage = (message: IMessage) => {
    const isMessageAdded = selectedMessages.some(
      (messageSelected) => messageSelected.id === message.id
    );
    if (selectMessagesMode && message?.type !== MessageType.N2B) {
      if (!isMessageAdded) {
        setSelectedMessages([...selectedMessages, message]);
      } else {
        setSelectedMessages(
          selectedMessages.filter(
            (messageSelected) => messageSelected.id !== message.id
          )
        );
      }
    }
  };

  const getNewMessageList = async (contactId: string) => {
    const newMessages = await getNextNewMessages({
      conversationId: '',
      contactId: contactId,
      pos: lastMessageId,
    });
    const oldMessagesIdsList = groupedMessages.map((messages) =>
      messages.messages.map((message) => message.id)
    );
    const oldMessagesIds = oldMessagesIdsList.flat();
    const messagesIsNews = newMessages?.filter(
      (message: IMessage) => !oldMessagesIds.includes(message.id)
    );
    return messagesIsNews;
  };

  const sortMessages = (messages: IMessage[]) => {
    const messagesSorted = messages.slice().sort((a, b) => {
      const dateA = moment(a.timestamp);
      const dateB = moment(b.timestamp);
      return dateA.diff(dateB);
    });

    const getLastMessageId = messagesSorted[0]?.id;

    setLastMessageId(getLastMessageId);

    return messagesSorted;
  };

  const groupMessages = (newMessages: IMessage[]) => {
    // Sort messages by date
    const messagesSorted = sortMessages(newMessages);
    // Group by date
    const messagesGroupByDate = messagesSorted.reduce(
      (acc: Message[], message: IMessage) => {
        const date = moment(message.timestamp).format(FORMAT_DATE);
        const index = acc.findIndex((item) => item.date === date);
        if (index === -1) {
          acc.push({
            date,
            messages: [message],
          });
        } else {
          acc[index].messages.push(message);
        }
        return acc;
      },
      []
    );

    return messagesGroupByDate;
  };

  const handleScrollToBottom = (event?: React.MouseEvent) => {
    event?.preventDefault();
    const current = messageContainerRef.current;
    if (current) {
      current.scrollTo({
        top: current.scrollHeight,
        behavior: 'smooth',
      });
      setNewMessageCount(0);
    }
  };

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    // Infinite scroll function
    e.preventDefault();
    e.stopPropagation();

    if (isNextLoading || isAllLoaded) return;

    // Scroll to top
    const top = e.currentTarget.scrollTop === 0;
    const current = e.currentTarget;

    setIsScrollAtBottom(
      current.scrollHeight - current.scrollTop === current.clientHeight ||
        current.scrollHeight - current.scrollTop < 700
    );

    if (top && !firstLoad) {
      const newMessageList = [];

      // if (conversationSelected) {
      setLoadedMoreMessages({
        status: true,
        scrollPosition: current.scrollTop,
        scrollHeight: current.scrollHeight,
      });

      const messagesList = await getNewMessageList(contactId);

      if (messagesList && messagesList.length > 0) {
        newMessageList.push(...messagesList);
      }
      // }
      // else {
      //   const conversationList = conversations.filter(
      //     (conversation) => conversation.contactInfo.id === contactId
      //   );

      //   if (conversationList) {
      //     const conversationListSorted = conversationList.sort((a, b) => {
      //       const dateA = moment(a.updatedAt);
      //       const dateB = moment(b.updatedAt);
      //       return dateB.diff(dateA);
      //     });
      //     const result = await Promise.all(
      //       conversationListSorted.map(async (conversation, i) => {
      //         if (conversationsAllLoaded.includes(conversation.id)) return [];

      //         const messagesConversation = await getNewMessageList(conversation.id);

      //         if (messagesConversation && messagesConversation.length > 0) {
      //           return messagesConversation;
      //         } else {
      //           setConversationsAllLoaded([
      //             ...conversationsAllLoaded,
      //             conversation.id,
      //           ]);

      //           return [];
      //         }
      //       })
      //     );
      //     const resultList = result.filter((item) => item.length > 0).flat();
      //     if (resultList.length > 20) {
      //       const splitList = resultList.slice(0, 20);
      //       const lastMessage = splitList[splitList.length - 1];
      //       const removeLastConversation = conversationsAllLoaded.filter(
      //         (item) => item !== lastMessage.conversationId
      //       );
      //       setConversationsAllLoaded(removeLastConversation);
      //       newMessageList.push(...splitList);
      //     } else {
      //       newMessageList.push(...resultList);
      //     }
      //   }
      // }
      if (newMessageList.length > 0) {
        setIsAllLoaded(false);
        const messagesSorted = sortMessages(newMessageList);
        // Group by date
        const messagesGroupByDate = messagesSorted.reduce(
          (acc: Message[], message: IMessage) => {
            const date = moment(message.timestamp).format(FORMAT_DATE);
            const index = acc.findIndex((item) => item.date === date);
            const alreadyExist = groupedMessages.findIndex(
              (item) => item.date === date
            );
            if (alreadyExist !== -1) {
              const messagesSorted = sortMessages([
                ...groupedMessages[alreadyExist].messages,
                message,
              ]);
              groupedMessages[alreadyExist].messages = messagesSorted;
            } else {
              if (index === -1) {
                acc.push({
                  date,
                  messages: [message],
                });
              } else {
                acc[index].messages.push(message);
              }
            }
            return acc;
          },
          []
        );
        const updateMessageState = [...messagesGroupByDate, ...groupedMessages];
        const getLastMessageId =
          updateMessageState[updateMessageState.length - 1]?.messages[
            updateMessageState[updateMessageState.length - 1]?.messages.length - 1
          ]?.id;

        setLastMessageId(getLastMessageId);
        setGroupedMessages((prev) => [...messagesGroupByDate, ...prev]);
      } else {
        setIsAllLoaded(true);
      }
    }
    setFirstLoad(false);
  };

  // On first load of a conversation: it scrolls to bottom after the messages are loaded
  // On adding new messages when the user scrolls at the top: it keeps the scroll position
  useEffect(() => {
    const current = messageContainerRef.current;

    if (unreadMessagesIds.length === 0 && loadedMoreMessages.status && current) {
      const newContentHeight =
        current.scrollHeight - loadedMoreMessages.scrollHeight;

      current.scrollTop = loadedMoreMessages.scrollPosition + newContentHeight;
      setLoadedMoreMessages({
        status: false,
        scrollPosition: 0,
        scrollHeight: 0,
      });

      return;
    }

    if ((unreadMessagesIds.length === 0 || isScrollAtBottom) && filesDoneLoading) {
      current?.scrollTo({
        top: current.scrollHeight,
      });
    }

    return () => {
      setFilesDoneLoading(false);
    };
  }, [groupedMessages, filesDoneLoading]);

  useEffect(() => {
    if (sliceMessages) {
      const groupedMessages = groupMessages(sliceMessages);
      setGroupedMessages(groupedMessages);
    }
  }, [sliceMessages]);

  return (
    <Box
      sx={{
        ...style.container,
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: theme.palette.mode == 'dark' ? '' : '#F0EBE3',
      }}
      ref={containerRef}
    >
      {!isScrollAtBottom && (
        <ArrowDownButton
          handleScrollToBottom={handleScrollToBottom}
          unreadMessagesIds={unreadMessagesIds}
          showSnackbar={showSnackbar}
          setShowSnackbar={setShowSnackbar}
        />
      )}

      {(isNextLoading || isLoading) && (
        <Box sx={style.progressContainer}>
          <LinearProgress />
        </Box>
      )}

      <Box
        sx={style.secondaryContainer}
        onScroll={handleScroll}
        ref={messageContainerRef}
      >
        {groupedMessages &&
          groupedMessages.length > 0 &&
          groupedMessages?.map((message, i) => (
            <Box
              key={i}
              paddingTop={1}
              paddingBottom={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pr: 1,
                  pl: 1,
                  position: 'sticky',
                  top: '1rem',
                  zIndex: 10,
                }}
              >
                <Date day={message.date} today={todayFormat} yesterday={yesterday} />
              </Box>

              {message.messages.map((message) => {
                if (
                  message.type === MessageType.INFO &&
                  !infoMessagesHandler.showMessages
                ) {
                  return null;
                }

                return (
                  <Fragment key={message.id}>
                    {firstUnreadMessageId === message.id && <NewMessagesLabel />}
                    <MessageItem
                      setUnreadMessagesIds={setUnreadMessagesIds}
                      handleSelectMessage={handleSelectMessage}
                      selectMessagesMode={false}
                      selectedMessages={[]}
                      message={message}
                      setFilesDoneLoading={setFilesDoneLoading}
                    />
                  </Fragment>
                );
              })}
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default BodySkeleton;
