import React, { ReactNode, useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// component/ui
import { Box, Button, Typography, useTheme } from '@mui/material';
import { IConversation } from '@trii/types/dist/Conversations';
import moment from 'moment';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { useSelector } from 'react-redux';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

interface Props {
  conversation: IConversation;
}

const FORMAT_DATE = 'DD/MM/YYYY HH:mm';

const EndedConversation = ({ conversation }: Props) => {
  const { getImage, openConversation } = useContext(conversationsContext);
  const conversationSelected = useSelector(selectConversationSelected);

  const { t } = useTranslation();
  const [dateStr, setDateStr] = React.useState<string>('');
  const theme = useTheme();

  React.useEffect(() => {
    if (conversation?.finalizedAt) {
      const date = moment(conversation.finalizedAt).format(FORMAT_DATE);
      setDateStr(date);
    }
  }, [conversation]);
  const ending = conversation?.ending?.name;
  const labels = conversation?.labels;
  const channelName =
    conversation && conversation.channelInfo && conversation.channelInfo.name;

  const userInfoName =
    conversation &&
    conversation?.assignedTo &&
    conversation?.assignedTo?.userInfo &&
    conversation?.assignedTo?.userInfo?.name;
  const groupInfoName =
    conversation &&
    conversation?.assignedTo &&
    conversation?.assignedTo?.groupInfo &&
    conversation?.assignedTo?.groupInfo?.name;

  const image = getImage(conversation?.channelInfo?.type);
  const icon: ReactNode = image.icon;

  return (
    <Button
      sx={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor:
          conversationSelected?.id === conversation.id
            ? theme.palette.action.active
            : 'background.default',
        borderRadius: 1,
        p: 1,
        cursor: 'pointer',
        border: `1px solid ${theme.palette.divider}`,
        '&:hover': {
          background: theme.palette.action.hover,
        },
        transition: 'background-color 0.1s ease-in-out',
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
      }}
      onClick={() => openConversation(conversation)}
      disabled={conversationSelected?.id === conversation.id}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: 12,
          color: 'text.primary',
        }}
      >
        {conversation.contactInfo.name}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          gap: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            color: 'text.primary',
          }}
        >
          {t('conversations.finalizedAt', { date: dateStr })}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box display="flex" flexDirection={'column'} alignItems={'flex-start'}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Box
              className="iconLastMessage"
              sx={{
                color: 'text.primary',
                marginTop: '-1px',
                '& svg': {
                  fontSize: '14px',
                  marginTop: '-1px',
                },
              }}
            >
              {icon}
            </Box>
            <Typography
              variant="subtitle2"
              width="100%"
              noWrap
              fontSize="9px"
              fontWeight={(theme) => theme.typography.fontWeightBold}
              color={(theme) => theme.palette.text.disabled}
            >
              {channelName}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              width="100%"
              noWrap
              fontSize="10px"
              color={(theme) => theme.palette.text.disabled}
            >
              {groupInfoName ? groupInfoName : '-'} /{' '}
              {userInfoName ? userInfoName : '-'}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              width="100%"
              noWrap
              fontSize="10px"
              color={(theme) => theme.palette.text.disabled}
            >
              {ending}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Button>
  );
};

export default EndedConversation;
