import { useTranslation } from 'react-i18next';
// Types
import {
  RCSServiceAccount,
} from '@trii/types/dist/Common/Channels';

// Components/ui
import { ChannelPanel } from 'components';
import { Box } from '@mui/material';
// Redux

import RCSInputs from './components/RCSInputs/RCSInputs';

interface Props {
  data: RCSServiceAccount;
}

const ConectionPanel = ({data}: Props) => {
  const {t} = useTranslation()

  return (
    <ChannelPanel title={t('global.connection')}>
      <Box display={'flex'} flexDirection={'column'} gap={4} alignItems={'center'}>
        <RCSInputs data={data} />
      </Box>
    </ChannelPanel>
  );
};

export default ConectionPanel;
