import { RefObject, forwardRef, useContext, useEffect } from 'react';
// Components/ui
import { Box } from '@mui/material';
import { SectionCard } from '../../layout';
import { ExternalLink, IFrameButton, TemplateRenderer } from './components';
// Types
import { ContactButtonProps } from '../../types/ContactProps';
import { IntegrationButton_format } from '@trii/types/dist/Contacts';
// Redux
import { useSelector } from 'react-redux';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { ActivitiesCardContext } from '../../../../context/ActivitiesCardContext';
import { getJSONButtonData } from 'redux/features/buttonsSlice/buttonsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';

const ContactButton = forwardRef(
  ({ handleClick, data }: ContactButtonProps, ref: RefObject<HTMLElement>) => {
    const dispatch = useAppDispatch();

    const { tabsShowed } = useContext(ActivitiesCardContext);
    const { contactInfo } = useContext(conversationsContext);

    // const selectedConversation = useSelector(selectConversationSelected);
    // const contactId = selectedConversation?.contactInfo?.id;


    useEffect(() => {
      if (tabsShowed.includes(`contact${data.name}`)) {
        switch (data.api_formatosalida) {
          case IntegrationButton_format.HTML:
            // Do something
            break;
          case IntegrationButton_format.HTML_newtab:
            // Do something
            break;
          case IntegrationButton_format.JSON:
            {
              
              dispatch(getJSONButtonData({ buttonId: data.id,  contactId : contactInfo.id }));

            break;
          }
        }
      }
    }, [tabsShowed]);

    return (
      <SectionCard
        title={data.name}
        onClick={handleClick}
        tabName={`contact${data.name}`}
      >
        <Box
          ref={ref}
          sx={{
            flexDirection: 'column',
            gap: '1rem',
            height: '100%',
            width: '100%',
          }}
        >
          {data.api_formatosalida === IntegrationButton_format.HTML && (
            <IFrameButton url={data.api_url} />
          )}
          {data.api_formatosalida === IntegrationButton_format.HTML_newtab && (
            <ExternalLink url={data.api_url} />
          )}
          {data.api_formatosalida === IntegrationButton_format.JSON && (
            <TemplateRenderer
              buttonId={data.id}
              contactId={contactInfo.id}
              template={data.api_Template}
            />
          )}
        </Box>
      </SectionCard>
    );
  }
);

export default ContactButton;
