import { useState, useContext, useEffect } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchActivities } from 'redux/features/activitiesSlice/activitiesSlice';
// Slice
import {
  fetchConversationsOfContact,
  selectContactInfoFetchStatus,
  selectConversationSelected,
} from 'redux/features/conversationsSlice/conversationsSlice';
// Components/ui
import { Box, useTheme } from '@mui/material';
import { ContactInfoSkeleton, PanelView, TabsMenu } from './components';
// Custom hooks
import useSectionRefs from './hooks/useSectionRefs';
// Provider
import ActivitiesCardContextProvider from './context/ActivitiesCardContextProvider';
import {
  fetchFiles,
  fetchHistoryLogs,
  fetchNotes,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
import {
  fetchButtons,
  selectButtons,
} from 'redux/features/buttonsSlice/buttonsSlice';

const ChatDetailsSidebar = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { contactInfo } = useContext(conversationsContext);

  const [value, setValue] = useState(0);
  const [isClicked, setIsClicked] = useState(false);

  const conversationSelected = useSelector(selectConversationSelected);
  const buttons = useSelector(selectButtons);
  const contactInfoFetchStatus = useSelector(selectContactInfoFetchStatus);

  const sectionRefs = useSectionRefs(buttons);

  const contactId = conversationSelected?.contactInfo?.id;

  useEffect(() => {
    dispatch(fetchButtons());
  }, [dispatch]);

  const handleTabChange = (newValue: number) => {
    setValue(newValue);

    // Fetch data when any tab is SELECTED (does not take in account scroll navigation between tabs)
    // Similar switch statement as in the SectionCard.tsx component
    switch (newValue) {
      case 1:
        dispatch(
          fetchConversationsOfContact({
            contactId,
            status: '1|2',
          })
        );
        break;
      case 3:
        dispatch(fetchNotes(contactId));
        break;
      case 5:
        dispatch(fetchFiles(contactId));
        break;
      case 6:
        dispatch(fetchHistoryLogs(contactId));
        break;
      case 7:
        dispatch(fetchActivities(conversationSelected?.contactInfo?.id));
        break;
    }
  };

  return (
    <ActivitiesCardContextProvider>
      <Box
        height="100%"
        sx={{
          borderLeft: `1px solid ${theme.palette.divider}`,
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          bgcolor: theme.palette.background.default,
        }}
        display={'flex'}
      >
        {!contactInfo || contactInfoFetchStatus === 'loading' ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            padding={2}
          >
            <ContactInfoSkeleton />
          </Box>
        ) : (
          <>
            <Box
              flexGrow={1}
              display={'flex'}
              flexDirection={'column'}
              width={'calc(100% - 4rem)'}
            >
              <PanelView
                sectionRefs={sectionRefs}
                value={value}
                setValue={setValue}
                isClicked={isClicked}
              />
            </Box>
            <TabsMenu
              sectionRefs={sectionRefs}
              value={value}
              handleChange={handleTabChange}
              setIsClicked={setIsClicked}
            />
          </>
        )}
      </Box>
    </ActivitiesCardContextProvider>
  );
};

export default ChatDetailsSidebar;
