// Redux
import {
  addPermission,
  removePermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  changeName,
  changeUseInCampaigns,
  changeToken,
  changeReOpenTime,
  changePauseBetweenMessages,
  changeReassign,
  changeReassignGroup,
  changeAutoResponseBusinessHours,
  changeAutoResponseOutOfBusinessHours,
  changeAutoResponseHoliday,
  changeSchedule,
  changeAssignTo,
  changeAssignMethod,
} from 'redux/features/RCSEditSlice/RCSEditSlice';
// Types
import { IChannel } from '@trii/types/dist/Common/Channels';
import { ConectionPanel } from './components';
import { PanelsSkeleton } from 'components';
import {
  ChannelPermissionsPanel,
  ChannelDetailsPanel,
  ChannelEditBodyContainer,
} from 'components';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { UserInfo } from '@trii/types/dist/Users';
import { IGroup } from '@trii/types/dist/Conversations/Groups/Group';

interface Props {
  data: IChannel;
  allFetched: boolean;
}

const Body = ({ data, allFetched }: Props) => {
  const isLoading = data === null || !allFetched;
  const dispatch = useAppDispatch();

  const handleAssignToChange = (user: UserInfo | null, group: IGroup | null) => {
    dispatch(changeAssignTo({ user, group }));
  };
  return (
    <ChannelEditBodyContainer>
      {isLoading ? (
        <PanelsSkeleton />
      ) : (
        <>
          <ChannelDetailsPanel
           handleAssignToChange={handleAssignToChange}
           assignTo={data.assignTo}
           assignMethod={data.assignMethod}
           changeAssignMethod={changeAssignMethod}
           changeName={changeName}
           changeUseInCampaigns={changeUseInCampaigns}
           changeToken={changeToken}
           changeReOpenTime={changeReOpenTime}
           changePauseBetweenMessages={changePauseBetweenMessages}
           changeReassign={changeReassign}
           changeReassignGroup={changeReassignGroup}
           changeAutoResponseBusinessHours={changeAutoResponseBusinessHours}
           changeAutoResponseOutOfBusinessHours={
             changeAutoResponseOutOfBusinessHours
           }
           changeAutoResponseHoliday={changeAutoResponseHoliday}
           changeSchedule={changeSchedule}
           data={data.rcsBusiness}
           id={data.id}
           name={data.name}
          />
          <ConectionPanel  data={data.rcsBusiness.serviceAccount}/>
          <ChannelPermissionsPanel
            addPermission={addPermission}
            removePermission={removePermission}
            changePermissionReadMessages={changePermissionReadMessages}
            changePermissionStartConversation={changePermissionStartConversation}
            data={data.permissions}
          />
        </>
      )}
    </ChannelEditBodyContainer>
  );
};

export default Body;
