import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { EmailState } from './types/EmailState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { RequestStatus } from 'redux/types/RequestStatus';
import { NewEmail } from './types/NewEmail';
// Service
import emailService from './emailService';

const initialState: EmailState = {
  emails: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
  },
  writer: {
    subject: '',
  },
};

export const fetchEmails = createAsyncThunk(
  'email/fetchEmails',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await emailService.fetchEmails(jwtToken, URL_CONVERSATIONS);

    return response;
  }
);

export const createEmail = createAsyncThunk(
  'email/createEmail',
  async (email: NewEmail, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await emailService.createEmail(
      jwtToken,
      URL_CONVERSATIONS,
      email
    );

    return response;
  }
);

export const deleteEmail = createAsyncThunk(
  'email/deleteEmail',
  async (emailId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await emailService.deleteEmail(
      jwtToken,
      URL_CONVERSATIONS,
      emailId
    );

    return response;
  }
);

const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    changeEmailCreateStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.status.create = action.payload;
    },
    setWriterSubject: (state, action: PayloadAction<string>) => {
      state.writer.subject = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEmails.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchEmails.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.emails = action.payload;
      })
      .addCase(createEmail.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createEmail.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.emails.push(action.payload);
      })
      .addCase(createEmail.rejected, (state, action) => {
        state.status.create = 'rejected';
        console.log(action.error);
      })
      .addCase(deleteEmail.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteEmail.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.emails = state.emails.filter((email) => email.id !== action.payload);
      });
  },
});

const selectEmailState = (state: RootState) => state.Email;
export const selectAllEmails = createSelector(
  selectEmailState,
  (state) => state.emails
);
export const selectEmailsFetchStatus = createSelector(
  selectEmailState,
  (state) => state.status.fetch
);
export const selectEmailCreateStatus = createSelector(
  selectEmailState,
  (state) => state.status.create
);
export const selectEmailDeleteStatus = createSelector(
  selectEmailState,
  (state) => state.status.delete
);
export const selectEmailWriterSubject = createSelector(
  selectEmailState,
  (state) => state.writer.subject
);

export const { changeEmailCreateStatus, setWriterSubject } = emailSlice.actions;

export default emailSlice.reducer;
