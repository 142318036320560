import { Box, Avatar, AvatarGroup, Typography, Tooltip, Chip } from '@mui/material';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
import { ConversationBotStatus } from '@trii/types/dist/Conversations';
import { ChatBotIcon } from 'components';
import getImage from 'functions/getImage';
import { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';

const Footer = memo(({ conversation }: { conversation: IConversation }) => {
  const { t } = useTranslation();

  const channelName =
    conversation && conversation.channelInfo && conversation.channelInfo.name;

  const userInfoName =
    conversation &&
    conversation?.assignedTo &&
    conversation?.assignedTo?.userInfo &&
    conversation?.assignedTo?.userInfo?.name;
  const groupInfoName =
    conversation &&
    conversation?.assignedTo &&
    conversation?.assignedTo?.groupInfo &&
    conversation?.assignedTo?.groupInfo?.name;

  const image = getImage(conversation?.channelInfo?.type);
  const title: string = t(image.title);

  const icon: ReactNode = image.icon;

  return (
    <Box display="flex" justifyContent={'space-between'}>
      <Box
        display="flex"
        flexDirection={'column'}
        sx={{ width: '100%', maxWidth: '65%' }}
      >
        <Box display="flex" alignItems="center" gap={0.5}>
          <Box
            className="iconLastMessage"
            sx={{
              color: 'text.primary',
              marginTop: '-1px',
              '& svg': {
                fontSize: '14px',
                marginTop: '-1px',
              },
            }}
          >
            {icon}
          </Box>
          <Typography
            variant="subtitle2"
            width="100%"
            noWrap
            fontSize="9px"
            fontWeight={(theme) => theme.typography.fontWeightBold}
            color={(theme) => theme.palette.text.disabled}
          >
            {channelName}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            width="100%"
            noWrap
            fontSize="10px"
            color={(theme) => theme.palette.text.disabled}
          >
            {groupInfoName ? groupInfoName : '-'} /{' '}
            {userInfoName ? userInfoName : '-'}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <AvatarGroup
          sx={{
            '& .MuiAvatar-root': {
              width: '1.3rem',
              height: '1.3rem',
              fontSize: '0.75rem',
            },
          }}
        >
          {conversation &&
            conversation.participants &&
            conversation.participants.map((participant) => (
              <Tooltip title={participant.name} arrow key={participant.id}>
                <Avatar
                  alt={
                    participant.name.charAt(0).toUpperCase() +
                    participant.name.slice(1)
                  }
                  src={participant?.imageUrl ? participant.imageUrl : '/'}
                  sx={{
                    width: '1.3rem',
                    height: '1.3rem',
                  }}
                />
              </Tooltip>
            ))}
        </AvatarGroup>

        {conversation?.botStatus === ConversationBotStatus.PROCESSING && (
          <Tooltip title={t('global.botProcessing')} arrow>
            <span>
              <ChatBotIcon color="black" />
            </span>
          </Tooltip>
        )}
      
        {conversation?.botStatus === ConversationBotStatus.WAITING && (
          <Tooltip title={t('global.botWaiting')} arrow>
            <span>
              <ChatBotIcon color="black" />
            </span>
          </Tooltip>
        )}
        {conversation?.botStatus === ConversationBotStatus.FINALIZED && (
          <Tooltip title={t('global.botFinalized')} arrow>
            <span>
              <ChatBotIcon color="#0aa709" />
            </span>
          </Tooltip>
        )}
        {conversation?.botStatus === ConversationBotStatus.CANCELED && (
          <ChatBotIcon color="gray" />
        )}

        {conversation?.newMessagesCount > 0 && (
          <Chip
            size="small"
            label={conversation.newMessagesCount}
            sx={{
              width: 'max-content',
              visibility: conversation?.newMessagesCount > 0 ? 'visible' : 'hidden',
              '&.MuiChip-root': {
                height: '1.225rem',
                borderRadius: '50%',
              },
              '& .MuiChip-label': {
                paddingLeft: '0.375rem ',
                paddingRight: '0.375rem',
              },
            }}
            color="error"
          />
        )}
      </Box>
    </Box>
  );
});

export default Footer;
